import {useTranslation} from "react-i18next";
import {Button, Form, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import {buildSharedRecordPath, SHARED_RECORD_ROUTE} from "../../routes";
import {Loader} from "../Loader/Loader";
import IconCopySvg from "../Icons/IconCopySvg";
import {getDomainName, getShortLink} from "../../helpers/FirebaseDynamicLinksHelper";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useAbortStatus} from "../../hooks/AbortHook";
import {shareRecord} from "../../helpers/GoogleStorageApiHelper";
import {useRouteMatch} from "react-router-dom";
import {IS_DEMO_APP} from "../../AppSettings";
import {useGoogleApi} from "../../google_api/GoogleApiProvider";

interface Props {
    recordId: string | null;
    recordName: string;
    linkCopyHandler: () => void;
    closeHandler: () => void;
}

export function SharedRecordLinkDialog(props: Props) {
    const {t} = useTranslation();
    const isAborted = useAbortStatus();
    const sharedRecordRouteMath = useRouteMatch(SHARED_RECORD_ROUTE);
    const googleDrive = useGoogleApi().drive;
    const [link, setLink] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const prepareShortLink = () => {
        if (googleDrive) {
            setLink(null);
            setError(null);
            if (props.recordId) {
                const domainName = getDomainName();
                const rawLink = domainName + buildSharedRecordPath(props.recordId);
                let promise;
                if (IS_DEMO_APP || sharedRecordRouteMath?.isExact) {
                    promise = Promise.resolve(true);
                } else {
                    promise = shareRecord(googleDrive, props.recordId);
                }
                promise.then(result => {
                    if (result) {
                        return getShortLink(rawLink, props.recordName);
                    } else {
                        throw new Error("Record share error");
                    }
                }).then(shortLink => {
                    if (!isAborted) {
                        if (shortLink) {
                            setLink(shortLink);
                            setError(null);
                        } else {
                            throw new Error("Short link creation error");
                        }
                    }
                }).catch(e => {
                    if (!isAborted) {
                        setLink(null);
                        setError(e);
                    }
                })
            } else {
                if (!isAborted) {
                    setLink(null);
                }
            }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        prepareShortLink();
    }, [googleDrive, props.recordId]);
    const copyButtonClickHandler = () => {
        if (link) {
            navigator.clipboard.writeText(link).then(() => {
                props.closeHandler();
                props.linkCopyHandler();
            });
        }
    };
    return (
        <Modal show={props.recordId !== null} size="lg" centered onHide={props.closeHandler}>
            <Modal.Header closeButton>{t("share_record_title")}</Modal.Header>
            <Modal.Body>
                {error && <ErrorSmall error={error} retryClickHandler={() => prepareShortLink()}/>}
                {!link && !error && <Loader/>}
                {link &&
                <div className="d-flex flex-column align-items-center my-2">
                    <div>{t("share_record_instructions")}</div>
                    <div className="d-flex align-self-stretch flex-row justify-content-between align-items-center my-4">
                        <Form.Control type="text" disabled className="flex-grow" defaultValue={link}/>
                        <Button className="ms-4" variant="outline-primary"
                                onClick={copyButtonClickHandler}><IconCopySvg/></Button>
                    </div>
                </div>
                }
            </Modal.Body>
        </Modal>
    );
}