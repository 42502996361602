import React, {Suspense, useEffect, useMemo} from 'react';
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import './App.css';
import {Viewer} from "./components/Viewer/Viewer";
import {AppBar} from "./components/AppBar/AppBar";
import {Container} from "react-bootstrap";
import {Footer} from "./components/Footer/Footer";
import {
    AWP_LIST_ROUTE,
    buildListPath,
    CALENDAR_ROUTE,
    EXECUTORS_ROUTE,
    HOME_ROUTE,
    LIST_BASE_ROUTE,
    LIST_ROUTE,
    MAP_ROUTE,
    RECORD_ROUTE,
    SHARED_RECORD_ROUTE
} from "./routes";
import {Welcome} from "./components/Welcome/Welcome";
import {MapExplorer} from "./components/MapExplorer/MapExplorer";
import {CalendarExplorer} from "./components/CalendarExplorer/CalendarExplorer";
import {ListExplorer} from "./components/ListExplorer/ListExplorer";
import {CALENDAR, LIST, MAP} from "./models/ExplorerMode";
import {LoadingScreen} from "./components/LoadingScreen/LoadingScreen";
import {LIST_LOCATION} from "./persistence";
import {getSavedValue} from "./helpers/PersistenceHelper";
import {Executors} from "./components/Executors/Executors";
import {AwpRouter} from "./AwpRouter";
import {useAwpRouteSection} from "./hooks/RouteHooks";
import { useGoogleApi } from './google_api/GoogleApiProvider';
import {logger, logInfo, MAX_LOG_RECORDS} from "./helpers/LogHelper";
import {testDeviceInfoParsing} from "./serial/AwpFwSerialDevice";
import {searchFw} from "./components/AwpFwGspUpdate/AwpFwSearch";

function testFwUpdate(){
    const  deviceInfo = testDeviceInfoParsing();
    if (deviceInfo.hwVersion && deviceInfo.swVersion) {
        searchFw(deviceInfo.deviceTypeId, deviceInfo.hwVersion, deviceInfo.swVersion, "new").then(fwInfo => console.log(fwInfo));
    }
}

function App() {
    const history = useHistory();
    const homeRouteMatch = useRouteMatch(HOME_ROUTE);
    const awpListRouteMatch = useRouteMatch(AWP_LIST_ROUTE);
    const sharedRecordRouteMatch = useRouteMatch(SHARED_RECORD_ROUTE);
    const executorsListRouteMatch = useRouteMatch(EXECUTORS_ROUTE);
    const awpRouteMatch = useAwpRouteSection();
    const googleApi = useGoogleApi();
    const isSignedIn = googleApi?.userData;
    useEffect(() => {
        logger.count().then(count => {
            if (count > MAX_LOG_RECORDS){
                logger.clear().then(b => logInfo("Logs cleared"));
            }
        })
        logInfo("Starting");
        // testFwUpdate();
    }, []);
    if (isSignedIn === undefined) {
        return <LoadingScreen/>
    }
    if (!isSignedIn && !homeRouteMatch?.isExact && !sharedRecordRouteMatch?.isExact && !executorsListRouteMatch && !awpListRouteMatch && !awpRouteMatch) {
        return (
            <Redirect to={HOME_ROUTE}/>
        );
    }
    const modeChangeHandler = (mode: number) => {
        switch (mode) {
            case LIST:
                history.push(buildListPath(getSavedValue(LIST_LOCATION, "")));
                break;
            case MAP:
                history.push(MAP_ROUTE);
                break;
            case CALENDAR:
                history.push(CALENDAR_ROUTE);
                break;
        }
    }
    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Container fluid>
                <AppBar/>
                <div className="container-content">
                    <div className="container-grow">
                        <Switch>
                            <Route exact path={SHARED_RECORD_ROUTE}>
                                <Viewer/>
                            </Route>
                            <Route exact path={RECORD_ROUTE}>
                                <Viewer/>
                            </Route>
                            <Route exact path={MAP_ROUTE}>
                                <MapExplorer modeChangeListener={modeChangeHandler}/>
                            </Route>
                            <Route exact path={CALENDAR_ROUTE}>
                                <CalendarExplorer modeChangeListener={modeChangeHandler}/>
                            </Route>
                            <Route exact path={LIST_BASE_ROUTE}>
                                {<ListExplorer modeChangeListener={modeChangeHandler}/>}
                            </Route>
                            <Route exact path={LIST_ROUTE}>
                                {<ListExplorer modeChangeListener={modeChangeHandler}/>}
                            </Route>
                            <Route exact path={EXECUTORS_ROUTE}>
                                {<Executors/>}
                            </Route>
                            <Route exact path={HOME_ROUTE}>
                                {isSignedIn ? <Redirect to={buildListPath()}/> : <Welcome/>}
                            </Route>
                            <AwpRouter/>
                            <Route>
                                <Redirect to={HOME_ROUTE}/>
                            </Route>
                        </Switch>
                    </div>
                    <Footer/>
                </div>
            </Container>
        </Suspense>
    );
}

export default App;

