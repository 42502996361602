import {LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UT1M, UT1M_CT, UT1M_IP} from "./DeviceType";

export interface DeviceFilterItem{
    k : string;
    v : boolean;
}

export interface DeviceFilter{
    filters : Array<DeviceFilterItem>;
}

export function isValidDeviceType(deviceType? : string) : boolean {
    switch (deviceType){
        case TUD2:
        case TUD3:
        case TP1M:
        case UCI:
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            return true;
        default:
            return false;
    }
}

export const defaultDeviceFilter : DeviceFilter = {
    filters: new Array<DeviceFilterItem>({k: TUD2, v: true}, {k: TUD3, v: true}, {k: LEEB, v: true},{k: UCI, v: true}, {k: TP1M, v: true}, {k: UT1M, v: true}, {k: UT1M_IP, v: true}, {k: UT1M_CT, v: true}, {k: MF1M, v: true})
};

export function getDeviceFilterState(df : DeviceFilter, key : string) : boolean{
    return df.filters.find(f => f.k === key)?.v ?? true;
}

export function setDeviceFilterState(df : DeviceFilter, key : string, value : boolean) {
    const item = df.filters.find(f => f.k === key);
    if (item){
        item.v = value;
    } else {
        df.filters.push({k : key, v: value});
    }
}