import {useTranslation} from "react-i18next";
import React, {Fragment, ReactNode, useEffect, useState} from "react";
import {firestore} from "../../index";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {Button, Row} from "react-bootstrap";
import {Loader} from "../Loader/Loader";
import './AwpFwGspUpdate.css';

import {
    FW_ARS1,
    FW_KRC, FW_LEEB, FW_LEEB_PLAIN,
    FW_MF1M,
    FW_TP2020,
    FW_TUD2,
    FW_TUD3,
    FW_UD2301,
    FW_UD2303,
    FW_UD3701,
    FW_UT1M,
    FW_UT1MIP,
    FW_UT1MST,
    FW_UT2A,
    FW_UT3EMA
} from "../../models/AwpFwUpdateDevice";
import {formatNumber} from "../../helpers/FormatHelper";
import {AwpFwStopListInfo} from "../../models/AwpFwStopListInfo";
import {AwpFwSerialDevice} from "../../serial/AwpFwSerialDevice";
import {AwpDeviceInfo} from "../../models/AwpDeviceInfo";

interface Props {
    serialDevice: AwpFwSerialDevice | undefined | null;
    onBackButtonClick: () => void;
    onInstallButtonClick: (fwInfo: AwpFwInfo) => void;
}

export function searchFw(deviceTypeId: number | undefined, hwVersion: number, swVersion : number, bootloader: string) : Promise<AwpFwInfo | null>{
    const fwCollectionRef = collection(firestore, "firmwares");
    const fwQuery = query(fwCollectionRef,
        where("deviceType", "==", getDeviceType(deviceTypeId)),
        where("status", "==", "release"),
        orderBy("SW", "desc"));
    return getDocs(fwQuery).then(snapshot => {
        const fwData = new Array<AwpFwInfo>();
        snapshot.forEach(doc => {
            const docData = doc.data();
            fwData.push({
                deviceType: docData.deviceType,
                sw: docData.SW,
                hwMin: docData.HWmin,
                hwMax: docData.HWmax,
                bootloader: docData.bootloader,
                type: docData.type,
                changeLog: docData.changeLog,
                fileId: doc.id
            } as AwpFwInfo);
        })
        let fwInfo = null;
        let latestSw = null;
        let latestHw = null;
        for (const item of fwData) {
            if (!latestSw) {
                latestSw = item.sw;
            }
            if (!latestHw) {
                latestHw = item.hwMin;
            }
            if (item.sw >= swVersion && (!item.bootloader || bootloader === item.bootloader)) {
                if (hwVersion >= item.hwMin && hwVersion <= item.hwMax) {
                    fwInfo = item;
                    fwInfo.latestHw = latestHw;
                    fwInfo.latestSw = latestSw;
                    break;
                }
            }
        }
        return fwInfo;
    });
}

function getDeviceType(deviceId?: number) : string {
    if (!deviceId) {
        return "";
    }
    switch (deviceId) {
        case FW_KRC:
            return "krc";
        case FW_MF1M:
            return "mf1m";
        case FW_TUD2:
            return "tud2";
        case FW_TUD3:
            return "tud3";
        case FW_TP2020:
            return "tp";
        case FW_UD2301:
            return "ud2301";
        case FW_UD2303:
            return "ud2303";
        case FW_UD3701:
            return "ud3701";
        case FW_UT1M:
            return "ut1m";
        case FW_UT1MIP:
            return "ut1mip";
        case FW_UT1MST:
            return "ut1mst";
        case FW_UT3EMA:
            return "ut3mema";
        case FW_UT2A:
            return "ut2a";
        case FW_ARS1:
            return "ars1";
        case FW_LEEB:
            return "leeb";
        case FW_LEEB_PLAIN:
            return "leeb_plain";
        default:
            return "";
    }
}

export function AwpFwSearch(props: Props) {
    const {t} = useTranslation();
    const [deviceDataRequestAttempt, setDeviceDataRequestAttempt] = useState(0);
    const [cancel, setCancel] = useState<(() => void) | undefined>(undefined);
    const [deviceInfo, setDeviceInfo] = useState(undefined as AwpDeviceInfo | null | undefined);
    const [fwInfo, setFwInfo] = useState(undefined as AwpFwInfo | undefined | null);
    useEffect(() => {
        if (props.serialDevice) {
            const [cancellationToken, promise] = props.serialDevice.requestDeviceData();
            setCancel(() => cancellationToken);
            promise.then((res) => {
                if (res === null) {
                    setDeviceInfo(null);
                } else {
                    const [id, type, hw, sw, bootloader] = res;
                    setDeviceInfo({
                        displayId: id,
                        deviceTypeId: type,
                        hwVersion: hw,
                        swVersion: sw,
                        bootloader: bootloader
                    } as AwpDeviceInfo);
                }
            });
        }
    }, [props.serialDevice, deviceDataRequestAttempt]);
    useEffect(() => {
        if (!deviceInfo || !deviceInfo.hwVersion) {
            return;
        }
        const stopListCollectionRef = collection(firestore, "fw_stop_list");
        const stopListQuery = query(stopListCollectionRef, where("deviceType", "==", getDeviceType(deviceInfo.deviceTypeId)));
        getDocs(stopListQuery).then(snapshot => {
            const stopListData = new Array<AwpFwStopListInfo>();
            snapshot.forEach(doc => {
                const docData = doc.data();
                stopListData.push({
                    deviceType: docData.deviceType,
                    sw: docData.SW,
                    hwMin: docData.HWmin,
                    hwMax: docData.HWmax,
                    reason: docData.reason
                } as AwpFwStopListInfo);
            })
            let stop = false;
            for (const item of stopListData) {
                if (deviceInfo.hwVersion >= item.hwMin && deviceInfo.hwVersion <= item.hwMax) {
                    if (item.sw === 0 || item.sw === deviceInfo.swVersion) {
                        stop = true;
                        break;
                    }
                }
            }
            if (stop) {
                setFwInfo(null);
            } else {
                searchFw(deviceInfo.deviceTypeId, deviceInfo.hwVersion, deviceInfo.swVersion, deviceInfo.bootloader).then(fwInfo => setFwInfo(fwInfo));
            }
        });
    }, [deviceInfo]);
    const onBackButtonClick = () => {
        if (cancel) {
            cancel();
        }
        props.onBackButtonClick();
    };
    const onRetryButtonClick = () => {
        setDeviceInfo(undefined);
        setDeviceDataRequestAttempt(attempt => attempt + 1);
    };
    let content: ReactNode;
    if (!props.serialDevice) {
        content = <div className="fw-simple-instruction-text text-center">
            {t('awp_fw_connection_lost_message')}
        </div>
    } else {
        if (deviceInfo === undefined) {
            content = <Loader message={t("awp_fw_device_version_awaiting_data")}/>;
        } else if (deviceInfo === null) {
            content = <Fragment>
                <div className="fw-simple-instruction-text text-center">
                    {t('awp_fw_timeout')}
                </div>
                <div className="my-2"/>
                <div className="fw-simple-instruction-text text-center">
                    {t('awp_fw_check_device_is_on')}
                </div>
            </Fragment>
        } else if (!deviceInfo.hwVersion) {
            content = <div className="fw-simple-instruction-text text-center">
                {t('awp_fw_device_version_detection_failed')}
            </div>
        } else if (fwInfo === undefined) {
            content = <Loader message={t("awp_fw_update_search")}/>;
        } else if (fwInfo === null || fwInfo.type !== "gsp") {
            content = (<Fragment>
                <div>{t("awp_fw_old_device_1")}</div>
                <div className="my-2"/>
                <div>{t("awp_fw_old_device_2")}</div>
                <div className="my-2"/>
                <div>{t("awp_fw_old_device_3")}</div>
                <div dangerouslySetInnerHTML={{__html: t("awp_fw_old_device_4")}}/>
            </Fragment>);
        } else {
            let fwInfoSw = formatNumber(fwInfo.sw, 3, 1);
            // Trello fix
            // https://trello.com/c/UI6uZ1Nz/814-update-06108-%D1%83%D1%82-2%D0%B0-%D0%BF%D1%80%D0%BE%D1%88-3050-%D0%BF%D0%BE%D1%81%D0%BB%D0%B5-%D0%BF%D0%BE%D0%B8%D1%81%D0%BA%D0%B0-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B9-%D0%BE%D1%82%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B0%D0%B5%D1%82-%D1%83%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B2-%D0%BA%D0%BE%D1%82%D0%BE%D1%80%D0%BE%D0%BC-%D0%BD%D0%B5%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D1%8C%D0%BD%D0%BE-%D0%BE%D1%82%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B0%D0%B5%D1%82%D1%81%D1%8F-%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%8F-%D0%BF%D1%80%D0%BE%D1%88%D0%B8%D0%B2%D0%BA%D0%B8-%D0%B2
            if (fwInfo.fileId === "f2b4a374-5fcf-4465-a83c-efb57f8fbbef"){
                fwInfoSw = "3.050";
            }
            const deviceInfoHw = deviceInfo.displayHwVersion ?? formatNumber(deviceInfo.hwVersion, 3, 1);
            const deviceInfoSw = deviceInfo.displaySwVersion ?? deviceInfo.swVersion ? formatNumber(deviceInfo.swVersion, 3, 1) : undefined;
            content = <Fragment>
                {deviceInfo.swVersion === 0 &&
                    <div className="fw-bold">{t("awp_fw_found", {version: fwInfoSw})}</div>}
                {deviceInfo.swVersion > 0 && deviceInfo.swVersion !== fwInfo.sw &&
                    <div className="fw-bold">{t("awp_fw_found_new", {version: fwInfoSw})}</div>}
                {deviceInfo.swVersion > 0 && deviceInfo.swVersion === fwInfo.sw &&
                    <div className="fw-bold">{t("awp_fw_already_latest")}</div>
                }
                {deviceInfoSw &&
                    <div style={{fontSize: "16px"}}>
                        {t("awp_fw_hw_info", {hw_version: deviceInfoHw, fw_version: deviceInfoSw})}
                    </div>
                }
                {deviceInfoSw === undefined &&
                    <div style={{fontSize: "16px"}}>
                        {t("awp_hw_info", {hw_version: deviceInfoHw})}
                    </div>
                }
                {fwInfo.latestSw && fwInfo.latestHw && fwInfo.latestSw > fwInfo.sw &&
                    <Fragment>
                        <div className="my-2"/>
                        <div>{t("awp_fw_support_terminated_1", {version: deviceInfo.displayHwVersion ?? formatNumber(deviceInfo.hwVersion, 3, 1)})}</div>
                        <div>{t("awp_fw_support_terminated_2")}</div>
                        <div className="my-2"/>
                        <div>{t("awp_fw_support_terminated_3", {
                            swVersion: formatNumber(fwInfo.latestSw, 3, 1),
                            hwVersion: formatNumber(fwInfo.latestHw, 3, 1)
                        })}</div>
                    </Fragment>
                }
            </Fragment>
        }
    }

    return (
        <div className="container-grow">
            <Row className="flex-grow-1">
                <div
                    className="d-flex flex-column justify-content-center align-items-center my-2 text-center fw-simple-instruction-text">
                    {content}
                    <div className="my-4"/>
                    <div className="d-flex flex-row justify-content-around align-self-stretch awp-fw-buttons-140">
                        <Button onClick={onBackButtonClick}>
                            {t("awp_fw_back")}
                        </Button>
                        {deviceInfo === null &&
                            <Button onClick={onRetryButtonClick}>
                                {t("awp_fw_retry")}
                            </Button>
                        }
                        {fwInfo && fwInfo.type === "gsp" && deviceInfo &&
                            <Button onClick={() => props.onInstallButtonClick(fwInfo)}>
                                {t(deviceInfo.swVersion !== fwInfo.sw ? "awp_fw_button_install" : "awp_fw_button_reinstall")}
                            </Button>
                        }
                    </div>
                </div>
            </Row>
        </div>
    );
}
