import {ArchiveModeSwitcher} from "../ArchiveModeSwitcher/ArchiveModeSwitcher";
import {MAP} from "../../models/ExplorerMode";
import {GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer} from "@react-google-maps/api";
import {API_KEY} from "../../AppSettings";
import {useEffect, useMemo, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {indexRecords} from "../../helpers/GoogleStorageApiHelper";
import {Loader} from "../Loader/Loader";
import {Error} from "../Error/Error";
import {RecordWrapper} from "../../models/RecordWrapper";
import './MapExplorer.css';
import {MapInfoWindowList} from "./MapInfoWindowList";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {
    defaultDeviceFilter,
    getDeviceFilterState,
    isValidDeviceType,
    setDeviceFilterState
} from "../../models/DeviceFilter";
import {EXPLORER_DEVICE_FILTER} from "../../persistence";
import {DeviceFilterSwitcher} from "../DeviceFilterSwitcher/DeviceFilterSwitcher";
import {buildRecordPath, MAP_PAGE_NAME} from "../../routes";
import {useAbortStatus} from "../../hooks/AbortHook";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {useAnalytics} from "../../hooks/AnalyticsHook";
import {useGoogleApi} from "../../google_api/GoogleApiProvider";

interface Props {
    modeChangeListener: (mode: number) => void;
}

function latLng(r: RecordWrapper) {
    return {
        lat: r.record.geoLocation?.latitude ?? 0,
        lng: r.record.geoLocation?.longitude ?? 0
    }
}

export function MapExplorer(props: Props) {
    useAnalytics(MAP_PAGE_NAME);
    const isAborted = useAbortStatus();
    const drive = useGoogleApi().drive;
    const [map, setMap] = useState(null as google.maps.Map | null);
    const [isBoundsSet, setBoundsSet] = useState(false);
    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [zoom, setZoom] = useState(2);
    const history = useHistory();
    const [error, setError] = useState(null as Error | null);
    const [items, setItems] = useState(null as Array<RecordWrapper> | null);
    const [activeItem, setActiveItem] = useState(null as RecordWrapper[] | null);
    const [deviceFilter, setDeviceFilter] = usePersistentState(EXPLORER_DEVICE_FILTER, defaultDeviceFilter);
    const [filter, setFilter] = useState(new Map<string, boolean>());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (drive) {
            setItems(null);
            indexRecords(drive).then(items => {
                if (!isAborted) {
                    const markers = items.filter(i => i.record.geoLocation);
                    const filter = new Map<string, boolean>();
                    markers.forEach(m => {
                        if (isValidDeviceType(m.record.deviceType) && !filter.has(m.record.deviceType)) {
                            let storedState = getDeviceFilterState(deviceFilter, m.record.deviceType);
                            if (storedState !== undefined) {
                                filter.set(m.record.deviceType, storedState);
                            }
                        }
                    });
                    setFilter(filter);
                    setItems(markers);
                    setError(null);
                }
            }).catch((e) => {
                if (!isAborted) {
                    setError(e);
                }
            })
        }
    }, [drive]);
    const displayItems = useMemo(() => {
        return items?.filter(i => filter.get(i.record.deviceType) ?? false);
    }, [items, filter]);
    const mapContainer = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!isBoundsSet && map && displayItems) {
            if (displayItems.length > 1) {
                const bounds = new google.maps.LatLngBounds();
                displayItems.forEach(i => {
                    if (i.record.geoLocation) {
                        bounds.extend(latLng(i))
                    }
                });
                map.fitBounds(bounds);
            } else {
                if (displayItems.length === 1) {
                    setCenter(latLng(displayItems[0]));
                    setZoom(14);
                }
            }
            setBoundsSet(true);
        }
    }, [map, displayItems]);
    const saveMapState = () => {
        const c = map?.getCenter();
        if (c) {
            setCenter({lat: c.lat(), lng: c.lng()})
        }
        const z = map?.getZoom();
        if (z) {
            setZoom(z);
        }
    };
    return (
        <div className="container-grow">
            <div className="d-flex flex-row align-items-center justify-content-between">
                {(drive && items && displayItems && !error) &&
                    <DeviceFilterSwitcher filters={filter}
                                          filtersChangeListener={newFilters => {
                                              setFilter(newFilters);
                                              newFilters.forEach((v, k) => {
                                                  setDeviceFilterState(deviceFilter, k, v);
                                              })
                                              setDeviceFilter(deviceFilter);
                                          }}/>
                }
                <div/>
                <ArchiveModeSwitcher mode={MAP} modeChangeListener={props.modeChangeListener}/>
            </div>
            <HorizontalDivider/>
            {(!error && (!drive || !items || !displayItems)) && <Loader/>}
            {(error) && <Error error={error} retryClickHandler={() => history.replace(history.location)}/>}
            {(drive && items && displayItems && !error) &&
            <div className="container-grow mt-4" ref={mapContainer}>
                <LoadScript googleMapsApiKey={API_KEY}>
                    <GoogleMap
                        mapContainerClassName="container-map"
                        options={{
                            streetViewControl: false
                        }}
                        center={center}
                        zoom={zoom}
                        onLoad={setMap}>
                        <MarkerClusterer averageCenter
                                         enableRetinaIcons
                                         onClick={(cluster) => {
                                             setActiveItem(cluster.getMarkers().flatMap(m => items.filter(i => i.id === m.getTitle())));
                                         }}
                                         zoomOnClick={false}
                                         gridSize={20}
                                         styles={[
                                             {
                                                 height: 53,
                                                 url: "images/cluster_icon.png",
                                                 width: 53,
                                                 textColor: "#ffffff"
                                             }]}>
                            {(c) => displayItems.map(i => (
                                <Marker key={i.id}
                                        title={i.id}
                                        position={latLng(i)}
                                        clusterer={c}
                                        onClick={() => {
                                            saveMapState();
                                            setActiveItem([i]);
                                        }}/>
                            ))}
                        </MarkerClusterer>
                        {activeItem &&
                        <InfoWindow options={{disableAutoPan: true}}
                                    onCloseClick={() => {
                                        saveMapState();
                                        setActiveItem(null);
                                    }}
                                    position={latLng(activeItem[0])}>
                            <MapInfoWindowList items={activeItem} itemClickListener={id => {
                                history.push(buildRecordPath(id))
                            }}/>
                        </InfoWindow>}
                    </GoogleMap>
                </LoadScript>
            </div>
            }
        </div>
    );
}