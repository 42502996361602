import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Row} from "react-bootstrap";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {AwpFwConnect} from "./AwpFwConnect";
import {AwpFwSearch} from "./AwpFwSearch";
import {AwpFwInstall} from "./AwpFwInstall";
import './AwpFwGspUpdate.css';
import IconArrowRightSvg from "../Icons/IconArrowRightSvg";
import {AwpFwSerialDevice} from "../../serial/AwpFwSerialDevice";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {AwpFwConnectionLost} from "./AwpFwConnectionLost";

export function AwpFwGspUpdate() {
    const {t} = useTranslation();
    const [step, setStep] = useState(1);
    const [serialDevice, setSerialDevice] = useState(undefined as AwpFwSerialDevice | null | undefined);
    const [fwInfo, setFwInfo] = useState(undefined as AwpFwInfo | undefined);
    const disconnectListener = useCallback(() => {
        if (serialDevice && serialDevice.executeDisconnectCallback()) {
            if (serialDevice) {
                serialDevice.removeDisconnectListener(disconnectListener);
            }
            setStep(4);
        }
    }, [serialDevice]);
    useEffect(() => {
        if (serialDevice){
            serialDevice.addDisconnectListener(disconnectListener);
        }
    }, [serialDevice, disconnectListener]);
    const changeStep = (newStep: number) => {
        switch (newStep) {
            case 1:
                if (serialDevice) {
                    serialDevice.removeDisconnectListener(disconnectListener);
                    serialDevice.close();
                }
                setSerialDevice(undefined);
                setFwInfo(undefined);
                break;
            case 2:
                setFwInfo(undefined);
                break;
        }
        setStep(newStep);
    };
    const deviceOpenHandler = (serialDevice: AwpFwSerialDevice | null) => {
        if (serialDevice) {
            setSerialDevice(serialDevice);
            changeStep(2);
        }
    };
    const fwSelectionHandler = (fwInfo: AwpFwInfo) => {
        setFwInfo(fwInfo);
        changeStep(3);
    }
    const fwUploadFinish = () => {
        if (serialDevice) {
            serialDevice.removeDisconnectListener(disconnectListener);
            serialDevice.close();
        }
    }
    return (
        <div className="container-grow d-flex flex-column m-4">
            <Row>
                <h5>{t("awp_fw_update")}</h5>
            </Row>
            <HorizontalDivider/>
            <div className="d-flex flex-row justify-content-between align-items-center my-2">
                <div className={`fw-step-item no-select ${step === 1 ? "fw-step-item-active" : ""}`}>
                    <span className="fw-item-circle">1</span>
                    <span className="fw-item-text">{t("awp_fw_connection")}</span>
                </div>
                <IconArrowRightSvg/>
                <div className={`fw-step-item no-select ${step === 2 ? "fw-step-item-active" : ""}`}>
                    <span className="fw-item-circle">2</span>
                    <span className="fw-item-text">{t("awp_fw_update_search")}</span>
                </div>
                <IconArrowRightSvg/>
                <div className={`fw-step-item no-select ${step === 3 ? "fw-step-item-active" : ""}`}>
                    <span className="fw-item-circle">3</span>
                    <span className="fw-item-text"> {t("awp_fw_installation")}</span>
                </div>
            </div>
            <HorizontalDivider/>
            {step === 1 && <AwpFwConnect onConnectButtonClick={deviceOpenHandler}/>}
            {step === 2 && <AwpFwSearch serialDevice={serialDevice}
                                        onBackButtonClick={() => changeStep(1)}
                                        onInstallButtonClick={fwSelectionHandler}/>}
            {step === 3 && <AwpFwInstall fwInfo={fwInfo!} serialDevice={serialDevice!}
                                         onBackButtonClick={() => changeStep(1)}
                                         onFinish={fwUploadFinish} onDone={() => changeStep(1)}/>}
            {step === 4 && <AwpFwConnectionLost onBackButtonClick={() => changeStep(1)}/>}
        </div>
    );
}