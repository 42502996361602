import {TFunction} from "react-i18next";
import {formatNumber} from "./FormatHelper";
import {DATA_PLACEHOLDER} from "../AppSettings";
import i18next from "i18next";

const MM = 0;
const MKM = 1;
const INCH = 2;
const MIL = 3;

export function formatAwpTpValue(value: number, scale: number){
    switch (scale){
        case MM:
            return formatNumber(value, 3, 3);
        case MKM:
            return formatNumber(value, 0, 0);
        case INCH:
            return formatNumber(value, 4, 4);
        case MIL:
            return formatNumber(value, 1, 1);
    }
    return formatNumber(value, 1, 1);
}

export function convertAwpTpValue(targetScale: number, value: number) {
    return convertAwpTpValueFromSource(MKM, targetScale, value);
}

export function convertAwpTpValueFromSource(sourceScale: number, targetScale: number, value: number) {
    switch (sourceScale) {
        case MM:
            switch (targetScale) {
                case MM:
                    return value;
                case MKM:
                    return value * 1000;
                case INCH:
                    return value / 25.4;
                case MIL:
                    return value / 0.0254;
            }
            break;
        case MKM:
            switch (targetScale) {
                case MM:
                    return value / 1000;
                case MKM:
                    return value;
                case INCH:
                    return value / 25400;
                case MIL:
                    return value / 25.4;
            }
            break;
        case INCH:
            switch (targetScale) {
                case MM:
                    return value * 25.4;
                case MKM:
                    return value * 25400;
                case INCH:
                    return value;
                case MIL:
                    return value * 1000;
            }
            break;
        case MIL:
            switch (targetScale) {
                case MM:
                    return value * 0.0254;
                case MKM:
                    return value * 25.4;
                case INCH:
                    return value / 1000;
                case MIL:
                    return value;
            }
            break;
    }
    return value;
}

export function formatAwpTpScaleName(t: TFunction<"translation">, scale: number) {
    switch (scale) {
        case MM:
            return t('scale_mm');
        case MKM:
            return t('scale_mkm');
        case INCH:
            return t('scale_inch');
        case MIL:
            return t('scale_mil');
    }
    return "";
}

export function formatEnAwpTpScaleName(scale: number) {
    const t = i18next.getFixedT("en");
    return formatAwpTpScaleName(t, scale);
}

export function formatAwpTpCoverName(t: TFunction<"translation">, material: number) {
    switch (material) {
        case 0:
            return t('cover_dielectric');
        case 1:
            return t('cover_1');
        case 2:
            return t('cover_2');
        case 3:
            return t('cover_3');
        case 3:
            return t('cover_4');
    }
    return "";
}

export function formatAwpTpBaseName(t: TFunction<"translation">, material: number) {
    switch (material) {
        case 0:
            return t('material_ferrum');
        case 1:
            return t('material_no_ferrum');
        case 2:
            return t('material_base_1');
        case 3:
            return t('material_base_2');
    }
    return "";
}

export function formatEnAwpTpMaterialName(material: number) {
    const t = i18next.getFixedT("en");
    return formatAwpTpBaseName(t, material);
}

export function formatAwpTpProbeType(t: TFunction<"translation">, serial?: string, name? : string){
    if (serial?.startsWith("105")){
        return t('awp_probe_f_0_3');
    }
    if (serial?.startsWith("106")){
        return t('awp_probe_f_0_5');
    }
    if (serial?.startsWith("107")){
        return t('awp_probe_f_2');
    }
    if (serial?.startsWith("108")){
        return t('awp_probe_f_5');
    }
    if (serial?.startsWith("109")){
        return t('awp_probe_nf_0_5');
    }
    if (serial?.startsWith("110")){
        return t('awp_probe_nf_2');
    }
    if (serial?.startsWith("111")){
        return t('awp_probe_m_12');
    }
    if (serial?.startsWith("112")){
        return t('awp_probe_m_30');
    }
    if (serial?.startsWith("113")){
        return t('awp_probe_m_60');
    }
    if (serial?.startsWith("114")){
        return t('awp_probe_dtvr');
    }
    if (serial?.startsWith("116")){
        return t('awp_probe_dsh1');
    }
    if (serial?.startsWith("120")){
        return t('awp_probe_dt');
    }
    return name ?? DATA_PLACEHOLDER;
}

export function formatAwpTpProbeName(t: TFunction<"translation">, name?: string){
    return name ?? DATA_PLACEHOLDER
}

export function getAwpTpMeasurementError(scale : number){
    switch (scale){
        case MM:
            return 0.005;
        case MKM:
            return 5;
        case INCH:
            return 0.0001;
        case MIL:
            return 0.1;
    }
    return 1;
}