import {useRouteMatch} from "react-router-dom";
import {
    AWP_DATA_STREAMING_ROUTE, AWP_FW_EXPERT_UPDATE_ROUTE, AWP_FW_GSP_UPDATE_ROUTE,
    AWP_IPSM_BASE_ROUTE, AWP_IPSM_DATA_TRANSFER_ROUTE, AWP_IPSM_RECORD_ROUTE,
    AWP_IPSM_ROUTE,
    AWP_KRC_BASE_ROUTE,
    AWP_KRC_RECORD_ROUTE,
    AWP_KRC_ROUTE,
    AWP_LIST_ROUTE, AWP_MF_BASE_ROUTE,
    AWP_MF_RECORD_ROUTE,
    AWP_MF_ROUTE, AWP_TP_BASE_ROUTE,
    AWP_TP_RECORD_ROUTE,
    AWP_TP_ROUTE, AWP_TUD_BASE_ROUTE,
    AWP_TUD_RECORD_ROUTE,
    AWP_TUD_ROUTE, AWP_UD2301_BASE_ROUTE,
    AWP_UD2301_RECORD_ROUTE,
    AWP_UD2301_ROUTE, AWP_UD2303_BASE_ROUTE,
    AWP_UD2303_RECORD_ROUTE,
    AWP_UD2303_ROUTE, AWP_UD3701_BASE_ROUTE,
    AWP_UD3701_RECORD_ROUTE,
    AWP_UD3701_ROUTE, AWP_UT2A_BASE_ROUTE,
    AWP_UT2A_RECORD_ROUTE,
    AWP_UT2A_ROUTE, AWP_UT3EMA_BASE_ROUTE,
    AWP_UT3EMA_RECORD_ROUTE,
    AWP_UT3EMA_ROUTE, AWP_UT_BASE_ROUTE,
    AWP_UT_RECORD_ROUTE,
    AWP_UT_ROUTE,
    EXECUTORS_ROUTE,
    HOME_ROUTE
} from "../routes";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA
} from "../helpers/AwpHelper";

export const AWP_DATA_STREAMING_SECTION = -1;
export const AWP_FW_SECTION = -2;

export function useExecutorRouteSection(): boolean {
    return useRouteMatch(EXECUTORS_ROUTE)?.isExact ?? false;
}

export function useWelcomeRouteSection(): boolean {
    return useRouteMatch(HOME_ROUTE)?.isExact ?? false;
}

export function useAwpRouteSection(): undefined | null | number {
    const isList = useRouteMatch(AWP_LIST_ROUTE)?.isExact ?? false;
    const isTud = useRouteMatch(AWP_TUD_ROUTE)?.isExact ?? false;
    const isTudBase = useRouteMatch(AWP_TUD_BASE_ROUTE)?.isExact ?? false;
    const isTudRecord = useRouteMatch(AWP_TUD_RECORD_ROUTE)?.isExact ?? false;
    const isKrc = useRouteMatch(AWP_KRC_ROUTE)?.isExact ?? false;
    const isKrcBase = useRouteMatch(AWP_KRC_BASE_ROUTE)?.isExact ?? false;
    const isKrcRecord = useRouteMatch(AWP_KRC_RECORD_ROUTE)?.isExact ?? false;
    const isTp = useRouteMatch(AWP_TP_ROUTE)?.isExact ?? false;
    const isTpBase = useRouteMatch(AWP_TP_BASE_ROUTE)?.isExact ?? false;
    const isTpRecord = useRouteMatch(AWP_TP_RECORD_ROUTE)?.isExact ?? false;
    const isUt = useRouteMatch(AWP_UT_ROUTE)?.isExact ?? false;
    const isUtBase = useRouteMatch(AWP_UT_BASE_ROUTE)?.isExact ?? false;
    const isUtRecord = useRouteMatch(AWP_UT_RECORD_ROUTE)?.isExact ?? false;
    const isMf = useRouteMatch(AWP_MF_ROUTE)?.isExact ?? false;
    const isMfBase = useRouteMatch(AWP_MF_BASE_ROUTE)?.isExact ?? false;
    const isMfRecord = useRouteMatch(AWP_MF_RECORD_ROUTE)?.isExact ?? false;
    const isUd2301 = useRouteMatch(AWP_UD2301_ROUTE)?.isExact ?? false;
    const isUd2301Base = useRouteMatch(AWP_UD2301_BASE_ROUTE)?.isExact ?? false;
    const isUd2301Record = useRouteMatch(AWP_UD2301_RECORD_ROUTE)?.isExact ?? false;
    const isUd2303 = useRouteMatch(AWP_UD2303_ROUTE)?.isExact ?? false;
    const isUd2303Base = useRouteMatch(AWP_UD2303_BASE_ROUTE)?.isExact ?? false;
    const isUd2303Record = useRouteMatch(AWP_UD2303_RECORD_ROUTE)?.isExact ?? false;
    const isUd3701 = useRouteMatch(AWP_UD3701_ROUTE)?.isExact ?? false;
    const isUd3701Base = useRouteMatch(AWP_UD3701_BASE_ROUTE)?.isExact ?? false;
    const isUd3701Record = useRouteMatch(AWP_UD3701_RECORD_ROUTE)?.isExact ?? false;
    const isUt3Ema = useRouteMatch(AWP_UT3EMA_ROUTE)?.isExact ?? false;
    const isUt3EmaBase = useRouteMatch(AWP_UT3EMA_BASE_ROUTE)?.isExact ?? false;
    const isUt3EmaRecord = useRouteMatch(AWP_UT3EMA_RECORD_ROUTE)?.isExact ?? false;
    const isUt2a = useRouteMatch(AWP_UT2A_ROUTE)?.isExact ?? false;
    const isUt2aBase = useRouteMatch(AWP_UT2A_BASE_ROUTE)?.isExact ?? false;
    const isUt2aRecord = useRouteMatch(AWP_UT2A_RECORD_ROUTE)?.isExact ?? false;
    const isIpsm = useRouteMatch(AWP_IPSM_ROUTE)?.isExact ?? false;
    const isIpsmBase = useRouteMatch(AWP_IPSM_BASE_ROUTE)?.isExact ?? false;
    const isIpsmRecord = useRouteMatch(AWP_IPSM_RECORD_ROUTE)?.isExact ?? false;
    const isIpsmDataTransfer = useRouteMatch(AWP_IPSM_DATA_TRANSFER_ROUTE)?.isExact ?? false;
    const isFwUpdate = useRouteMatch(AWP_FW_GSP_UPDATE_ROUTE)?.isExact ?? false;
    const isFwExpertUpdate = useRouteMatch(AWP_FW_EXPERT_UPDATE_ROUTE)?.isExact ?? false;
    const isDataStreaming = useRouteMatch(AWP_DATA_STREAMING_ROUTE)?.isExact ?? false;
    if (isList){
        return null;
    }
    if (isDataStreaming){
        return AWP_DATA_STREAMING_SECTION;
    }
    if (isFwUpdate || isFwExpertUpdate){
        return AWP_FW_SECTION;
    }
    if (isTud || isTudBase || isTudRecord){
        return AWP_DEVICE_TUD;
    }
    if (isKrc || isKrcBase || isKrcRecord){
        return AWP_DEVICE_KRC;
    }
    if (isTp || isTpBase || isTpRecord){
        return AWP_DEVICE_TP;
    }
    if (isUt || isUtBase || isUtRecord){
        return AWP_DEVICE_UT;
    }
    if (isMf || isMfBase || isMfRecord){
        return AWP_DEVICE_MF;
    }
    if (isUd2301 || isUd2301Base || isUd2301Record){
        return AWP_DEVICE_UD2301;
    }
    if (isUd2303 || isUd2303Base || isUd2303Record){
        return AWP_DEVICE_UD2303;
    }
    if (isUd3701 || isUd3701Base || isUd3701Record){
        return AWP_DEVICE_UD3701;
    }
    if (isUt3Ema || isUt3EmaBase || isUt3EmaRecord){
        return AWP_DEVICE_UT3EMA;
    }
    if (isUt2a || isUt2aBase || isUt2aRecord){
        return AWP_DEVICE_UT2A;
    }
    if (isIpsm || isIpsmBase || isIpsmRecord || isIpsmDataTransfer){
        return AWP_DEVICE_IPSM;
    }
    return undefined;
}