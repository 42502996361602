import Konva from "konva";
import {Group, Line, Rect, Text} from "react-konva";
import {useState} from "react";

const TEXT_SIZE = 12;
const CORNER_RADIUS = 8;
const STROKE_WIDTH = 2;
const PADDING = 6;
const ARROW_SIZE = 8;

const STROKE_COLOR = "#27769d";
const FILL_COLOR = "#212121";
const TEXT_COLOR = "#ffffff";

interface Props {
    text: string;
    x: number;
    y: number;
    imageWidth: number;
    imageHeight: number;
    clickListener: () => void;
    disabled?: boolean;
}

enum Orientation {
    W,
    NW,
    N,
    NE,
    E,
    SE,
    S,
    SW
}

function getWidthWithOrientation(orientation: Orientation, width: number) {
    switch (orientation) {
        case Orientation.W:
        case Orientation.NW:
        case Orientation.SW:
        case Orientation.E:
        case Orientation.NE:
        case Orientation.SE:
            return width + ARROW_SIZE;
    }
    return width;
}

function getHeightWithOrientation(orientation: Orientation, height: number) {
    switch (orientation) {
        case Orientation.N:
        case Orientation.NW:
        case Orientation.NE:
        case Orientation.S:
        case Orientation.SW:
        case Orientation.SE:
            return height + ARROW_SIZE;
    }
    return height;
}

function getOriginXOffset(orientation: Orientation, width: number) {
    switch (orientation) {
        case Orientation.NW:
        case Orientation.W:
        case Orientation.SW:
            return ARROW_SIZE;
        case Orientation.NE:
        case Orientation.E:
        case Orientation.SE:
            return -width - ARROW_SIZE;
        default:
            return -width / 2;
    }
}

function getOriginYOffset(orientation: Orientation, height: number) {
    switch (orientation) {
        case Orientation.NW:
        case Orientation.N:
        case Orientation.NE:
            return ARROW_SIZE;
        case Orientation.SW:
        case Orientation.S:
        case Orientation.SE:
            return -height - ARROW_SIZE;
        default:
            return -height / 2;
    }
}

function getArrow(orientation: Orientation, x: number, y: number, width: number, height: number) {
    let x1, x2, x3;
    let y1, y2, y3;
    switch (orientation) {
        case Orientation.W:
            x1 = 0;
            y1 = height / 2 - ARROW_SIZE / 2;
            x2 = -ARROW_SIZE;
            y2 = height / 2;
            x3 = 0;
            y3 = height / 2 + ARROW_SIZE / 2;
            break;
        case Orientation.NW:
            x1 = 0;
            y1 = Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            x2 = -ARROW_SIZE;
            y2 = -ARROW_SIZE;
            x3 = Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            y3 = 0;
            break;
        case Orientation.N:
            x1 = width / 2 - ARROW_SIZE / 2;
            y1 = 0;
            x2 = width / 2;
            y2 = -ARROW_SIZE;
            x3 = width / 2 + ARROW_SIZE / 2;
            y3 = 0;
            break;
        case Orientation.NE:
            x1 = width;
            y1 = Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            x2 = width + ARROW_SIZE;
            y2 = -ARROW_SIZE;
            x3 = width - Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            y3 = 0;
            break;
        case Orientation.E:
            x1 = width;
            y1 = height / 2 - ARROW_SIZE / 2;
            x2 = width + ARROW_SIZE;
            y2 = height / 2;
            x3 = width;
            y3 = height / 2 + ARROW_SIZE / 2;
            break;
        case Orientation.SE:
            x1 = width;
            y1 = height - Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            x2 = width + ARROW_SIZE;
            y2 = height + ARROW_SIZE;
            x3 = width - Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            y3 = height;
            break;
        case Orientation.S:
            x1 = width / 2 - ARROW_SIZE / 2;
            y1 = height;
            x2 = width / 2;
            y2 = height + ARROW_SIZE;
            x3 = width / 2 + ARROW_SIZE / 2;
            y3 = height;
            break;
        case Orientation.SW:
            x1 = 0;
            y1 = height - Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            x2 = -ARROW_SIZE;
            y2 = height + ARROW_SIZE;
            x3 = Math.max(ARROW_SIZE / 2, CORNER_RADIUS);
            y3 = height;
            break;
        default:
            throw new Error();
    }
    return [x + x1, y + y1, x + x2, y + y2, x + x3, y + y3];
}

export function MarkerView(props: Props) {
    const [isHovered, setHovered] = useState(false);
    const lines = props.text.split("\\n");
    let maxWidth = 0;
    let maxHeight = 0;
    for (const line of lines) {
        const tv = new Konva.Text();
        tv.fontSize(TEXT_SIZE);
        const textSize = tv.measureSize(line);
        maxWidth = Math.max(maxWidth, textSize.width);
        maxHeight = Math.max(maxHeight, textSize.height);
        tv.destroy();
    }
    const rectWidth = maxWidth + 2 * PADDING;
    const rectHeight = maxHeight * lines.length + 0.5 * maxHeight * (lines.length - 1) + 2 * PADDING;
    const markerX = props.imageWidth * props.x;
    const markerY = props.imageHeight * props.y;
    let orientation = Orientation.N;
    if (markerY > props.imageHeight - getHeightWithOrientation(orientation, rectHeight)) {
        orientation = Orientation.S;
    }
    if (markerX < getWidthWithOrientation(orientation, rectWidth)) {
        orientation = Orientation.W;
        if (markerY < getHeightWithOrientation(orientation, rectHeight)) {
            orientation = Orientation.NW;
        }
        if (markerY > props.imageHeight - getHeightWithOrientation(orientation, rectHeight)) {
            orientation = Orientation.SW;
        }
    }
    if (markerX > props.imageWidth - getWidthWithOrientation(orientation, rectWidth)) {
        orientation = Orientation.E;
        if (markerY < getHeightWithOrientation(orientation, rectHeight)) {
            orientation = Orientation.NE;
        }
        if (markerY > props.imageHeight - getHeightWithOrientation(orientation, rectHeight)) {
            orientation = Orientation.SE;
        }
    }
    const originX = markerX + getOriginXOffset(orientation, rectWidth);
    const originY = markerY + getOriginYOffset(orientation, rectHeight);
    const arrowData = getArrow(orientation, originX, originY, rectWidth, rectHeight);
    const onMouseEnter = (e: any) => {
        const container = e.target.getStage().container();
        if (props.disabled !== true) {
            container.style.cursor = "pointer";
        }
        setHovered(true);
    };
    const onMouseLeave = (e: any) => {
        const container = e.target.getStage().container();
        if (props.disabled !== true) {
            container.style.cursor = "default";
        }
        setHovered(false);
    };
    return (
        <Group onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onTouchStart={() => setHovered(true)}
               onTouchEnd={() => setHovered(false)} onClick={props.clickListener} onTap={props.clickListener}
               opacity={(isHovered && props.disabled !== true) ? 0.8 : 1}>
            <Line points={arrowData} strokeWidth={STROKE_WIDTH} stroke={STROKE_COLOR} fill={STROKE_COLOR}
                  closed={true}/>
            <Rect x={originX} y={originY} cornerRadius={CORNER_RADIUS} width={rectWidth} height={rectHeight}
                  stroke={STROKE_COLOR}
                  strokeWidth={STROKE_WIDTH} fill={FILL_COLOR}/>
            {lines.map((line, index) =>
                <Text x={originX + PADDING}
                      y={originY + PADDING + index * maxHeight + (index > 0 ? index * 0.5 * maxHeight : 0)}
                      width={rectWidth} height={maxHeight} text={line} fill={TEXT_COLOR}
                      fontSize={TEXT_SIZE} align={"left"} verticalAlign={"middle"}
                      key={`tl-${index}`}/>)}
        </Group>
    );

}