import {useEffect, useState} from "react";
import {SerialDevice} from "./SerialDevice";
import {AwpSerialDevice} from "./AwpSerialDevice";
import {
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT3EMA
} from "../helpers/AwpHelper";
import {AwpUsbStorageDevice} from "./AwpUsbStorageDevice";
import {AwpDevice} from "./AwpDevice";
import {AwpIpsmSerialDevice} from "./AwpIpsmSerialDevice";
import {AwpFwSerialDevice} from "./AwpFwSerialDevice";
import {AwpDataStreamingSerialDevice} from "./AwpDataStreamingSerialDevice";

export const useSerialPort: (baudRate: number, filters?: SerialPortFilter[]) => SerialDevice = (baudRate, filters) => {
    const [serialPort] = useState(new SerialDevice(baudRate, filters));
    useEffect(() => {
        return () => {
            serialPort.close();
        }
    }, [serialPort]);
    return serialPort;
}

export const useAwpSerialDevice: (deviceType: number, baudRate? : number) => AwpDevice = (deviceType, baudRate) => {
    if (!baudRate){
        baudRate = AwpSerialDevice.defaultBaudRate;
    }
    let filters = AwpSerialDevice.defaultFilters;
    if (deviceType === AWP_DEVICE_UT || deviceType === AWP_DEVICE_UD2301 || deviceType === AWP_DEVICE_UD2303 || deviceType === AWP_DEVICE_UT3EMA) {
        filters = AwpSerialDevice.advancedFilters;
    }
    const serialPort = useSerialPort(baudRate, filters);
    let awpDevice;
    if (deviceType === AWP_DEVICE_UD3701){
        awpDevice = new AwpUsbStorageDevice(serialPort);
    } else {
        const smallReadDelays = deviceType === AWP_DEVICE_UT3EMA;
        awpDevice = new AwpSerialDevice(serialPort, smallReadDelays);
    }
    const [device] = useState(awpDevice);
    return device;
}

export const useAwpIpsmSerialDevice: () => AwpIpsmSerialDevice = () => {
    const serialPort = useSerialPort(AwpIpsmSerialDevice.defaultBaudRate, AwpIpsmSerialDevice.filters);
    const awpDevice = new AwpIpsmSerialDevice(serialPort);
    const [device] = useState(awpDevice);
    return device;
}

export const useAwpDataStreamingSerialDevice: () => AwpDataStreamingSerialDevice = () => {
    const serialPort = useSerialPort(AwpDataStreamingSerialDevice.defaultBaudRate, AwpDataStreamingSerialDevice.filters);
    const awpDevice = new AwpDataStreamingSerialDevice(serialPort);
    const [device] = useState(awpDevice);
    return device;
}

export const useFwSerialPort: (baudRate?: number) => SerialDevice | undefined = (baudRate) => {
    const [serialPort, setSerialPort] = useState(undefined as SerialDevice | undefined);
    useEffect(() => {
        serialPort?.close();
        let port : SerialDevice | undefined;
        if (baudRate){
            port = new SerialDevice(baudRate);
            setSerialPort(port);
        }
    }, [baudRate]);
    return serialPort;
}

export const useAwpFwSerialDevice: (baudRate?: number) => AwpFwSerialDevice | undefined = (baudRate?: number) => {
    const serialPort = useFwSerialPort(baudRate);
    const [device, setDevice] = useState(undefined as AwpFwSerialDevice | undefined);
    useEffect(() => {
        if (serialPort){
            setDevice(new AwpFwSerialDevice(serialPort));
        }
    }, [serialPort])
    return device;
}