import {LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UT1M} from "../models/DeviceType";
import {formatDeviceName} from "./FormatHelper";
import {TFunction} from "react-i18next";

const TUD2_ARCHIVE_FOLDER_NAME = "T-UD2";
const TUD3_ARCHIVE_FOLDER_NAME = "T-UD3";
const LEEB_ARCHIVE_FOLDER_NAME = "Lab LEEB";
const UCI_ARCHIVE_FOLDER_NAME = "UCI";
const TP1M_ARCHIVE_FOLDER_NAME = "TP1-M";
const UT1M_ARCHIVE_FOLDER_NAME = "UT1-M";
const MF1M_ARCHIVE_FOLDER_NAME = "MF1-M"

function getArchiveRootFolderDisplayName(t: TFunction<"translation">) {
    return t("archive_root_folder_display_name");
}

function getDeviceRootFolderDisplayName(t: TFunction<"translation">, folderName: string) {
    switch (folderName) {
        case TUD2_ARCHIVE_FOLDER_NAME:
            return formatDeviceName(t, TUD2);
        case TUD3_ARCHIVE_FOLDER_NAME:
            return formatDeviceName(t, TUD3);
        case LEEB_ARCHIVE_FOLDER_NAME:
            return formatDeviceName(t, LEEB);
        case UCI_ARCHIVE_FOLDER_NAME:
            return formatDeviceName(t, UCI);
        case TP1M_ARCHIVE_FOLDER_NAME:
            return formatDeviceName(t, TP1M);
        case UT1M_ARCHIVE_FOLDER_NAME:
            return formatDeviceName(t, UT1M);
        case MF1M_ARCHIVE_FOLDER_NAME:
            return formatDeviceName(t, MF1M);
        default:
            return null;
    }
}

export function getArchiveItemName(t: TFunction<"translation">, level: number, rawName: string) {
    switch (level) {
        case 0:
            return getArchiveRootFolderDisplayName(t);
        case 1:
            return getDeviceRootFolderDisplayName(t, rawName);
        default:
            return rawName;
    }
}