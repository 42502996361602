import {IS_DEMO_APP} from "../AppSettings";
import {GoogleDriveApiHelper} from "./GoogleDriveApiHelper";
import {RecordWrapper} from "../models/RecordWrapper";
import {ArchiveItem} from "../models/ArchiveItem";
import {TpRecord} from "../models/TpRecord";
import {TudRecord} from "../models/TudRecord";
import {UtRecord} from "../models/UtRecord";
import {GoogleCloudStorageApiHelper} from "./GoogleCloudStorageApiHelper";
import {UciRecord} from "../models/UciRecord";
import {MfRecord} from "../models/MfRecord";
import {LeebRecord} from "../models/LeebRecord";

export function getArchiveRootFolder(drive: any): Promise<ArchiveItem> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.getArchiveRootFolder() : GoogleDriveApiHelper.getArchiveRootFolder(drive);
}

export function getArchiveItems(drive: any, where: string | string[], shouldIndexRecords: boolean): Promise<Array<ArchiveItem>> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.getArchiveItems(where, shouldIndexRecords) : GoogleDriveApiHelper.getArchiveItems(drive, where, shouldIndexRecords);
}

export function getPath(drive: any, id: string): Promise<ArchiveItem[]> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.getPath(id) : GoogleDriveApiHelper.getPath(drive, id);
}

export function indexRecords(drive: any): Promise<Array<RecordWrapper>> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.indexRecords() : GoogleDriveApiHelper.indexRecords(drive);
}

export function getRecord(drive: any, id: string, onlyMy?: boolean): Promise<TpRecord | TudRecord | LeebRecord | UciRecord | UtRecord | MfRecord> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.getRecord(id) : GoogleDriveApiHelper.getRecord(drive, id, onlyMy);
}

export function getMediaRecordUrl(drive: any, recordId: string, name: string): Promise<string> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.getMediaRecordUrl(recordId, name) : GoogleDriveApiHelper.getMediaRecordUrl(drive, recordId, name);
}

export function getMediaFile(drive: any, id: string, name: string): Promise<string> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.getMediaFile(id, name) : GoogleDriveApiHelper.getMediaFile(drive, id, name);
}

export function shareRecord(drive: any, recordId: string): Promise<boolean> {
    return IS_DEMO_APP ? GoogleCloudStorageApiHelper.shareRecord(recordId) : GoogleDriveApiHelper.shareRecord(drive, recordId);
}

export function deleteItems(drive: any, recordIds: Array<string>) : Promise<boolean>{
    return IS_DEMO_APP ? Promise.resolve(false) : GoogleDriveApiHelper.deleteItems(drive, recordIds);
}
