import {AwpTudSeries, prepareAwpTudBarChartData, prepareAwpTudLineChartData} from "../models/AwpTudSeries";
import {AwpTpSeries, prepareAwpTpBarChartData, prepareAwpTpLineChartData} from "../models/AwpTpSeries";
import {LineChartData} from "../components/MeasurementDetails/MeasurementsLineChart";
import {formatAwpTudKrcScaleName, getAwpTudKrcMeasurementError} from "./AwpTudKrcFormatHelper";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpSeries
} from "./AwpHelper";
import {TFunction} from "react-i18next";
import {formatAwpTpScaleName, getAwpTpMeasurementError} from "./AwpTpFormatHelper";
import {
    AwpUtSeries,
    getAwpUtMeasurementError,
    prepareAwpUtAreaChartData
} from "../models/AwpUtSeries";
import {formatAwpUtScaleName} from "./AwpUtFormatHelper";
import {AwpMfSeries, getAwpMfMeasurementError, prepareAwpMfAreaChartData} from "../models/AwpMfSeries";
import {formatAwpMfScaleName} from "./AwpMfFormatHelper";
import {AwpKrcSeries, prepareAwpKrcBarChartData, prepareAwpKrcLineChartData} from "../models/AwpKrcSeries";
import {formatAwpIpsmScaleName} from "./AwpIpsmFormatHelper";

export function prepareAwpLineChartData(deviceType: number, series: AwpSeries){
    switch (deviceType){
        case AWP_DEVICE_TUD:
            return prepareAwpTudLineChartData(series as AwpTudSeries);
        case AWP_DEVICE_KRC:
            return prepareAwpKrcLineChartData(series as AwpKrcSeries);
        case AWP_DEVICE_TP:
            return prepareAwpTpLineChartData(series as AwpTpSeries);
        case AWP_DEVICE_IPSM:
        case AWP_DEVICE_UT:
        case AWP_DEVICE_MF:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            throw new Error("Invalid device");
    }
    return new Array<LineChartData>();
}

export function getAwpMeasurementError(deviceType: number, series: AwpSeries){
    switch (deviceType){
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            const tudKrcSeries = series as AwpTudSeries;
            return getAwpTudKrcMeasurementError(tudKrcSeries.scale, tudKrcSeries.probeParam.typeProbe);
        case AWP_DEVICE_TP:
            return getAwpTpMeasurementError((series as AwpTpSeries).scale);
        case AWP_DEVICE_UT:
            return getAwpUtMeasurementError((series as AwpUtSeries).measure.scale);
        case AWP_DEVICE_MF:
            return getAwpMfMeasurementError((series as AwpMfSeries).scale);
        case AWP_DEVICE_IPSM:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            throw new Error("Invalid device");
    }
    return 1;
}

export function getAwpLineChartName(deviceType: number, t: TFunction<"translation">, series: AwpSeries){
    switch (deviceType){
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
            const tudKrcSeries = series as AwpTudSeries;
            return formatAwpTudKrcScaleName(t, tudKrcSeries.scale, tudKrcSeries.probeParam.typeProbe);
        case AWP_DEVICE_TP:
            const tpSeries = series as AwpTpSeries;
            return formatAwpTpScaleName(t, tpSeries.scale);
        case AWP_DEVICE_IPSM:
        case AWP_DEVICE_UT:
        case AWP_DEVICE_MF:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            throw new Error("Invalid device");
    }
    return "";
}

export function getAwpAreaChartName(deviceType: number, t: TFunction<"translation">, series: AwpSeries){
    switch (deviceType){
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
        case AWP_DEVICE_TP:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
        case AWP_DEVICE_IPSM:
            throw new Error("Invalid device");
        case AWP_DEVICE_MF:
            return formatAwpMfScaleName(t, (series as AwpMfSeries).scale);
        case AWP_DEVICE_UT:
            return formatAwpUtScaleName(t, (series as AwpUtSeries).measure.scale);
    }
    return "";
}

export function prepareAwpBarChartData(deviceType: number, series: AwpSeries){
    switch (deviceType){
        case AWP_DEVICE_TUD:
            return prepareAwpTudBarChartData(series as AwpTudSeries);
        case AWP_DEVICE_KRC:
            return prepareAwpKrcBarChartData(series as AwpKrcSeries);
        case AWP_DEVICE_TP:
            return prepareAwpTpBarChartData(series as AwpTpSeries);
        case AWP_DEVICE_IPSM:
        case AWP_DEVICE_UT:
        case AWP_DEVICE_MF:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
            throw new Error("Invalid device");
    }
    return [];
}

export function prepareAwpAreaChartData(deviceType: number, series: AwpSeries){
    switch (deviceType){
        case AWP_DEVICE_TUD:
        case AWP_DEVICE_KRC:
        case AWP_DEVICE_TP:
        case AWP_DEVICE_UD2301:
        case AWP_DEVICE_UD2303:
        case AWP_DEVICE_UD3701:
        case AWP_DEVICE_UT3EMA:
        case AWP_DEVICE_UT2A:
        case AWP_DEVICE_IPSM:
            throw new Error("Invalid device");
        case AWP_DEVICE_MF:
            return prepareAwpMfAreaChartData(series as AwpMfSeries);
        case AWP_DEVICE_UT:
            return prepareAwpUtAreaChartData(series as AwpUtSeries);
    }
    return [];
}