import {AwpFwInfo} from "../../models/AwpFwInfo";
import {AwpFwSerialDevice} from "../../serial/AwpFwSerialDevice";
import './AwpFwGspUpdate.css';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {firebaseStorage} from "../../index";
import {Loader} from "../Loader/Loader";
import {Button, ProgressBar} from "react-bootstrap";
import {logError, logger} from "../../helpers/LogHelper";

const STATUS_IN_PROGRESS = 0;
const STATUS_OK = 1;
const STATUS_BAD_RESPONSE = 2;

interface Props {
    fwInfo: AwpFwInfo,
    serialDevice: AwpFwSerialDevice;
    onBackButtonClick: () => void;
    onFinish: () => void;
    onDone: () => void;
}

interface FwData {
    baudRate: number;
    data: ArrayBuffer;
}


export function AwpFwInstall(props: Props) {
    const {t} = useTranslation();
    const [downloadAttempt, setDownloadAttempt] = useState(0);
    const [installAttempt, setInstallAttempt] = useState(0);
    const [data, setData] = useState(undefined as FwData | undefined | null);
    const [progress, setProgress] = useState(undefined as number | undefined);
    const [status, setStatus] = useState(STATUS_IN_PROGRESS);
    useEffect(() => {
        const fileRef = ref(firebaseStorage, `Firmwares/${props.fwInfo.fileId}`);
        getDownloadURL(fileRef)
            .then(url => fetch(url))
            .then(async response => {
                if (response.ok) {
                    try {
                        const fwData = await response.arrayBuffer();
                        if (props.fwInfo.type === "gsp") {
                            setData({
                                data: fwData,
                                baudRate: AwpFwSerialDevice.speed921600
                            });
                        } else if (props.fwInfo.type === "gsps") {
                            setData({
                                data: fwData,
                                baudRate: AwpFwSerialDevice.speedOldLoader
                            });
                        } else {
                            setData(null);
                        }
                        return;
                    } catch (e) {
                        logError("Response process error", e);
                    }
                    setData(null);
                }
            });
    }, [downloadAttempt, props.fwInfo]);
    useEffect(() => {
        if (data) {
            const promise = props.serialDevice.sendGsp(data.data, data.baudRate, setProgress);
            setProgress(0);
            promise.then(() => {
                setProgress(undefined);
                setStatus(STATUS_OK);
                props.onFinish();
            }).catch(e => {
                logError("Fw install error", e);
                setProgress(undefined);
                setStatus(STATUS_BAD_RESPONSE);
            });
        }
    }, [data, installAttempt])
    const onRetryButtonClick = () => {
        setData(undefined);
        setDownloadAttempt(attempt => attempt + 1);
    };
    const onRetryInstallButtonClick = () => {
        setStatus(STATUS_IN_PROGRESS);
        setInstallAttempt(attempt => attempt + 1);
    };
    return (
        <div className="container-grow">
            {data === undefined &&
                <div className="container-grow">
                    <Loader message={t('awp_fw_downloading')}/>
                </div>
            }
            {data === null &&
                <div className="container-grow">
                    <div
                        className="d-flex flex-grow-1 justify-content-center flex-column align-items-center my-4 fw-simple-instruction-text">
                        <span>{t("awp_fw_download_failed")}</span>
                        <div className="my-2"/>
                        <Button onClick={onRetryButtonClick}>{t("retry")}</Button>
                    </div>
                </div>
            }
            {data &&
                <div className="container-grow">
                    {progress === undefined && status === STATUS_IN_PROGRESS &&
                        <div
                            className="d-flex flex-grow-1 justify-content-center flex-column align-items-center my-4 fw-simple-instruction-text text-center">
                            <Loader message={t('awp_fw_upload_in_progress')}/>
                        </div>
                    }
                    {progress !== undefined && status === STATUS_IN_PROGRESS &&
                        <div
                            className="d-flex flex-grow-1 justify-content-center flex-column align-items-center my-4 fw-simple-instruction-text">
                            <div
                                className="d-flex flex-grow-1 flex-column align-self-stretch justify-content-center align-items-stretch">
                                <ProgressBar className="mx-2 my-4" animated
                                             now={progress} min={0}
                                             max={data.data.byteLength ?? 100}/>
                                <Loader message={t('awp_fw_upload_in_progress')}/>
                            </div>
                            <div
                                className="my-2 text-center fw-simple-instruction-text-warning">{t('awp_fw_upload_warning_1')}</div>
                            <div
                                className="my-2 text-center fw-simple-instruction-text-warning">{t('awp_fw_upload_warning_2')}</div>
                            <div
                                className="my-2 text-center fw-simple-instruction-text-warning">{t('awp_fw_upload_warning_3')}</div>
                        </div>
                    }
                    {status === STATUS_OK &&
                        <div className="container-grow">
                            <div
                                className="d-flex flex-grow-1 justify-content-center flex-column align-items-center my-4 fw-simple-instruction-text text-center awp-fw-buttons-100">
                                <span className="fw-bold">{t("awp_fw_congratulations_1")}</span>
                                <span className="fw-bold">{t("awp_fw_congratulations_2")}</span>
                                <div className="my-2"/>
                                <span style={{fontSize: "16px"}}>{t("awp_fw_upload_details")}</span>
                                <span
                                    style={{fontSize: "16px"}}>{t("awp_fw_current_version", {version: props.fwInfo.sw})}</span>
                                <div className="my-2"/>
                                <Button onClick={props.onDone}>{t("ok")}</Button>
                            </div>
                        </div>
                    }
                    {status === STATUS_BAD_RESPONSE &&
                        <div className="container-grow">
                            <div
                                className="d-flex flex-grow-1 justify-content-center flex-column align-items-center my-4 px-4 fw-simple-instruction-text">
                                <span className="fw-bold">{t("awp_fw_upload_failed")}</span>
                                <div className="my-2"/>
                                <span className="align-self-start">{t("awp_fw_fail_reason_1")}</span>
                                <ul className="align-self-start ms-5">
                                    <li>{t("awp_fw_fail_reason_2")}</li>
                                    <li>{t("awp_fw_fail_reason_3")}</li>
                                    <li>{t("awp_fw_fail_reason_4")}</li>
                                </ul>
                                <div className="my-2"/>
                                <span>{t("awp_fw_fail_reason_5")}</span>
                                <div className="my-2"/>
                                <span>{t("awp_fw_contact_manufacturer")}</span>
                                <div className="my-2"/>
                                <div className="d-flex flex-row align-self-stretch justify-content-around">
                                    <Button onClick={props.onBackButtonClick}>{t("awp_fw_back")}</Button>
                                    <Button onClick={onRetryInstallButtonClick}>{t("awp_fw_retry")}</Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}