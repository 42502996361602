import {TFunction} from "react-i18next";
import {formatNumber} from "./FormatHelper";
import i18next from "i18next";

const MPA = 0;
const TM3 = 1;
const GPA = 2;
const SP = 3;
const US = 4;
const MPS = 5;

export function formatAwpIpsmScaleName(t: TFunction<"translation">, scale: number) {
    switch (scale) {
        case MPA:
            return t('scale_mpa');
        case TM3:
            return t('scale_tm3');
        case GPA:
            return t('scale_gpa');
        case SP:
            return t('scale_sp');
        case US:
            return t('scale_us');
        case MPS:
            return t('scale_mps');
    }
    return "";
}

export function formatEnAwpIpsmScaleName(scale: number) {
    const t = i18next.getFixedT("en");
    return formatAwpIpsmScaleName(t, scale);
}

export function formatAwpIpsmValue(value: number, scale: number){
    switch (scale){
        case MPA:
            return formatNumber(value, 1, 1);
        case TM3:
            return formatNumber(value, 1, 1);
        case GPA:
            return formatNumber(value, 1, 1);
        case SP:
            return formatNumber(value, 1, 1);
        case US:
            return formatNumber(value, 1, 1);
        case MPS:
            return formatNumber(value, 1, 1);
    }
    return formatNumber(value, 1, 1);
}

export function parseIpsmScale(rawValue : string) : number{
    if (rawValue.toLowerCase().includes("mpa") || rawValue.includes("¬Ї")){
        return 0;
    }
    if (rawValue.toLowerCase().includes("t/m3") || rawValue.includes("в/¬3")){
        return 1;
    }
    if (rawValue.toLowerCase().includes("gpa")|| rawValue.includes("Ї")){
        return 2;
    }
    if (rawValue.toLowerCase().includes("sp") || rawValue.includes("§Ё")){
        return 3;
    }
    if (rawValue.toLowerCase().includes("us") || rawValue.includes("Mkc")){
        return 4;
    }
    if (rawValue.toLowerCase().includes("m/s") || rawValue.includes("M/C")){
        return 5;
    }
    throw new Error("Unknown raw scale (" + rawValue + ")");
}

export function getIpsmScale(value : number) : string{
    switch (value){
        case 0:
            return "mPa";
        case 1:
            return "t/m3";
        case 2:
            return "GPa";
        case 3:
            return "sp";
        case 4:
            return "us";
        case 5:
            return "m/s";
    }
    throw new Error("Unknown scale (" + value + ")");
}

export function parseIpsmValue(rawValue : string) : number{
    const regExp = /\d+\.?\d*/gm;
    const match = regExp.exec(rawValue);
    if (match){
        return Number(match[0]);
    }
    throw new Error("Bad value (" + rawValue + ")");
}