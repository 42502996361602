import {Button, ButtonGroup, Col, Row, Toast, ToastBody, ToastContainer} from "react-bootstrap";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import IconCsvSvg from "../Icons/IconCsvSvg";
import IconPdfSvg from "../Icons/IconPdfSvg";
import IconCloseSvg from "../Icons/IconCloseSvg";
import {useTranslation} from "react-i18next";
import {Fragment, useEffect, useMemo, useState} from "react";
import {FetchData} from "../../models/FetchData";
import {ToastData} from "../../models/ToastData";
import {useHistory, useParams} from "react-router-dom";
import {Loader} from "../Loader/Loader";
import {Error} from "../Error/Error";
import {AwpMeasurementSeriesInfo} from "../AwpSeriesInfo/AwpMeasurementSeriesInfo";
import './AwpRecordView.css';
import {useLocale} from "../../hooks/LocaleHook";
import {createAwpCsv} from "../../csv/CsvHelper";
import logoPng from "../AppBar/logo_main.png";
import {ViewContainer} from "../ViewContainer/ViewContainer";
import {ViewContainerWithEnlarge} from "../ViewContainer/ViewContainerWithEnlarge";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {AwpPhotoViewer} from "../AwpPhotoViewer/AwpPhotoViewer";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpRecord,
    AwpSeries,
    formatAwpProbeName,
    formatAwpValue,
    getAwpSeriesTitle,
    hasAwpPhoto,
    hasAwpScreen,
    prepareAwpMarkers,
    readAwpRecord
} from "../../helpers/AwpHelper";
import {TOAST_DELAY} from "../../AppSettings";
import {
    AWP_IPSM_ROUTE,
    AWP_KRC_ROUTE,
    AWP_MF_ROUTE,
    AWP_TP_ROUTE,
    AWP_TUD_ROUTE,
    AWP_UD2301_ROUTE,
    AWP_UD2303_ROUTE,
    AWP_UD3701_ROUTE,
    AWP_UT2A_ROUTE,
    AWP_UT3EMA_ROUTE,
    AWP_UT_ROUTE,
    buildAwpListPath
} from "../../routes";
import {formatAwpDeviceDisplayName} from "../../helpers/AwpDeviceDisplayNameHelper";
import {MeasurementsLineChart} from "../MeasurementDetails/MeasurementsLineChart";
import {MeasurementsBarChart} from "../MeasurementDetails/MeasurementsBarChart";
import {createAwpPdf} from "../../pdf/AwpPdfHelper";
import {ModeSwitcher} from "../ModeSwitcher/ModeSwitcher";
import {
    getAwpAreaChartName,
    getAwpLineChartName,
    getAwpMeasurementError,
    prepareAwpAreaChartData,
    prepareAwpBarChartData,
    prepareAwpLineChartData
} from "../../helpers/AwpChartHelper";
import {AwpUtSeriesInfo} from "../AwpSeriesInfo/AwpUtSeriesInfo";
import {MeasurementsAreaChart} from "../MeasurementDetails/MeasurementsAreaChart";
import {AwpUtSeries} from "../../models/AwpUtSeries";
import {formatAwpUtScaleName} from "../../helpers/AwpUtFormatHelper";
import {AwpMfMeasuredInfo} from "../AwpSeriesInfo/AwpMfMeasuredInfo";
import {AwpMfEstimatedInfo} from "../AwpSeriesInfo/AwpMfEstimatedInfo";
import {AwpUd2301DeviceInfo} from "../AwpSeriesInfo/AwpUd2301DeviceInfo";
import {AwpUd2301TransducerInfo} from "../AwpSeriesInfo/AwpUd2301TransducerInfo";
import {AwpUd2303DeviceInfo} from "../AwpSeriesInfo/AwpUd2303DeviceInfo";
import {AwpUd2303TransducerInfo} from "../AwpSeriesInfo/AwpUd2303TranducerInfo";
import {AwpUd3701DeviceInfo} from "../AwpSeriesInfo/AwpUd3701DeviceInfo";
import {AwpUd3701TransducerInfo} from "../AwpSeriesInfo/AwpUd3701TransducerInfo";
import {AwpUt3EmaDeviceInfo} from "../AwpSeriesInfo/AwpUt3EmaDeviceInfo";
import {AwpUt3EmaTransducerInfo} from "../AwpSeriesInfo/AwpUt3EmaTransducerInfo";
import {AwpUd2301StrobeInfo} from "../AwpSeriesInfo/AwpUd2301StrobeInfo";
import {AwpUd2303StrobeInfo} from "../AwpSeriesInfo/AwpUd2303StrobeInfo";
import {AwpUd2303ResultInfo} from "../AwpSeriesInfo/AwpUd2303ResultInfo";
import {AwpUd2301ResultInfo} from "../AwpSeriesInfo/AwpUd2301ResultInfo";
import {AwpUd3701StrobeInfo} from "../AwpSeriesInfo/AwpUd3701StrobeInfo";
import {AwpUd3701ResultInfo} from "../AwpSeriesInfo/AwpUd3701ResultInfo";
import {AwpUt3EmaResultInfo} from "../AwpSeriesInfo/AwpUt3EmaResultInfo";
import {AwpMfSeries} from "../../models/AwpMfSeries";
import {formatAwpMfScaleName} from "../../helpers/AwpMfFormatHelper";
import {AwpUt2ATransducerInfo} from "../AwpSeriesInfo/AwpUt2ATransducerInfo";
import {AwpUt2aDeviceInfo} from "../AwpSeriesInfo/AwpUt2ADeviceInfo";
import {useFileSystem} from "../../filesystem/AwpCloudStorageFileSystemHook";
import {AwpBreadcrumbItem} from "../AwpManager/AwpBreadcrumbItem";
import {formatAwpDeviceName} from "../../helpers/AwpOptions";
import {AwpBreadcrumbs} from "../AwpManager/AwpBreadcrumbs";
import {AwpIpsmSeries} from "../../models/AwpIpsmSeries";
import {AwpIpsmSeriesInfo} from "../AwpSeriesInfo/AwpIspmSeriesInfo";
import {AwpUt2aResultInfo} from "../AwpSeriesInfo/AwpUt2aResultInfo";

const CHART_MODE_GRAPH = 0;
const CHART_MODE_HISTOGRAM = 1;

interface PathParams {
    path: string;
    id: string;
}

interface Props {
    deviceType: number;
}

export function AwpDemoRecordView(props: Props) {
    const {t} = useTranslation();
    const locale = useLocale();
    const history = useHistory();
    const {path, id} = useParams<PathParams>();
    const folders = (path && path !== "@") ? path.split("@") : new Array<string>();
    const [toastData, setToastData] = useState(null as ToastData | null);
    const fsHandle = useFileSystem(props.deviceType, folders);
    const [activeRecord, setActiveRecord] = useState(FetchData.init<AwpRecord>());
    const [activeSeriesIndex, setActiveSeriesIndex] = useState(0);
    const [chartMode, setChartMode] = useState(CHART_MODE_GRAPH);
    const activeSeries: AwpSeries | undefined = useMemo(() => {
        return (activeRecord.data) ? activeRecord.data.series[activeSeriesIndex] : undefined;
    }, [activeRecord, activeSeriesIndex]);
    const [isPhotoLoaded, setPhotoLoaded] = useState(false);
    const fullScreenHandle = useFullScreenHandle();
    useEffect(() => {
        if (fsHandle?.handle && id) {
            setActiveRecord(FetchData.loading());
            readAwpRecord(props.deviceType, fsHandle.handle, id).then(record => {
                setActiveSeriesIndex(0);
                setActiveRecord(record ? FetchData.value(record) : FetchData.error<AwpRecord>());
            })
        }
    }, [props.deviceType, fsHandle, id]);
    const makeCsv = () => {
        if (activeRecord.data) {
            createAwpCsv(props.deviceType, activeRecord.data.name, activeRecord.data);
        }
    };
    const makePdf = () => {
        if (activeRecord.data) {
            createAwpPdf(props.deviceType, t, locale, logoPng, activeRecord.data).then(jsPDF => jsPDF.save(`${activeRecord.data?.name ?? "report"}.pdf`))
                .catch(() => setToastData({
                    message: t('pdf_save_failed'),
                    isSuccess: false,
                    delay: TOAST_DELAY
                }));
        }
    };
    const close = () => {
        if (history.length > 2) {
            history.goBack();
        } else {
            if (props.deviceType === AWP_DEVICE_TUD) {
                history.push(AWP_TUD_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_KRC) {
                history.push(AWP_KRC_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_TP) {
                history.push(AWP_TP_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_UT) {
                history.push(AWP_UT_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_MF) {
                history.push(AWP_MF_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_UD2301) {
                history.push(AWP_UD2301_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_UD2303) {
                history.push(AWP_UD2303_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_UD3701) {
                history.push(AWP_UD3701_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_UT3EMA) {
                history.push(AWP_UT3EMA_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_UT2A) {
                history.push(AWP_UT2A_ROUTE);
            }
            if (props.deviceType === AWP_DEVICE_IPSM) {
                history.push(AWP_IPSM_ROUTE);
            }
        }
    };
    const keyDownListener = (ev: KeyboardEvent) => {
        if (ev.key === 'Escape' || ev.key === 'Esc') {
            close();
            return false;
        }
        return true;
    }
    useEffect(() => {
        document.addEventListener("keydown", keyDownListener);
        return () => {
            document.removeEventListener("keydown", keyDownListener);
        }
    });
    const markerClickHandler = (index: number) => {
        setActiveSeriesIndex(index);
        if (fullScreenHandle.active) {
            fullScreenHandle.exit().then();
        }
    }
    const manualFullScreenCloseHandler = () => {
        if (fullScreenHandle.active) {
            fullScreenHandle.exit().then();
        }
    }
    const breadcrumbs = new Array<AwpBreadcrumbItem>();
    breadcrumbs.push({
        displayName: formatAwpDeviceName(t, props.deviceType) ?? "",
        path: buildAwpListPath(props.deviceType, [])
    });
    breadcrumbs.push(...folders.map((folder, i) => {
        return {
            displayName: folder,
            path: buildAwpListPath(props.deviceType, folders.slice(0, i + 1))
        } as AwpBreadcrumbItem;
    }));
    breadcrumbs.push({
        displayName: activeRecord.data?.name ?? "",
        path: ""
    });
    return (
        <div className="container-grow">
            <div id="pdf-canvas-container" style={{position: "absolute", top: "-10000px", left: "-10000px"}}>
                <canvas id="pdf-canvas"/>
            </div>
            <div className="d-flex flew-row justify-content-between align-items-center">
                <ButtonGroup className="my-4">
                    <Button variant="outline-primary"
                            onClick={makeCsv}><IconCsvSvg/></Button>
                    <Button variant="outline-primary"
                            onClick={makePdf}><IconPdfSvg/></Button>
                </ButtonGroup>
                <div className="d-flex flex-grow-1 mx-4">
                    <AwpBreadcrumbs
                        path={breadcrumbs}
                        locationChangeListener={path => history.push(path)}/>
                </div>
                <div className="button-close" onClick={close}><IconCloseSvg/></div>
            </div>
            <HorizontalDivider/>
            <div className="container-grow">
                {fsHandle === undefined && <Loader/>}
                {id && activeRecord.hasError &&
                    <div
                        className="container-grow d-flex flex-column align-items-center justify-content-center m-2">
                        <Error errorMessage={t("record_loading_error")}/>
                    </div>
                }
                {id && activeRecord.isLoading &&
                    <div
                        className="container-grow d-flex flex-column align-items-center justify-content-center m-2">
                        <Loader/>
                    </div>
                }
                {activeRecord.data &&
                    <Fragment>
                        <div className="container-grow">
                            <h3 className="mt-4">{activeRecord.data.name}</h3>
                            <Row xs={1} sm={1} md={2} xl={3}>
                                {activeRecord.data.deviceSerial &&
                                    <Col className="d-flex flex-column flex-grow-1">
                                        <ViewContainer title={t('device')} reserveSpaceForDataTime={true}>
                                            <div className="d-flex flex-column justify-content-start">
                                                <span className="view-item-title mr-2">
                                                    {formatAwpDeviceDisplayName(t, props.deviceType, activeRecord.data.deviceSerial ?? "")}
                                                </span>
                                                <span className="view-item-description">
                                                    {activeRecord.data.deviceSerial}
                                                </span>
                                            </div>
                                        </ViewContainer>
                                    </Col>
                                }
                                {((props.deviceType === AWP_DEVICE_TUD || props.deviceType === AWP_DEVICE_KRC || props.deviceType === AWP_DEVICE_MF) && (activeRecord.data.probeType !== undefined && activeRecord.data.probeSerial !== undefined)) &&
                                    <Col className="d-flex flex-column flex-grow-1">
                                        <ViewContainer title={t('probe')} reserveSpaceForDataTime={true}>
                                            <div className="d-flex flex-column justify-content-start">
                                                <span
                                                    className="view-item-title mr-2">{formatAwpProbeName(t, props.deviceType, activeRecord.data)}</span>
                                                <span
                                                    className="view-item-description">{activeRecord.data.probeSerial}</span>
                                            </div>
                                        </ViewContainer>
                                    </Col>
                                }
                                {props.deviceType === AWP_DEVICE_TP && activeRecord.data.probeName !== undefined && activeRecord.data.probeSerial !== undefined &&
                                    <Col className="d-flex flex-column flex-grow-1">
                                        <ViewContainer title={t('probe')} reserveSpaceForDataTime={true}>
                                            <div className="d-flex flex-column justify-content-start">
                                                <span
                                                    className="view-item-title mr-2">{formatAwpProbeName(t, props.deviceType, activeRecord.data)}</span>
                                                <span
                                                    className="view-item-description">{activeRecord.data.probeSerial}</span>
                                            </div>
                                        </ViewContainer>
                                    </Col>
                                }
                                {props.deviceType === AWP_DEVICE_UT && activeRecord.data.probeName &&
                                    <Col className="d-flex flex-column flex-grow-1">
                                        <ViewContainer title={t('probe')} reserveSpaceForDataTime={true}>
                                            <div className="d-flex flex-column justify-content-start">
                                                <span
                                                    className="view-item-title mr-2">{formatAwpProbeName(t, props.deviceType, activeRecord.data)}</span>
                                            </div>
                                        </ViewContainer>
                                    </Col>
                                }
                                <Col className="d-flex flex-column flex-grow-1">
                                    <ViewContainer title={t('additional_info')} reserveSpaceForDataTime={true}>
                                        <div className="d-flex flex-column justify-content-start">
                                            <div className="d-flex flex-row ">
                                                <span className="view-item-title me-2">{t('operator')}:</span>
                                                <span
                                                    className="view-item-description single-line ellipsize">{activeRecord.data.operator ?? t('not_set')}</span>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <span className="view-item-title me-2">{t('object')}:</span>
                                                <span
                                                    className="view-item-description single-line ellipsize">{activeRecord.data.object ?? t('not_set')}</span>
                                            </div>
                                        </div>
                                    </ViewContainer>
                                </Col>
                            </Row>
                            {props.deviceType === AWP_DEVICE_UD2301 && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('parameters_ud2301')}
                                                           reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2301DeviceInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('transducer')} reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2301TransducerInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {props.deviceType === AWP_DEVICE_UD2303 && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('parameters_ud2303')}
                                                           reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2303DeviceInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer
                                                title={`${t('transducer')} ${formatAwpProbeName(t, props.deviceType, activeRecord.data)}`}
                                                reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2303TransducerInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {props.deviceType === AWP_DEVICE_UD3701 && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('parameters_ud3701')}
                                                           reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd3701DeviceInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('transducer')} reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd3701TransducerInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {props.deviceType === AWP_DEVICE_UT3EMA && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('parameters_ut3ema')}
                                                           reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUt3EmaDeviceInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('transducer')} reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUt3EmaTransducerInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {props.deviceType === AWP_DEVICE_UT2A && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer title={t('parameters_ut2a')}
                                                           reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUt2aDeviceInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer
                                                title={t('transducer')}
                                                reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUt2ATransducerInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {hasAwpPhoto(props.deviceType) && activeRecord.data.photo &&
                                <Row xs={1} sm={1} md={2} xl={3}>
                                    <Col md={{span: 12}} lg={{span: 12}} xl={{span: 8}}>
                                        <ViewContainerWithEnlarge title={t('measurements')}
                                                                  dateTime={activeRecord.data?.date?.getTime()}
                                                                  enlargeClickHandler={fullScreenHandle.enter}
                                                                  isLoaded={isPhotoLoaded}>
                                            <FullScreen className="d-flex flex-column flex-grow-1"
                                                        handle={fullScreenHandle}>
                                                <AwpPhotoViewer url={activeRecord.data.photo}
                                                                markers={prepareAwpMarkers(props.deviceType, activeRecord.data.series)}
                                                                imageLoadListener={() => setPhotoLoaded(true)}
                                                                isFullScreen={fullScreenHandle.active}
                                                                fullScreenCloseClickListener={manualFullScreenCloseHandler}
                                                                markerClickListener={markerClickHandler}
                                                                flipImage={true}/>
                                            </FullScreen>
                                        </ViewContainerWithEnlarge>
                                    </Col>
                                </Row>
                            }
                            {(props.deviceType === AWP_DEVICE_UD2301 || props.deviceType === AWP_DEVICE_UD2303 || props.deviceType === AWP_DEVICE_UD3701) && activeSeries &&
                                <div
                                    className="d-flex flex-row align-self-stretch align-items-center justify-content-center my-2">
                                    <h4 className="m-0">{t('awp_strobes_and_measurement_results')}</h4>
                                </div>
                            }
                            {props.deviceType === AWP_DEVICE_UT3EMA && activeSeries &&
                                <div
                                    className="d-flex flex-column align-self-stretch align-items-center justify-content-center my-2">
                                    <h4 className="m-0">{t('awp_measurement_results')}</h4>
                                    <AwpUt3EmaResultInfo data={activeSeries}/>
                                </div>
                            }
                            {props.deviceType === AWP_DEVICE_UT2A && activeSeries &&
                                <div
                                    className="d-flex flex-column align-self-stretch align-items-center justify-content-center my-2">
                                    <h4 className="m-0">{t('awp_measurement_results')}</h4>
                                    <AwpUt2aResultInfo data={activeSeries}/>
                                </div>
                            }
                            {hasAwpScreen(props.deviceType) && activeRecord.data.photo &&
                                <Row xs={1} sm={1} md={2} xl={3}>
                                    <Col md={{span: 12}} lg={{span: 12}} xl={{span: 8}}>
                                        <ViewContainerWithEnlarge dateTime={activeRecord.data?.date?.getTime()}
                                                                  enlargeClickHandler={fullScreenHandle.enter}
                                                                  isLoaded={isPhotoLoaded}>
                                            <FullScreen className="d-flex flex-column flex-grow-1"
                                                        handle={fullScreenHandle}>
                                                <AwpPhotoViewer url={activeRecord.data.photo}
                                                                imageLoadListener={() => setPhotoLoaded(true)}
                                                                isFullScreen={fullScreenHandle.active}
                                                                fullScreenCloseClickListener={manualFullScreenCloseHandler}
                                                                markerClickListener={markerClickHandler}
                                                                flipImage={false}/>
                                            </FullScreen>
                                        </ViewContainerWithEnlarge>
                                    </Col>
                                </Row>
                            }
                            {(props.deviceType === AWP_DEVICE_TUD || props.deviceType === AWP_DEVICE_KRC || props.deviceType === AWP_DEVICE_TP || props.deviceType === AWP_DEVICE_UT || props.deviceType === AWP_DEVICE_MF || props.deviceType === AWP_DEVICE_IPSM) && activeSeries &&
                                <Fragment>
                                    <div
                                        className="d-flex flex-row align-self-stretch align-items-center justify-content-center my-2">
                                        <Button className="mx-4" size="sm"
                                                style={{visibility: activeSeriesIndex > 0 ? 'visible' : 'hidden'}}
                                                onClick={() => setActiveSeriesIndex(activeSeriesIndex - 1)}>{"<<"}</Button>
                                        {(props.deviceType === AWP_DEVICE_TUD || props.deviceType === AWP_DEVICE_KRC || props.deviceType === AWP_DEVICE_TP || props.deviceType === AWP_DEVICE_UT || props.deviceType === AWP_DEVICE_MF) &&
                                            <h4 className="m-0">{activeRecord.data.series.length > 1 ? t('measurement_series_format', {index: activeSeriesIndex + 1}) : t('measurement_series')}</h4>
                                        }
                                        {props.deviceType === AWP_DEVICE_IPSM &&
                                            <h4 className="m-0">{activeRecord.data.series.length > 1 ? t('group_index', {index: activeSeriesIndex + 1}) : t('measurement_group')}</h4>
                                        }
                                        <Button className="mx-4" size="sm"
                                                style={{visibility: (activeSeriesIndex < activeRecord.data.series.length - 1) ? 'visible' : 'hidden'}}
                                                onClick={() => setActiveSeriesIndex(activeSeriesIndex + 1)}>{">>"}</Button>
                                    </div>
                                    <div
                                        className="d-flex flex-row align-self-stretch align-items-center justify-content-center">
                                        <h5>{getAwpSeriesTitle(props.deviceType, t, activeSeries)}</h5>
                                    </div>
                                    <Row xs={1} sm={1} md={2} xl={2} className={props.deviceType === AWP_DEVICE_IPSM ? "justify-content-center" : ""}>
                                        {(props.deviceType === AWP_DEVICE_TUD || props.deviceType === AWP_DEVICE_KRC || props.deviceType === AWP_DEVICE_TP) &&
                                            <Col>
                                                <ViewContainer className="container-measurement-info">
                                                    <AwpMeasurementSeriesInfo
                                                        deviceType={props.deviceType}
                                                        data={activeSeries}/>
                                                </ViewContainer>
                                            </Col>
                                        }
                                        {props.deviceType === AWP_DEVICE_IPSM &&
                                            <Col>
                                                <ViewContainer>
                                                    <AwpIpsmSeriesInfo
                                                        data={activeSeries as AwpIpsmSeries}/>
                                                </ViewContainer>
                                            </Col>
                                        }
                                        {props.deviceType === AWP_DEVICE_UT &&
                                            <Col>
                                                <ViewContainer className="container-measurement-info">
                                                    <AwpUtSeriesInfo
                                                        data={activeSeries}/>
                                                </ViewContainer>
                                            </Col>
                                        }
                                        {(props.deviceType === AWP_DEVICE_TUD || props.deviceType === AWP_DEVICE_KRC || props.deviceType === AWP_DEVICE_TP) &&
                                            <Col>
                                                <ViewContainer className="container-measurement-info">
                                                    <Fragment>
                                                        <ModeSwitcher className="align-self-center" size="sm"
                                                                      minWidth="150px"
                                                                      modes={[t('graph'), t('histogram')]}
                                                                      mode={chartMode}
                                                                      modeChangeListener={setChartMode}/>
                                                        {chartMode === CHART_MODE_GRAPH &&
                                                            <MeasurementsLineChart classNames="flex-grow-1"
                                                                                   data={prepareAwpLineChartData(props.deviceType, activeSeries)}
                                                                                   measurementError={getAwpMeasurementError(props.deviceType, activeSeries)}
                                                                                   name={getAwpLineChartName(props.deviceType, t, activeSeries)}
                                                                                   valueFormatter={v => formatAwpValue(props.deviceType, v, activeSeries)}/>
                                                        }
                                                        {chartMode === CHART_MODE_HISTOGRAM &&
                                                            <MeasurementsBarChart classNames="flex-grow-1"
                                                                                  data={prepareAwpBarChartData(props.deviceType, activeSeries)}/>
                                                        }
                                                    </Fragment>
                                                </ViewContainer>
                                            </Col>
                                        }
                                        {props.deviceType === AWP_DEVICE_UT &&
                                            <Col>
                                                <ViewContainer className="container-measurement-info">
                                                    <MeasurementsAreaChart classNames="flex-grow-1"
                                                                           data={prepareAwpAreaChartData(props.deviceType, activeSeries)}
                                                                           measurementError={getAwpMeasurementError(props.deviceType, activeSeries)}
                                                                           name={getAwpAreaChartName(props.deviceType, t, activeSeries)}
                                                                           valueFormatter={v => `${formatAwpValue(props.deviceType, v, activeSeries)} ${formatAwpUtScaleName(t, (activeSeries as AwpUtSeries).measure.scale)}`}/>
                                                </ViewContainer>
                                            </Col>
                                        }
                                        {props.deviceType === AWP_DEVICE_MF &&
                                            <Fragment>
                                                <Col className="d-flex flex-column flex-grow-1">
                                                    <ViewContainer title={t('measured_value')}>
                                                        <AwpMfMeasuredInfo data={activeSeries}/>
                                                    </ViewContainer>
                                                </Col>
                                                <Col className="d-flex flex-column flex-grow-1">
                                                    <ViewContainer title={t('estimated_values')}>
                                                        <AwpMfEstimatedInfo data={activeSeries}/>
                                                    </ViewContainer>
                                                </Col>
                                                {(activeSeries as AwpMfSeries).mode !== 0 &&
                                                    <Col className="w-100">
                                                        <ViewContainer className="container-measurement-info">
                                                            <MeasurementsAreaChart classNames="flex-grow-1"
                                                                                   data={prepareAwpAreaChartData(props.deviceType, activeSeries)}
                                                                                   measurementError={getAwpMeasurementError(props.deviceType, activeSeries)}
                                                                                   name={getAwpAreaChartName(props.deviceType, t, activeSeries)}
                                                                                   valueFormatter={v => `${formatAwpValue(props.deviceType, v, activeSeries)} ${formatAwpMfScaleName(t, (activeSeries as AwpMfSeries).scale)}`}/>
                                                        </ViewContainer>
                                                    </Col>
                                                }
                                            </Fragment>
                                        }
                                    </Row>
                                </Fragment>
                            }
                            {props.deviceType === AWP_DEVICE_UD2301 && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer
                                                reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2301StrobeInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2301ResultInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {props.deviceType === AWP_DEVICE_UD2303 && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer
                                                reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2303StrobeInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd2303ResultInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            {props.deviceType === AWP_DEVICE_UD3701 && activeSeries &&
                                <Fragment>
                                    <Row xs={1} sm={1} md={2} xl={3}>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer
                                                reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd3701StrobeInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                        <Col className="d-flex flex-column flex-grow-1">
                                            <ViewContainer reserveSpaceForDataTime={true}>
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start">
                                                    <AwpUd3701ResultInfo data={activeSeries}/>
                                                </div>
                                            </ViewContainer>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                            <Row xs={1} sm={1} md={2} xl={3}>
                                <Col className="d-flex flex-column flex-grow-1">
                                    <ViewContainer title={t('conclusion')}>
                                        <div className="d-flex flex-column justify-content-start">
                                            <span
                                                className="view-item-description line-break">{((activeRecord.data.conclusion ?? "") === "") ? t('conclusion_not_set') : activeRecord.data.conclusion}</span>
                                        </div>
                                    </ViewContainer>
                                </Col>
                            </Row>
                        </div>
                    </Fragment>
                }
            </div>
            <ToastContainer position={"bottom-end"} className="mb-4 me-4 position-fixed">
                <Toast onClose={() => setToastData(null)} show={toastData !== null}
                       bg={toastData?.isSuccess !== undefined ? (toastData.isSuccess ? 'success' : 'danger') : 'primary'}
                       delay={toastData?.delay ?? 0} autohide={toastData?.delay !== undefined}>
                    <ToastBody>{toastData?.message}</ToastBody>
                </Toast>
            </ToastContainer>
        </div>
    );
}