import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {Loader} from "../Loader/Loader";
import {EmptyArchivePlaceholder} from "../AwpRecordList/EmptyArchivePlaceholder";
import {ToolBarButton} from "../ToolBarButton/ToolBarButton";
import IconInfoSvg from "../Icons/IconInfoSvg";
import {AwpRecordList} from "../AwpRecordList/AwpRecordList";
import {AwpRecordPreview} from "../AwpRecordPreview/AwpRecordPreview";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {buildAwpListPath, buildAwpRecordPath} from "../../routes";
import {FetchData} from "../../models/FetchData";
import {AwpRecordInfo} from "../../models/AwpRecordInfo";
import {AwpRecord, readAwpRecord, readAwpRecordInfo} from "../../helpers/AwpHelper";
import {useFileSystem} from "../../filesystem/AwpCloudStorageFileSystemHook";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {SHOW_PREVIEW} from "../../persistence";
import {isMobile} from "react-device-detect";
import {useScreenSize} from "../../hooks/ScreenSizeHooks";
import {MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW} from "../../AppSettings";
import {AwpBreadcrumbItem} from "./AwpBreadcrumbItem";
import {formatAwpDeviceName} from "../../helpers/AwpOptions";
import {AwpBreadcrumbs} from "./AwpBreadcrumbs";

interface PathParams {
    path: string;
}

interface Props {
    deviceType: number;
}

export function AwpDemoManager(props: Props) {
    const {t} = useTranslation();
    const history = useHistory();
    useEffect(() => {
        document.querySelector('body')?.classList?.add('body-awp');
        return () => {
            document.querySelector('body')?.classList?.remove('body-awp');
        }
    });
    const {path} = useParams<PathParams>();
    const folders = (path && path !== "@") ? path.split("@") : new Array<string>();
    const fsHandle = useFileSystem(props.deviceType, folders);
    const [showPreview, setShowPreview] = usePersistentState(SHOW_PREVIEW, !isMobile || window.innerWidth > window.innerHeight);
    const [records, setRecords] = useState(FetchData.init<Array<AwpRecordInfo>>());
    const [activeRecord, setActiveRecord] = useState(FetchData.init<AwpRecord>());
    const [highlightedItems, setHighlightedItems] = useState(() => new Array<string>());
    const singleHighlightedItem = highlightedItems.length === 1 ? highlightedItems[0] : undefined;
    const {toggleMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [contextRecordFileNames, setContextRecordFileNames] = useState(null as Array<string> | null);
    const [width] = useScreenSize();
    const isPreviewAvailable = width > MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW;
    useEffect(() => {
        if (singleHighlightedItem && (!records.data || records.data.every(r => r.fileName !== singleHighlightedItem))) {
            setHighlightedItems(new Array<string>());
        }
    }, [records]);
    useEffect(() => {
        if (fsHandle?.handle && singleHighlightedItem && records.data) {
            setActiveRecord(FetchData.loading<AwpRecord>());
            const info = records.data.find(v => v.fileName === singleHighlightedItem);
            if (info) {
                if (info.isFolder) {
                    setActiveRecord(FetchData.init<AwpRecord>());
                } else {
                    readAwpRecord(props.deviceType, fsHandle.handle, singleHighlightedItem).then(record => {
                        setActiveRecord(record ? FetchData.value(record) : FetchData.error<AwpRecord>());
                    });
                }
            }
        } else {
            setActiveRecord(FetchData.init<AwpRecord>());
        }
    }, [fsHandle, singleHighlightedItem]);
    const updateRecords = () => {
        if (fsHandle?.handle) {
            setRecords(FetchData.loading());
            const promise: Promise<Array<AwpRecordInfo>> = new Promise(async (resolve) => {
                const results = new Array<AwpRecordInfo>();
                if (fsHandle.handle) {
                    const entries = await fsHandle.handle.directories();
                    for (const entry of entries) {
                        const info = await readAwpRecordInfo(props.deviceType, entry);
                        if (info) {
                            results.push(info);
                        } else {
                            results.push({
                                fileName: entry.name(),
                                isFolder: true,
                                name: entry.name(),
                                date: undefined,
                                probeName: "",
                                probeType: undefined,
                                probeId: "",
                                deviceId: ""
                            });
                        }
                    }
                }
                resolve(results);
            });
            promise.then(records => setRecords(FetchData.value(records)));
        } else {
            setRecords(FetchData.init<Array<AwpRecordInfo>>());
        }
    }
    useEffect(() => {
        updateRecords();
    }, [fsHandle]);
    const showRecord = (fileNames: Array<string>) => {
        if (fileNames.length > 0) {
            const fileName = fileNames[0];
            if (fileName === "" && folders.length > 0) {
                folders.pop();
                const newPath = buildAwpListPath(props.deviceType, [...folders]);
                history.push(newPath);
            }
            if (records.data) {
                const info = records.data.find(v => v.fileName === fileName);
                if (info) {
                    const newPath = info.isFolder ? buildAwpListPath(props.deviceType, [...folders, fileName]) : buildAwpRecordPath(props.deviceType, folders, fileName);
                    history.push(newPath);
                }
            }
        }
    };
    const showRecordNewTab = (fileNames: Array<string>) => {
        if (fileNames.length > 0) {
            const fileName = fileNames[0];
            if (records.data) {
                const info = records.data.find(v => v.fileName === fileName);
                if (info) {
                    const newPath = info.isFolder ? buildAwpListPath(props.deviceType, folders) : buildAwpRecordPath(props.deviceType, folders, fileName);
                    window.open(newPath, '_blank');
                }
            }
        }
    };
    const contextMenuHandler = (id: string, x: number, y: number) => {
        setAnchorPoint({x: x, y: y});
        if (highlightedItems.includes(id)) {
            const records = new Array<string>();
            records.push(id);
            records.push(...highlightedItems.filter(i => i !== id));
            setContextRecordFileNames(records);
        } else {
            setContextRecordFileNames([id]);
        }
        toggleMenu(true);
    };
    const openContextRecord = () => {
        if (contextRecordFileNames) {
            showRecord(contextRecordFileNames);
        }
    };
    const openContextRecordNewTab = () => {
        if (contextRecordFileNames) {
            showRecordNewTab(contextRecordFileNames);
        }
    };
    const handleItemClick = (id?: string) => {
        if (id) {
            setHighlightedItems(new Array<string>(...[id]));
        } else {
            setHighlightedItems(new Array<string>());
        }
    }
    const breadcrumbs = new Array<AwpBreadcrumbItem>();
    breadcrumbs.push({
        displayName: formatAwpDeviceName(t, props.deviceType) ?? "",
        path: buildAwpListPath(props.deviceType, [])
    });
    breadcrumbs.push(...folders.map((folder, i) => {
        return {
            displayName: folder,
            path: buildAwpListPath(props.deviceType, folders.slice(0, i + 1))
        } as AwpBreadcrumbItem;
    }));
    return (
        <div className="container-grow d-flex flex-column mx-4 mt-4">
            <div className="container-grow align-self-stretch">
                {records.isLoading && <Loader/>}
                {records.data && records.data.length === 0 && <EmptyArchivePlaceholder/>}
                {records.data && records.data.length > 0 &&
                    <Fragment>
                        <div className="d-flex flex-row justify-content-end">
                            <div className="d-flex flex-grow-1">
                                <AwpBreadcrumbs
                                    path={breadcrumbs}
                                    locationChangeListener={path => history.push(path)}/>
                            </div>
                            {isPreviewAvailable &&
                                <Fragment>
                                    <div className="mx-2"/>
                                    <ToolBarButton icon={<IconInfoSvg/>} activated={showPreview}
                                                   clickHandler={() => setShowPreview(!showPreview)}/>
                                </Fragment>
                            }
                        </div>
                        <HorizontalDivider className="my-1"/>
                        <div className="container-grow container-records flex-row">
                            <div
                                className="d-flex flex-grow-1 flex-column align-self-stretch m-2 overflow-y-auto"
                                onClick={() => handleItemClick()}>
                                <AwpRecordList isRoot={folders.length === 0} deviceType={props.deviceType}
                                               records={records.data}
                                               highlightedItems={highlightedItems}
                                               itemHighlightListener={handleItemClick}
                                               itemDoubleClickListener={(id) => showRecord([id])}
                                               itemContextClickListener={contextMenuHandler}/>
                            </div>
                            {isPreviewAvailable &&
                                <AwpRecordPreview deviceType={props.deviceType} showPreview={showPreview}
                                                  record={activeRecord} count={highlightedItems.length}
                                                  onClose={() => setShowPreview(false)}
                                                  onDetailsClick={() => showRecord(highlightedItems)}/>
                            }
                        </div>
                    </Fragment>
                }
            </div>
            <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                            onClose={() => toggleMenu(false)} menuClassName={"context-menu"}>
                <MenuItem onClick={openContextRecord}>{t("open")}</MenuItem>
                <MenuItem onClick={openContextRecordNewTab}>{t("open_new_tab")}</MenuItem>
            </ControlledMenu>
        </div>
    );
}