import {TFunction} from "react-i18next";
import {formatNumber} from "./FormatHelper";
import i18next from "i18next";

const MM = 0;
const INCH = 1;

export function formatEnAwpUtScaleName(scale: number) {
    const t = i18next.getFixedT("en");
    return formatAwpUtScaleName(t, scale);
}

export function formatAwpUtScaleName(t: TFunction<"translation">, scale: number) {
    switch (scale) {
        case MM:
            return t('scale_mm');
        case INCH:
            return t('scale_inch');
    }
    return "";
}

export function formatAwpUtValue(value: number){
    return formatNumber(value, 2, 2);
}

export function convertAwpUtValue(targetScale: number, value: number) {
    return convertAwpUtValueFromSource(MM, targetScale, value);
}

export function convertAwpUtValueFromSource(sourceScale: number, targetScale: number, value: number) {
    switch (sourceScale) {
        case MM:
            switch (targetScale) {
                case MM:
                    return value;
                case INCH:
                    return value / 25.4;
            }
            break;
        case INCH:
            switch (targetScale) {
                case MM:
                    return value * 25.4;
                case INCH:
                    return value;
            }
            break;
    }
    return value;
}