import packageInfo from '../../package.json';
import {DynamicLinkInfo} from "../models/DynamicLinkInfo";
import {API_KEY, IS_DEMO_APP} from "../AppSettings";

const REQUEST_URL = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks";

const SHORT_LINK_DOMAIN = "https://archive.novotest.biz/r";
const DEMO_SHORT_LINK_DOMAIN = "https://archive.novotest.biz/d";
const ANDROID_PACKAGE_NAME = "ua.novotest.bluetooth";
const ANDROID_MIN_VERSION_CODE = "9062";

const SOCIAL_MEDIA_IMAGE_URL = "https://www.novotest.info/logo192.png";

function getDevelopmentDomainName() {
    return IS_DEMO_APP ? "https://demo.novotest.info" : "https://novotest.info";
}

export function getDomainName() {
    return process.env.NODE_ENV === 'development' ? getDevelopmentDomainName() : window.location.href.replace(window.location.pathname, "");
}

export function getAndroidDynamicLink(url: string) {
    return `${SHORT_LINK_DOMAIN}/?link=${url}&apn=${ANDROID_PACKAGE_NAME}&amv=${ANDROID_MIN_VERSION_CODE}`;
}

function getDynamicLinkInfo(url: string, recordName: string){
    const socialTitle = `${packageInfo.title} - ${recordName}`;
    const socialDescription = `${packageInfo.description} - ${recordName}`;
    if (IS_DEMO_APP){
        return {
            domainUriPrefix: DEMO_SHORT_LINK_DOMAIN,
            link: url,
            socialMetaTagInfo: {
                socialTitle: socialTitle,
                socialDescription: socialDescription,
                socialImageLink: SOCIAL_MEDIA_IMAGE_URL
            }
        } as DynamicLinkInfo;
    } else {
        return {
            domainUriPrefix: SHORT_LINK_DOMAIN,
            link: url,
            androidInfo: {
                androidPackageName: ANDROID_PACKAGE_NAME,
                androidFallbackLink: url,
                androidMinPackageVersionCode: ANDROID_MIN_VERSION_CODE
            },
            socialMetaTagInfo: {
                socialTitle: socialTitle,
                socialDescription: socialDescription,
                socialImageLink: SOCIAL_MEDIA_IMAGE_URL
            }
        } as DynamicLinkInfo;
    }
}

export function getShortLink(url: string, recordName: string): Promise<string | null> {
    const dynamicLinkInfo = getDynamicLinkInfo(url, recordName);
    const requestUrl = `${REQUEST_URL}?key=${API_KEY}`;
    return fetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({dynamicLinkInfo: dynamicLinkInfo})
    }).then(response => {
        if (response.ok) {
            return response.json().then(json => json.shortLink);
        }
        return null;
    }).catch(() => {
        return null;
    });
}