import {MediaRecord} from "../models/MediaRecord";
import {TudMeasurement} from "../models/TudMeasurement";
import {TpMeasurement} from "../models/TpMeasurement";
import {UtMeasurement} from "../models/UtMeasurement";
import {GeoLocation} from "../models/GeoLocation";
import {jsPDF} from "jspdf";
import {
    Drawer,
    drawFooter,
    drawHeader,
    drawMeasurementsWithoutPhoto,
    drawMeasurementWithPhoto,
    drawPhotoWithMeasurements,
    FONT_FAMILY,
    prepareInfoPages
} from "./PdfPageDrawer";
import {formatDateTime, formatDeviceName, formatTpProbeName, formatTudProbeName} from "../helpers/FormatHelper";
import {TFunction} from "react-i18next";
import {getImageDataFromBase64, getImageDataFromUrl, ImageData} from "./ImageData";
import QRCode from 'qrcode';
import * as TudProbe from "../models/TudProbe";
import * as TpProbe from "../models/TpProbe";
import {roboto} from "./RobotoFont";
import {robotoBold} from "./RobotoBoldFont";
import {buildMapsUrl} from "../helpers/GoogleMapsHelper";
import {UciMeasurement} from "../models/UciMeasurement";
import {MfMeasurement} from "../models/MfMeasurement";
import {TpSurfaceTemperatureMeasurement} from "../models/TpSurfaceTemperatureMeasurement";
import {TpDewPointMeasurement} from "../models/TpDewPointMeasurement";
import {TP1M} from "../models/DeviceType";
import {
    COATING_THICKNESS_MEASUREMENT_TYPE,
    DEW_POINT_MEASUREMENT_TYPE,
    SURFACE_TEMPERATURE_MEASUREMENT_TYPE
} from "../models/TpRecord";
import {LeebMeasurement} from "../models/LeebMeasurement";

export function createTudRecordPdf(t: TFunction<"translation">,
                                   locale: string,
                                   logo: ImageData,
                                   name: string,
                                   userName: string,
                                   userOrganization: string,
                                   userSignature: ImageData | undefined,
                                   deviceType: string,
                                   deviceId: string,
                                   probe: number | undefined,
                                   probeId: string | undefined,
                                   dateTime: number,
                                   measurementBitmap: ImageData | undefined,
                                   measurements: Array<TudMeasurement> | undefined,
                                   notes: string | undefined,
                                   audioRecords: Array<MediaRecord> | undefined,
                                   videoRecords: Array<MediaRecord> | undefined,
                                   location: GeoLocation | undefined,
                                   locationBitmap: ImageData | undefined,
                                   link: string,
                                   qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    let probeInfo = "";
    if (probe && probe !== TudProbe.NO_PROBE_VALUE && probeId) {
        probeInfo = `${formatTudProbeName(t, probe)} ${probeId}`;
    }
    const measurementsArray = measurements?.filter(m => m.n > 0);
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurementsArray, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode, null, null);
}

export function createLeebRecordPdf(t: TFunction<"translation">,
                                   locale: string,
                                   logo: ImageData,
                                   name: string,
                                   userName: string,
                                   userOrganization: string,
                                   userSignature: ImageData | undefined,
                                   deviceType: string,
                                   deviceId: string,
                                   dateTime: number,
                                   measurementBitmap: ImageData | undefined,
                                   measurements: Array<LeebMeasurement> | undefined,
                                   notes: string | undefined,
                                   audioRecords: Array<MediaRecord> | undefined,
                                   videoRecords: Array<MediaRecord> | undefined,
                                   location: GeoLocation | undefined,
                                   locationBitmap: ImageData | undefined,
                                   link: string,
                                   qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    let probeInfo = "";
    const measurementsArray = measurements?.filter(m => m.n > 0);
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurementsArray, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode, null, null);
}

export function createUciRecordPdf(t: TFunction<"translation">,
                                   locale: string,
                                   logo: ImageData,
                                   name: string,
                                   userName: string,
                                   userOrganization: string,
                                   userSignature: ImageData | undefined,
                                   deviceType: string,
                                   deviceId: string,
                                   dateTime: number,
                                   measurementBitmap: ImageData | undefined,
                                   measurements: Array<UciMeasurement> | undefined,
                                   notes: string | undefined,
                                   audioRecords: Array<MediaRecord> | undefined,
                                   videoRecords: Array<MediaRecord> | undefined,
                                   location: GeoLocation | undefined,
                                   locationBitmap: ImageData | undefined,
                                   link: string,
                                   qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    let probeInfo = "";
    const measurementsArray = measurements?.filter(m => m.count > 0);
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurementsArray, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode, null, null);
}

export function createTpRecordPdf(t: TFunction<"translation">,
                                  locale: string,
                                  logo: ImageData,
                                  name: string,
                                  userName: string,
                                  userOrganization: string,
                                  userSignature: ImageData | undefined,
                                  deviceType: string,
                                  deviceId: string,
                                  probe: number | undefined,
                                  probeId: string | undefined,
                                  dateTime: number,
                                  measurementBitmap: ImageData | undefined,
                                  measurements: Array<TpMeasurement> | undefined,
                                  notes: string | undefined,
                                  audioRecords: Array<MediaRecord> | undefined,
                                  videoRecords: Array<MediaRecord> | undefined,
                                  location: GeoLocation | undefined,
                                  locationBitmap: ImageData | undefined,
                                  link: string,
                                  qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    let probeInfo = "";
    if (probe && probe !== TpProbe.NO_PROBE_VALUE && probeId) {
        probeInfo = `${formatTpProbeName(t, probe)} ${probeId}`;
    }
    const measurementsArray = measurements?.filter(m => m.n > 0);
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurementsArray, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode,  COATING_THICKNESS_MEASUREMENT_TYPE, null);
}

export function createTpSurfaceTemperatureRecordPdf(t: TFunction<"translation">,
                                                    locale: string,
                                                    logo: ImageData,
                                                    name: string,
                                                    userName: string,
                                                    userOrganization: string,
                                                    userSignature: ImageData | undefined,
                                                    deviceType: string,
                                                    deviceId: string,
                                                    probe: number | undefined,
                                                    probeId: string | undefined,
                                                    dateTime: number,
                                                    measurementBitmap: ImageData | undefined,
                                                    measurements: Array<TpSurfaceTemperatureMeasurement> | undefined,
                                                    notes: string | undefined,
                                                    audioRecords: Array<MediaRecord> | undefined,
                                                    videoRecords: Array<MediaRecord> | undefined,
                                                    location: GeoLocation | undefined,
                                                    locationBitmap: ImageData | undefined,
                                                    link: string,
                                                    qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    let probeInfo = "";
    if (probe && probe !== TpProbe.NO_PROBE_VALUE && probeId) {
        probeInfo = `${formatTpProbeName(t, probe)} ${probeId}`;
    }
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurements, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode,  SURFACE_TEMPERATURE_MEASUREMENT_TYPE, null);
}

export function createTpDewPointRecordPdf(t: TFunction<"translation">,
                                                    locale: string,
                                                    logo: ImageData,
                                                    name: string,
                                                    userName: string,
                                                    userOrganization: string,
                                                    userSignature: ImageData | undefined,
                                                    deviceType: string,
                                                    deviceId: string,
                                                    probe: number | undefined,
                                                    probeId: string | undefined,
                                                    dateTime: number,
                                                    measurementBitmap: ImageData | undefined,
                                                    measurements: Array<TpDewPointMeasurement> | undefined,
                                                    notes: string | undefined,
                                                    audioRecords: Array<MediaRecord> | undefined,
                                                    videoRecords: Array<MediaRecord> | undefined,
                                                    location: GeoLocation | undefined,
                                                    locationBitmap: ImageData | undefined,
                                                    link: string,
                                                    qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    let probeInfo = "";
    if (probe && probe !== TpProbe.NO_PROBE_VALUE && probeId) {
        probeInfo = `${formatTpProbeName(t, probe)} ${probeId}`;
    }
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurements, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode, DEW_POINT_MEASUREMENT_TYPE, null);
}

export function createUtRecordPdf(t: TFunction<"translation">,
                                  locale: string,
                                  logo: ImageData,
                                  name: string,
                                  userName: string,
                                  userOrganization: string,
                                  userSignature: ImageData | undefined,
                                  deviceType: string,
                                  deviceId: string,
                                  probeName: string | undefined,
                                  dateTime: number,
                                  measurementBitmap: ImageData | undefined,
                                  measurements: Array<UtMeasurement> | undefined,
                                  showAverage: boolean,
                                  notes: string | undefined,
                                  audioRecords: Array<MediaRecord> | undefined,
                                  videoRecords: Array<MediaRecord> | undefined,
                                  location: GeoLocation | undefined,
                                  locationBitmap: ImageData | undefined,
                                  link: string,
                                  qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    const probeInfo = probeName ? t("probe_format", {probe: probeName}) : "";
    const measurementsArray = measurements?.filter(m => m.measurementContacts.length > 0);
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurementsArray, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode, null, showAverage);
}

export function createMfRecordPdf(t: TFunction<"translation">,
                                  locale: string,
                                  logo: ImageData,
                                  name: string,
                                  userName: string,
                                  userOrganization: string,
                                  userSignature: ImageData | undefined,
                                  deviceType: string,
                                  deviceId: string,
                                  dateTime: number,
                                  measurementBitmap: ImageData | undefined,
                                  measurements: Array<MfMeasurement> | undefined,
                                  notes: string | undefined,
                                  audioRecords: Array<MediaRecord> | undefined,
                                  videoRecords: Array<MediaRecord> | undefined,
                                  location: GeoLocation | undefined,
                                  locationBitmap: ImageData | undefined,
                                  link: string,
                                  qrCode: ImageData,): Promise<jsPDF> {
    const deviceInfo = t("tool_info_format", {name: formatDeviceName(t, deviceType), id: deviceId});
    const probeInfo = "";
    const measurementsArray = measurements?.filter(m => m.measurements.length > 0);
    return create(t, locale, logo, deviceType, name, userName, userOrganization, userSignature, deviceInfo, probeInfo, dateTime, measurementBitmap, measurementsArray, notes, audioRecords, videoRecords, location, locationBitmap, link, qrCode, null, null);
}

function create(t: TFunction<"translation">,
                locale: string,
                logo: ImageData,
                deviceType: string,
                recordName: string,
                userName: string,
                userOrganization: string,
                userSignature: ImageData | undefined,
                deviceInfo: string,
                probeInfo: string,
                dateTime: number,
                measurementBitmap: ImageData | undefined,
                measurements: Array<TudMeasurement> | Array<LeebMeasurement> | Array<UciMeasurement> | Array<TpMeasurement> | Array<TpSurfaceTemperatureMeasurement> | Array<TpDewPointMeasurement> | Array<UtMeasurement> | Array<MfMeasurement> | undefined,
                notes: string | undefined,
                audioRecords: Array<MediaRecord> | undefined,
                videoRecords: Array<MediaRecord> | undefined,
                location: GeoLocation | undefined,
                locationBitmap: ImageData | undefined,
                link: string,
                qrCode: ImageData,
                measurementType: number | null,
                showAverage: boolean | null): Promise<jsPDF> {
    return QRCode.toDataURL(link).then(code => getImageDataFromBase64(code)).then(qrBitmap => {
        return (location ? getImageDataFromUrl(buildMapsUrl(location, locale)) : Promise.resolve(undefined)).then(locationBitmap => {
            return new Promise((resolve) => {
                const recordDateTime = formatDateTime(locale, new Date(dateTime));
                const printDateTime = formatDateTime(locale, new Date());
                const pdfDocument = new jsPDF("p", "mm", "a4", true);
                pdfDocument.addFileToVFS("Roboto.ttf", roboto);
                pdfDocument.addFont('Roboto.ttf', FONT_FAMILY, 'normal');
                pdfDocument.addFileToVFS("RobotoBold.ttf", robotoBold);
                pdfDocument.addFont('RobotoBold.ttf', FONT_FAMILY, 'bold');
                let measurementIndex: number | undefined = undefined;
                let numberOfPages = 0;
                let pageIndex = 0;
                let infoPages: Array<Array<Drawer>> | undefined;
                while (true) {
                    if (pageIndex > 0) {
                        pdfDocument.addPage("a4", "p");
                    }
                    pageIndex++;
                    const contentBounds = drawHeader(t, pdfDocument, logo, qrBitmap, recordName, userName, userOrganization, deviceInfo, probeInfo, recordDateTime);
                    if (!infoPages) {
                        infoPages = prepareInfoPages(t, pdfDocument, contentBounds, location, locationBitmap, notes, audioRecords, videoRecords, link, userName, userSignature);
                        if (deviceType === TP1M && (measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE || measurementType === DEW_POINT_MEASUREMENT_TYPE)){
                            numberOfPages = (infoPages?.length ?? 0) + 1;
                        } else {
                            numberOfPages = (infoPages?.length ?? 0) + (measurementBitmap && measurements && measurements.length > 1 ? measurements.length + 1 : (measurements ? measurements.length : 0));
                        }
                    }
                    const pageNumberInfo = t("page_count_format", {page: pageIndex, pages: numberOfPages});
                    drawFooter(t, pdfDocument, printDateTime, pageNumberInfo);
                    if (measurements) {
                        if (measurementIndex === undefined) {
                            measurementIndex = 0;
                            if (measurementBitmap && measurements.length > 1) {
                                drawPhotoWithMeasurements(t, pdfDocument, contentBounds, deviceType, measurementType, measurements, showAverage, measurementBitmap);
                                if (deviceType === TP1M && (measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE || measurementType === DEW_POINT_MEASUREMENT_TYPE)){
                                    measurementIndex = measurements.length;
                                }
                                continue;
                            }
                        }
                        if (measurementIndex < measurements.length) {
                            if (measurementBitmap) {
                                drawMeasurementWithPhoto(t, pdfDocument, contentBounds, deviceType, measurementType, measurements, measurementIndex, measurements[measurementIndex], showAverage, measurementBitmap);
                            } else {
                                drawMeasurementsWithoutPhoto(t, pdfDocument, contentBounds, deviceType, measurementType, measurements, measurementIndex, measurements[measurementIndex], showAverage);
                            }
                            measurementIndex++;
                            if (measurementIndex < measurements.length || infoPages.length > 0) {
                                continue;
                            } else {
                                break;
                            }
                        }
                    }
                    if (infoPages.length > 0) {
                        const drawers = infoPages.shift();
                        for (const drawer of drawers!) {
                            drawer(pdfDocument);
                        }
                        if (infoPages.length > 0) {
                            continue;
                        }
                    }
                    break;
                }
                resolve(pdfDocument);
            });
        });
    });
}