import {parseFloat, parseInt32, parseString, parseUint16, parseUint8} from "../helpers/BinaryParseHelper";
import {TFunction} from "react-i18next";
import {convertAwpUtValue, formatAwpUtScaleName} from "../helpers/AwpUtFormatHelper";
import {AreaChartData} from "../components/MeasurementDetails/MeasurementsAreaChart";

const SERIES_SIZE_V1 = 6021;
const SERIES_SIZE_V2 = 6101;
const SERIES_SIZE_V3 = 6120;

const NAME_SIZE = 21;
const PROBE_NAME_SIZE = 21;
const DEVICE_SERIAL_SIZE_V1_V2 = 11;
const DEVICE_SERIAL_SIZE_V3 = 30;
const DIR_NAME_SIZE = 15;
const SCREEN_DATA_SIZE = 1024 + 62;
const MEASURE_SIZE = 2096;
const PROBES_SIZE = 1628;

const B_SCAN_SIZE = 512;
const PARAM_PROBE_COUNT = 20;
const PARAM_PROBE_SIZE = 81;

export interface AwpUtSeries {
    name: string;
    probeName: string;
    deviceSerial: string;
    dirName: string;
    screen: Uint8Array;
    measure: Measure;
    probes: Probes;
}

interface Measure {
    timeEtalon: number;
    speedMaterial: number;
    scale: number;
    time: number;
    thinc: number;
    maxThinc: number;
    minThinc: number;
    bScan: Array<number>;
    head: number;
    flagScan: number;
    currentTime: number;
    currentThinc: number;
    saveThinc: number;
    saveTime: number;
    acustContact: number;
}

interface Probes {
    usedProbe: number;
    gCorrKu: number;
    params: Array<ParamProbe>;
}

interface ParamProbe {
    name: string;
    deadTime: number;
    pulseTime: number;
    ku1: number;
    ku2: number;
    userKu: number;
    correctKu: number;
    mmTvg: number;
    dbTvg: number;
    porog: number;
    gate: number;
    v: number;
    kv: number;
    delay: number;
    cicl: number;
    fix: number;
}

export function parseUtSeries(buffer: ArrayBuffer): AwpUtSeries | null {
    let version;
    if (buffer.byteLength === SERIES_SIZE_V1){
        version = 1;
    } else
    if (buffer.byteLength === SERIES_SIZE_V2){
        version = 2;
    } else
    if (buffer.byteLength === SERIES_SIZE_V3){
        version = 3;
    } else {
        return null;
    }
    let offset = 0;
    const name = parseString(buffer, offset, NAME_SIZE);
    offset += NAME_SIZE;
    const probeName = parseString(buffer, offset, PROBE_NAME_SIZE);
    offset += PROBE_NAME_SIZE;
    let deviceSerial;
    if (version === 1 || version === 2) {
        deviceSerial = parseString(buffer, offset, DEVICE_SERIAL_SIZE_V1_V2);
        offset += DEVICE_SERIAL_SIZE_V1_V2;
    }
    if (version === 3){
        deviceSerial = parseString(buffer, offset, DEVICE_SERIAL_SIZE_V3);
        offset += DEVICE_SERIAL_SIZE_V3;
    }
    const dirName = parseString(buffer, offset, DIR_NAME_SIZE);
    offset += DIR_NAME_SIZE;
    const screen = new Uint8Array(buffer, offset, SCREEN_DATA_SIZE);
    offset += SCREEN_DATA_SIZE;
    const measure = parseMeasure(buffer, offset);
    offset += MEASURE_SIZE;
    const probes = parseProbes(buffer, offset);
    return {
        name: name,
        probeName: probeName,
        deviceSerial: deviceSerial ?? "",
        dirName: dirName,
        screen: screen,
        measure: measure,
        probes: probes
    }
}

function parseMeasure(initialBuffer: ArrayBuffer, initialOffset: number): Measure {
    let offset = 0;
    let buffer = initialBuffer.slice(initialOffset, initialOffset + MEASURE_SIZE);
    const timeEtalon = parseFloat(buffer, offset);
    offset += 4;
    const speedMaterial = parseInt32(buffer, offset);
    offset += 4;
    const scale = parseInt32(buffer, offset);
    offset += 4;
    const time = parseFloat(buffer, offset);
    offset += 4;
    const thinc = parseFloat(buffer, offset);
    offset += 4;
    const maxThinc = parseFloat(buffer, offset);
    offset += 4;
    const minThinc = parseFloat(buffer, offset);
    offset += 4;
    const bScan = new Float32Array(buffer, offset, B_SCAN_SIZE);
    offset += 4 * B_SCAN_SIZE;
    const head = parseUint16(buffer, offset);
    offset += 2;
    const flagScan = parseUint8(buffer, offset);
    offset += 1;
    buffer = buffer.slice(offset);
    offset = 0;
    const currentTime = parseFloat(buffer, offset);
    offset += 4;
    const currentThinc = parseFloat(buffer, offset);
    offset += 4;
    const saveThinc = parseFloat(buffer, offset);
    offset += 4;
    const saveTime = parseFloat(buffer, offset);
    offset += 4;
    const acustContact = parseUint8(buffer, offset);
    return {
        timeEtalon: timeEtalon,
        speedMaterial: speedMaterial,
        scale: scale,
        time: time,
        thinc: thinc,
        maxThinc: maxThinc,
        minThinc: minThinc,
        bScan: Array.from(bScan),
        head: head,
        flagScan: flagScan,
        currentTime: currentTime,
        currentThinc: currentThinc,
        saveThinc: saveThinc,
        saveTime: saveTime,
        acustContact: acustContact
    }
}

function parseProbes(initialBuffer: ArrayBuffer, initialOffset: number): Probes {
    let offset = 0;
    const buffer = initialBuffer.slice(initialOffset, initialOffset + PROBES_SIZE);
    const usedProbe = parseInt32(buffer, offset);
    offset += 4;
    const gCorrKu = parseFloat(buffer, offset);
    offset += 4;
    const paramProbes = new Array<ParamProbe>();
    for (let i = 0; i < PARAM_PROBE_COUNT; i++) {
        paramProbes.push(parseParamProbes(buffer, offset));
        offset += PARAM_PROBE_SIZE;
    }
    return {
        usedProbe: usedProbe,
        gCorrKu: gCorrKu,
        params: paramProbes
    }
}

function parseParamProbes(initialBuffer: ArrayBuffer, initialOffset: number): ParamProbe {
    const name = parseString(initialBuffer, initialOffset, NAME_SIZE);
    let offset = 0;
    const buffer = initialBuffer.slice(initialOffset + NAME_SIZE, initialOffset + NAME_SIZE + PARAM_PROBE_SIZE);
    const deadTime = parseInt32(buffer, offset);
    offset += 4;
    const pulseTime = parseInt32(buffer, offset);
    offset += 4;
    const ku1 = parseFloat(buffer, offset);
    offset += 4;
    const ku2 = parseFloat(buffer, offset);
    offset += 4;
    const userKu = parseFloat(buffer, offset);
    offset += 4;
    const correctKu = parseFloat(buffer, offset);
    offset += 4;
    const mmTvg = parseInt32(buffer, offset);
    offset += 4;
    const dbTvg = parseFloat(buffer, offset);
    offset += 4;
    const porog = parseInt32(buffer, offset);
    offset += 4;
    const gate = parseInt32(buffer, offset);
    offset += 4;
    const v = parseInt32(buffer, offset);
    offset += 4;
    const kV = parseFloat(buffer, offset);
    offset += 4;
    const delay = parseFloat(buffer, offset);
    offset += 4;
    const cicl = parseInt32(buffer, offset);
    offset += 4;
    const fix = parseInt32(buffer, offset);
    return {
        name: name,
        deadTime: deadTime,
        pulseTime: pulseTime,
        ku1: ku1,
        ku2: ku2,
        userKu: userKu,
        correctKu: correctKu,
        mmTvg: mmTvg,
        dbTvg: dbTvg,
        porog: porog,
        gate: gate,
        v: v,
        kv: kV,
        delay: delay,
        cicl: cicl,
        fix: fix
    };
}

export function getAwpUtSeriesTitle(t: TFunction<"translation">, series: AwpUtSeries){
    return t('scale_format', {
        scale: formatAwpUtScaleName(t, series.measure.scale)
    });
}

export function prepareAwpUtAreaChartData(series: AwpUtSeries) {
    let i = 0;
    const data = new Array<AreaChartData>();
    const scale = series.measure.scale;
    for (let j = 0; j < 510; j++) {
        const value = series.measure.bScan[j];
        if (value !== 0) {
            data.push({
                x: i++,
                y: [0, convertAwpUtValue(scale, value)]
            });
        } else {
            break;
        }
    }
    return data;
}

export function getAwpUtMeasurementError(scale: number){
    switch (scale){
        case 0:
            return 0.05;
        case 1:
            return 0.001;
        default:
            return 0.05;
    }
}
