import {parseFloat, parseString, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";
import {LineChartData} from "../components/MeasurementDetails/MeasurementsLineChart";
import {formatAwpTudKrcMaterialName, formatAwpTudKrcScaleName} from "../helpers/AwpTudKrcFormatHelper";
import {TFunction} from "react-i18next";

const SERIES_SIZE = 24632;

const MAX_MEASURE = 100;
const SAMPLES = 210;

const MEASURE_STRUCTURE_SIZE = 22041;
const SYS_STRUCTURE_SIZE = 651 - 80;
const LIST_BOX_STRUCTURE_SIZE = 40;

const LIST_BOX_DATA_OFFSET = 16;

const SYSTEM_STRUCTURE_OFFSET = MEASURE_STRUCTURE_SIZE;
const SCALE_OFFSET = SYSTEM_STRUCTURE_OFFSET + SYS_STRUCTURE_SIZE + LIST_BOX_DATA_OFFSET;
const MATERIAL_OFFSET = SYSTEM_STRUCTURE_OFFSET + SYS_STRUCTURE_SIZE + 2 * LIST_BOX_STRUCTURE_SIZE + LIST_BOX_DATA_OFFSET;
const PARAMS_STRUCTURE_OFFSET = SYSTEM_STRUCTURE_OFFSET + SYS_STRUCTURE_SIZE + 3 * LIST_BOX_STRUCTURE_SIZE;

export interface AwpKrcSeries {
    measurement : KrcMeasurement;
    systemSet : KrcSys;
    probeParam: KrcParam;
    material: number;
    scale: number;
}

export function parseKrcSeries(buffer: ArrayBuffer) : AwpKrcSeries | null{
    if (buffer.byteLength !== SERIES_SIZE){
        return null;
    }
    return {
        measurement: parseMeasurement(buffer, 0),
        systemSet: parseSys(buffer, SYSTEM_STRUCTURE_OFFSET),
        material: parseUint8(buffer, MATERIAL_OFFSET),
        scale: parseUint8(buffer, SCALE_OFFSET),
        probeParam: parseParams(buffer, PARAMS_STRUCTURE_OFFSET)
    }
}

interface KrcMeasurement{
    code : Array<number>;
    real : Array<number>;
    adc : Array<number>;
    n : number;
    r: number;
    avg: number;
    s: number;
    max : number;
    min : number;
    nMax: number;
    nMin : number;
    x: number;
    y: number;
    validate: Array<number>;
    angle: Array<number>;
    currentAngle: number;
}

interface KrcSys{
    serial: string;
}

interface KrcParam{
    name: string;
    serial: string;
    typeProbe: number;
}

function parseMeasurement(buffer : ArrayBuffer, baseOffset : number) : KrcMeasurement{
    let offset = baseOffset;
    const code = new Uint32Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE * 4;
    const real = new Float32Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE * 4;
    const adc = new Uint8Array(buffer, offset, MAX_MEASURE * SAMPLES);
    offset += (MAX_MEASURE * SAMPLES);
    const n = parseUint32(buffer, offset);
    offset += 4;
    const r = parseFloat(buffer, offset);
    offset += 4;
    const avg = parseFloat(buffer, offset);
    offset += 4;
    const s = parseFloat(buffer, offset);
    offset += 4;
    const max = parseFloat(buffer, offset);
    offset += 4;
    const min = parseFloat(buffer, offset);
    offset += 4;
    const nMax = parseUint32(buffer, offset);
    offset += 4;
    const nMin = parseUint32(buffer, offset);
    offset += 4;
    const x = parseUint32(buffer, offset);
    offset += 4;
    const y = parseUint32(buffer, offset);
    offset += 4;
    const validate = new Uint8Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE;
    const angle = new Uint8Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE;
    const currentAngle = parseUint8(buffer, offset);
    return {
        code: Array.from(code),
        real: Array.from(real),
        adc: Array.from(adc),
        n: n,
        r: r,
        avg: avg,
        s: s,
        max: max,
        min: min,
        nMax: nMax,
        nMin: nMin,
        validate: Array.from(validate),
        x: x,
        y: y,
        angle: Array.from(angle),
        currentAngle: currentAngle,
    }
}

function parseSys(buffer : ArrayBuffer, baseOffset : number) : KrcSys{
    let offset = baseOffset;
    const serial = parseString(buffer, offset, 11);
    return {
        serial: serial
    }
}

function parseParams(buffer : ArrayBuffer, baseOffset: number) : KrcParam{
    let offset = baseOffset;
    const name = parseString(buffer, offset, 11);
    offset += 11;
    const serial = parseString(buffer, offset, 11);
    offset += 11;
    const typeProbe = parseUint8(buffer, offset);
    return {
        name: name,
        serial: serial,
        typeProbe: typeProbe
    };
}

export function prepareAwpKrcLineChartData(series: AwpKrcSeries){
    const data = new Array<LineChartData>();
    for (let i = 0; i < series.measurement.n; i++) {
        data.push({
            x: i + 1,
            y: series.measurement.real[i]
        });
    }
    return data;
}

export function prepareAwpKrcBarChartData(series : AwpKrcSeries){
    const data = new Array<number>();
    for (let i = 0; i < series.measurement.n; i++) {
        data.push(series.measurement.real[i]);
    }
    return data;
}

export function prepareAwpKrcSeriesInfoData(series: AwpKrcSeries){
    const count = series.measurement.n;
    let max = -Number.MAX_VALUE;
    let min = Number.MAX_VALUE;
    let sum = 0;
    for (let i = 0; i < count; i++) {
        const value = series.measurement.real[i];
        max = Math.max(value, max);
        min = Math.min(value, min);
        sum += value;
    }
    const deviation = max - min;
    const avg = sum / count;
    const standardDeviation = series.measurement.s;
    sum = 0;
    for (let i = 0; i < count; i++) {
        const value = series.measurement.real[i];
        sum += Math.pow(value - avg, 2);
    }
    const meanSquareDeviation = Math.pow(sum / count, 0.5);
    const variationCoefficient = meanSquareDeviation / avg * 100;
    return {
        count : count,
        max: max,
        min: min,
        deviation: deviation,
        avg: avg,
        standardDeviation: standardDeviation,
        meanSquareDeviation: meanSquareDeviation,
        variationCoefficient: variationCoefficient
    };
}

export function prepareAwpKrcMarkers(series: AwpKrcSeries[]){
    return series.map((s, i) => {
        return {
            index: i,
            x: s.measurement.y,
            y: s.measurement.x
        };
    })
}

export function getAwpKrcSeriesTitle(t: TFunction<"translation">, series: AwpKrcSeries){
    return t('scale_material_format', {
        scale: formatAwpTudKrcScaleName(t, series.scale, series.probeParam.typeProbe),
        material: formatAwpTudKrcMaterialName(t, series.material, series.probeParam.typeProbe)
    });
}

