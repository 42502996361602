import {TFunction} from "react-i18next";
import {responsivePropType} from "react-bootstrap/createUtilityClasses";
import {AwpUd3701Series} from "../models/AwpUd3701Series";
import {formatNumber} from "./FormatHelper";
import {DATA_PLACEHOLDER} from "../AppSettings";

export interface Ud3701DeviceInfo {
    gain: string;
    delayMm: string;
    delayUs: string;
    scanMm: string;
    scanUs: string;
    detector: string;
    middle: string;
    filter: string;
    tvg: string;
}

export interface Ud3701Result {
    tA: string;
    tB: string;
    tAB: string;
    aA: string;
    aB: string;
    aAB: string;
    xbA: string;
    xbB: string;
    xipA: string;
    xipB: string;
    yA: string;
    yB: string;
    yAB: string;
    sA: string;
    sB: string;
    lA: string;
    lB: string;
    lAB: string;
    daA: string;
    daB: string;
    daAB: string;
}

export interface Ud3701StrobeInfo {
    startMm1 : string;
    startMm2 : string;
    startUs1 : string;
    startUs2 : string;
    widthMm1 : string;
    widthMm2 : string;
    widthUs1 : string;
    widthUs2 : string;
    level1 : string;
    level2 : string;
    control1 : string;
    control2 : string;
    search1 : string;
    search2 : string;
    mode1 : string;
    mode2 : string;
}

export interface Ud3701TransducerInfo {
    freq: string;
    angle: string;
    delay: string;
    vector: string;
    diamSyzeA: string;
}

export function getUd3701DeviceInfo(t: TFunction<"translation">, data: AwpUd3701Series): Ud3701DeviceInfo {
    const k = data.adc.speed / 200;
    return {
        gain: formatNumber(data.adc.gain / 10, 1, 1),
        delayMm: formatNumber(data.adc.mmDelay / 10, 1, 1),
        delayUs: formatNumber(data.adc.mmDelay / k, 1, 1),
        scanMm: formatNumber(data.adc.mmScan / 10, 1, 1),
        scanUs: formatNumber(data.adc.mmScan / k, 1, 1),
        detector: formatAwpUd3701DetectorMode(t, data.adc.mode),
        middle: data.screen.midle !== 0 ? formatNumber(Math.pow(2, data.screen.midle), 0, 0) : t('awp_off'),
        filter: data.adc.filterOnOff === 0 ? t("awp_off") : formatNumber(data.adc.filterFreq, 2),
        tvg: data.adc.tvgOnOff === 0 ? t("awp_off") : t("awp_on")
    }
}

export function getUd3701Result(t: TFunction<"translation">, data: AwpUd3701Series): Ud3701Result {
    const tA = data.asd.t[0] / 50;
    const tB = data.asd.t[1] / 50;
    const tAB = Math.abs((data.asd.t[0] - data.asd.t[1]) / 50);
    const aA = data.asd.adB[0];
    const aB = data.asd.adB[1];
    const aAB = data.asd.adB[0] - data.asd.adB[1];
    const xbA = data.asd.x[0];
    const xbB = data.asd.x[1];
    const xipA = data.asd.xip[0];
    const xipB = data.asd.xip[1];
    const yA = data.asd.y[0];
    const yB = data.asd.y[1];
    const yAB = data.asd.yab;
    const sA = data.asd.s[0];
    const sB = data.asd.s[1];
    const lA = (((data.asd.t[0] - data.probe.delay) / 50) * data.adc.speed) / 2000;
    const lB = (((data.asd.t[1] - data.probe.delay) / 50) * data.adc.speed) / 2000;
    const lAB = lA - lB;
    const daA = data.asd.dAdB[0];
    const daB = data.asd.dAdB[1];
    const daAB = daA - daB;
    return {
        tA: data.asd.flag[0] !== 0 ? formatNumber(tA, 2, 2) : DATA_PLACEHOLDER,
        tB: data.asd.flag[1] !== 0 ? formatNumber(tB, 2, 2) : DATA_PLACEHOLDER,
        tAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(tAB, 2, 2) : DATA_PLACEHOLDER,
        aA: data.asd.flag[0] !== 0 ? formatNumber(aA, 1, 1) : DATA_PLACEHOLDER,
        aB: data.asd.flag[1] !== 0 ? formatNumber(aB, 1, 1) : DATA_PLACEHOLDER,
        aAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(aAB, 2, 2) : DATA_PLACEHOLDER,
        xbA: data.asd.flag[0] !== 0 ? formatNumber(xbA, 1, 1) : DATA_PLACEHOLDER,
        xbB: data.asd.flag[1] !== 0 ? formatNumber(xbB, 1, 1) : DATA_PLACEHOLDER,
        xipA: data.asd.flag[0] !== 0 ? formatNumber(xipA, 1, 1) : DATA_PLACEHOLDER,
        xipB: data.asd.flag[1] !== 0 ? formatNumber(xipB, 1, 1) : DATA_PLACEHOLDER,
        yA: data.asd.flag[0] !== 0 ? formatNumber(yA, 1, 1) : DATA_PLACEHOLDER,
        yB: data.asd.flag[1] !== 0 ? formatNumber(yB, 1, 1) : DATA_PLACEHOLDER,
        yAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(yAB, 2, 2) : DATA_PLACEHOLDER,
        sA: data.asd.flag[0] !== 0 ? formatNumber(sA, 1, 1) : DATA_PLACEHOLDER,
        sB: data.asd.flag[1] !== 0 ? formatNumber(sB, 1, 1) : DATA_PLACEHOLDER,
        lA: data.asd.flag[0] !== 0 ? formatNumber(lA, 1, 1) : DATA_PLACEHOLDER,
        lB: data.asd.flag[1] !== 0 ? formatNumber(lB, 1, 1) : DATA_PLACEHOLDER,
        lAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(lAB, 2, 2) : DATA_PLACEHOLDER,
        daA: data.asd.flag[0] !== 0 ? formatNumber(daA, 1, 1) : DATA_PLACEHOLDER,
        daB: data.asd.flag[1] !== 0 ? formatNumber(daB, 1, 1) : DATA_PLACEHOLDER,
        daAB: data.asd.flag[0] !== 0 && data.asd.flag[1] !== 0 ? formatNumber(daAB, 2, 2) : DATA_PLACEHOLDER
    }
}

export function getUd3701StrobeInfo(t: TFunction<"translation">, data: AwpUd3701Series): Ud3701StrobeInfo {
    const k = data.adc.speed / 200;
    return {
        startMm1 : formatNumber(data.strobe.x[0] / 10, 1, 1),
        startMm2 : formatNumber(data.strobe.x[1] / 10, 1, 1),
        startUs1 : formatNumber(data.strobe.x[0] / k, 1, 1),
        startUs2 : formatNumber(data.strobe.x[1] / k, 1, 1),
        widthMm1 : formatNumber(data.strobe.len[0] / 10, 1, 1),
        widthMm2 : formatNumber(data.strobe.len[1] / 10, 1, 1),
        widthUs1 : formatNumber(data.strobe.len[0] / k, 1, 1),
        widthUs2 : formatNumber(data.strobe.len[1] / k, 1, 1),
        level1 : formatNumber(data.strobe.y[0], 1, 1),
        level2 : formatNumber(data.strobe.y[1], 1, 1),
        control1 : formatNumber(data.strobe.yContr[0] / 10, 1, 1),
        control2 : formatNumber(data.strobe.yContr[1] / 10, 1, 1),
        search1 : formatNumber(data.strobe.ySearch[0] / 10, 1, 1),
        search2 : formatNumber(data.strobe.ySearch[1] / 10, 1, 1),
        mode1 : data.strobe.frontPic[0] === 0 ? t('awp_flank') : t('awp_peak'),
        mode2 : data.strobe.frontPic[1] === 0 ? t('awp_flank') : t('awp_peak')
    }
}

export function getUd3701TransducerInfo(t: TFunction<"translation">, data: AwpUd3701Series): Ud3701TransducerInfo {
    return {
        freq: formatNumber(data.probe.freq / 10, 1, 1),
        angle: formatNumber(data.probe.angle / 10, 1, 1),
        delay: formatNumber(data.probe.delay / 50, 1, 1),
        vector: formatNumber(data.probe.vector / 10, 1, 1),
        diamSyzeA: formatNumber(data.probe.diamSyzeA / 10, 1, 1)
    }
}

export function formatAwpUd3701DetectorMode(t: TFunction<"translation">, type: number) {
    switch (type) {
        case 0:
            return t("awp_radio");
        case 1:
            return t("awp_module_1");
        case 2:
            return t("awp_module_2");
        default:
            return "";
    }
}