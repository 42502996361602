import {jsPDF} from "jspdf";
import {
    drawAwpDeviceAndProbeInfo,
    drawAwpScreen,
    drawAwpStrobeInfo,
    Drawer,
    drawFooter,
    drawHeader,
    drawMeasurementsWithoutPhoto,
    drawPhotoWithMeasurements,
    FONT_FAMILY,
    prepareInfoPages
} from "./AwpPdfPageDrawer";
import {formatDateTime} from "../helpers/FormatHelper";
import {TFunction} from "react-i18next";
import {ImageData} from "./ImageData";
import {roboto} from "./RobotoFont";
import {robotoBold} from "./RobotoBoldFont";
import {AwpTudSeries} from "../models/AwpTudSeries";
import {DATA_PLACEHOLDER} from "../AppSettings";
import {formatAwpTudKrcProbeType} from "../helpers/AwpTudKrcFormatHelper";
import {AwpTpSeries} from "../models/AwpTpSeries";
import {formatAwpTpProbeType} from "../helpers/AwpTpFormatHelper";
import {
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA,
    AwpSeries
} from "../helpers/AwpHelper";
import {AwpUtSeries} from "../models/AwpUtSeries";
import {AwpMfSeries} from "../models/AwpMfSeries";
import {AwpUd2301Series} from "../models/AwpUd2301Series";
import {AwpUd2303Series} from "../models/AwpUd2303Series";
import {AwpUd3701Series} from "../models/AwpUd3701Series";
import {AwpUt3EmaSeries} from "../models/AwpUt3EmaSeries";
import {formatAwpDeviceDisplayName} from "../helpers/AwpDeviceDisplayNameHelper";
import {AwpUt2aSeries} from "../models/AwpUt2aSeries";
import {AwpIpsmSeries} from "../models/AwpIpsmSeries";

export function createAwpTudKrcRecordPdf(t: TFunction<"translation">,
                                         locale: string,
                                         logo: ImageData,
                                         deviceType: number,
                                         name: string,
                                         operator: string | undefined,
                                         object: string | undefined,
                                         conclusion: string | undefined,
                                         deviceId: string | undefined,
                                         probe: number | undefined,
                                         probeId: string | undefined,
                                         dateTime: Date | undefined,
                                         measurementBitmap: ImageData | undefined,
                                         measurements: Array<AwpTudSeries> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const probeInfo = `${t('probe')}: ${formatAwpTudKrcProbeType(t, probe)}, ${t('probe_id')}: ${probeId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, probeInfo, dateTime, measurementBitmap, measurements, null);
}

export function createAwpTpRecordPdf(t: TFunction<"translation">,
                                     locale: string,
                                     logo: ImageData,
                                     deviceType: number,
                                     name: string,
                                     operator: string | undefined,
                                     object: string | undefined,
                                     conclusion: string | undefined,
                                     deviceId: string | undefined,
                                     probeName: string | undefined,
                                     probeId: string | undefined,
                                     dateTime: Date | undefined,
                                     measurementBitmap: ImageData | undefined,
                                     measurements: Array<AwpTpSeries> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const probeInfo = `${t('probe')}: ${formatAwpTpProbeType(t, probeId, probeName)}, ${t('probe_id')}: ${probeId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, probeInfo, dateTime, measurementBitmap, measurements, null);
}

export function createAwpUtRecordPdf(t: TFunction<"translation">,
                                     locale: string,
                                     logo: ImageData,
                                     deviceType: number,
                                     name: string,
                                     operator: string | undefined,
                                     object: string | undefined,
                                     conclusion: string | undefined,
                                     deviceId: string | undefined,
                                     probe: number | undefined,
                                     probeName: string | undefined,
                                     dateTime: Date | undefined,
                                     measurementBitmap: ImageData | undefined,
                                     measurements: Array<AwpUtSeries> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const probeInfo = `${t('probe')}: ${probeName ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, probeInfo, dateTime, measurementBitmap, measurements, null);
}

export function createAwpMfRecordPdf(t: TFunction<"translation">,
                                     locale: string,
                                     logo: ImageData,
                                     deviceType: number,
                                     name: string,
                                     operator: string | undefined,
                                     object: string | undefined,
                                     conclusion: string | undefined,
                                     deviceId: string | undefined,
                                     probeName: string | undefined,
                                     probe: number | undefined,
                                     probeId: string | undefined,
                                     dateTime: Date | undefined,
                                     measurementBitmap: ImageData | undefined,
                                     measurements: Array<AwpMfSeries> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const probeInfo = `${probeName !== undefined && probeName !== "" ? (`${t('probe')}: ${probeName}, `) : ""}${t('probe_id')}: ${probeId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, probeInfo, dateTime, measurementBitmap, measurements, null);
}

export function createAwpUd2301RecordPdf(t: TFunction<"translation">,
                                     locale: string,
                                     logo: ImageData,
                                     deviceType: number,
                                     name: string,
                                     operator: string | undefined,
                                     object: string | undefined,
                                     conclusion: string | undefined,
                                     deviceId: string | undefined,
                                     dateTime: Date | undefined,
                                     measurementBitmap: ImageData | undefined,
                                     measurements: Array<AwpUd2301Series> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, "", dateTime, measurementBitmap, measurements, null);
}

export function createAwpUd2303RecordPdf(t: TFunction<"translation">,
                                         locale: string,
                                         logo: ImageData,
                                         deviceType: number,
                                         name: string,
                                         operator: string | undefined,
                                         object: string | undefined,
                                         conclusion: string | undefined,
                                         deviceId: string | undefined,
                                         dateTime: Date | undefined,
                                         measurementBitmap: ImageData | undefined,
                                         measurements: Array<AwpUd2303Series> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, "", dateTime, measurementBitmap, measurements, null);
}

export function createAwpUd3701RecordPdf(t: TFunction<"translation">,
                                         locale: string,
                                         logo: ImageData,
                                         deviceType: number,
                                         name: string,
                                         operator: string | undefined,
                                         object: string | undefined,
                                         conclusion: string | undefined,
                                         deviceId: string | undefined,
                                         dateTime: Date | undefined,
                                         measurementBitmap: ImageData | undefined,
                                         measurements: Array<AwpUd3701Series> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, "", dateTime, measurementBitmap, measurements, null);
}

export function createAwpUt3EmaRecordPdf(t: TFunction<"translation">,
                                         locale: string,
                                         logo: ImageData,
                                         deviceType: number,
                                         name: string,
                                         operator: string | undefined,
                                         object: string | undefined,
                                         conclusion: string | undefined,
                                         deviceId: string | undefined,
                                         dateTime: Date | undefined,
                                         measurementBitmap: ImageData | undefined,
                                         measurements: Array<AwpUt3EmaSeries> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, "", dateTime, measurementBitmap, measurements, null);
}

export function createAwpUt2aRecordPdf(t: TFunction<"translation">,
                                         locale: string,
                                         logo: ImageData,
                                         deviceType: number,
                                         name: string,
                                         operator: string | undefined,
                                         object: string | undefined,
                                         conclusion: string | undefined,
                                         deviceId: string | undefined,
                                         dateTime: Date | undefined,
                                         measurementBitmap: ImageData | undefined,
                                         measurements: Array<AwpUt2aSeries> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType,deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, "", dateTime, measurementBitmap, measurements, null);
}

export function createAwpIpsmRecordPdf(t: TFunction<"translation">,
                                       locale: string,
                                       logo: ImageData,
                                       deviceType: number,
                                       name: string,
                                       operator: string | undefined,
                                       object: string | undefined,
                                       conclusion: string | undefined,
                                       deviceId: string | undefined,
                                       dateTime: Date | undefined,
                                       measurementBitmap: ImageData | undefined,
                                       measurements: Array<AwpIpsmSeries> | undefined): Promise<jsPDF> {
    const deviceName = `${t('device')}: ${formatAwpDeviceDisplayName(t, deviceType, deviceId ?? "")}`;
    const deviceInfo = `${t('device_id')}: ${deviceId ?? DATA_PLACEHOLDER}`;
    const operatorInfo = `${t('operator')}: ${operator ?? DATA_PLACEHOLDER}`;
    const objectInfo = `${t('object')}: ${object ?? DATA_PLACEHOLDER}`;
    return create(t, locale, logo, deviceType, name, operatorInfo, objectInfo, conclusion, deviceName, deviceInfo, "", dateTime, measurementBitmap, measurements, null);
}

function create(t: TFunction<"translation">,
                locale: string,
                logo: ImageData,
                deviceType: number,
                recordName: string,
                operator: string | undefined,
                object: string | undefined,
                conclusion: string | undefined,
                deviceName: string,
                deviceInfo: string,
                probeInfo: string,
                dateTime: Date | undefined,
                measurementBitmap: ImageData | undefined,
                measurements: Array<AwpSeries> | undefined,
                options: any): Promise<jsPDF> {
    return new Promise((resolve) => {
        const recordDateTime = dateTime ? formatDateTime(locale, dateTime) : undefined;
        const printDateTime = formatDateTime(locale, new Date());
        const pdfDocument = new jsPDF("p", "mm", "a4", true);
        pdfDocument.addFileToVFS("Roboto.ttf", roboto);
        pdfDocument.addFont('Roboto.ttf', FONT_FAMILY, 'normal');
        pdfDocument.addFileToVFS("RobotoBold.ttf", robotoBold);
        pdfDocument.addFont('RobotoBold.ttf', FONT_FAMILY, 'bold');
        let measurementIndex: number | undefined = undefined;
        let numberOfPages = 0;
        let pageIndex = 0;
        let infoPages: Array<Array<Drawer>> | undefined;
        while (true) {
            if (pageIndex > 0) {
                pdfDocument.addPage("a4", "p");
            }
            pageIndex++;
            const contentBounds = drawHeader(t, pdfDocument, logo, recordName, deviceName, deviceInfo, probeInfo, operator, object, recordDateTime);
            if (!infoPages) {
                infoPages = prepareInfoPages(t, pdfDocument, contentBounds, conclusion);
                numberOfPages = (infoPages?.length ?? 0) + (measurementBitmap ? 1 : 0) + (measurements?.length ?? 0) * ((deviceType === AWP_DEVICE_UD2301 || deviceType === AWP_DEVICE_UD2303 || deviceType === AWP_DEVICE_UD3701) ? 2 : 1);
            }
            const pageNumberInfo = t("page_count_format", {page: pageIndex, pages: numberOfPages});
            drawFooter(t, pdfDocument, printDateTime, pageNumberInfo);
            if (measurements) {
                if (measurementIndex === undefined) {
                    if (deviceType === AWP_DEVICE_UD2301 || deviceType === AWP_DEVICE_UD2303 || deviceType === AWP_DEVICE_UD3701 || deviceType === AWP_DEVICE_UT3EMA || deviceType === AWP_DEVICE_UT2A){
                        measurementIndex = -1;
                        drawAwpDeviceAndProbeInfo(t, pdfDocument, contentBounds, deviceType, measurements);
                        continue;
                    } else {
                        measurementIndex = 0;
                        if (measurementBitmap) {
                            drawPhotoWithMeasurements(t, pdfDocument, contentBounds, measurementBitmap);
                            continue;
                        }
                    }
                }
                if (measurementIndex === -1){
                    measurementIndex = (deviceType === AWP_DEVICE_UT3EMA || deviceType === AWP_DEVICE_UT2A) ? measurements?.length ?? 0 : 0;
                    if (measurementBitmap){
                        const title = (deviceType === AWP_DEVICE_UT3EMA || deviceType === AWP_DEVICE_UT2A) ? t('awp_measurement_results') : t('awp_strobes_and_measurement_results');
                        drawAwpScreen(t, pdfDocument, contentBounds, title, measurementBitmap);
                        if (deviceType === AWP_DEVICE_UT3EMA || deviceType === AWP_DEVICE_UT2A){
                            break;
                        } else {
                            continue;
                        }
                    }
                }
                if (measurementIndex < measurements.length) {
                    if (deviceType === AWP_DEVICE_UD2301 || deviceType === AWP_DEVICE_UD2303 || deviceType === AWP_DEVICE_UD3701){
                        drawAwpStrobeInfo(t, pdfDocument, contentBounds, deviceType, measurements);
                    } else {
                        drawMeasurementsWithoutPhoto(t, pdfDocument, contentBounds, deviceType, measurements.length, measurementIndex, measurements[measurementIndex], options);
                    }
                    measurementIndex++;
                    if (measurementIndex < measurements.length || infoPages.length > 0) {
                        continue;
                    } else {
                        break;
                    }
                }
            }
            if (infoPages.length > 0) {
                const drawers = infoPages.shift();
                for (const drawer of drawers!) {
                    drawer(pdfDocument);
                }
                if (infoPages.length > 0) {
                    continue;
                }
            }
            break;
        }
        resolve(pdfDocument);
    });
}