import packageInfo from '../package.json';

export const APP_VERSION = `v.${packageInfo.version}`;

export const MAIN_APP_URL = "https://novotest.info";
export const DEMO_APP_URL = "https://demo.novotest.info";

export const CLIENT_ID = "533153982158-h0vlphimq6g5gmnmbg1jcl8569qmljs0.apps.googleusercontent.com";
export const CLIENT_SECRET = "zwVJBTnFr_2mdQSyCOzmEAXV";

export const CLIENT_SCOPE = "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive";
export const API_KEY = "AIzaSyDo8cA753YKimAkqKaLze5Lc0Sw6JNC5WY";

export const IS_DEMO_APP = true;
export const IS_LOCAL_APP = false;

const AUTH_DOMAIN = "novotest-app.firebaseapp.com";
const PROJECT_ID = "novotest-app";

const DATABASE_URL = "https://novotest-app-default-rtdb.europe-west1.firebasedatabase.app";
const STORAGE_BUCKET = "novotest-app.appspot.com";
const MESSAGING_SENDER_ID = "533153982158";
const APP_ID = "1:533153982158:web:877c1a41059d7e663bd29f";
const MEASUREMENT_ID = "G-L1FK39FF8W";
const DEMO_APP_ID = "1:533153982158:web:66b7134f35e2c2a83bd29f";
const DEMO_MEASUREMENT_ID = "G-KF1JHEWCYY";
export const ANALYTICS_ID = "G-C2TQ8LDRGN";
export const DEMO_ANALYTICS_ID = "G-EPDR166BTB";

export const FIREBASE_CONFIG = {
    apiKey: API_KEY,
    authDomain: AUTH_DOMAIN,
    projectId: PROJECT_ID,
    databaseURL: DATABASE_URL,
    storageBucket: STORAGE_BUCKET,
    messagingSenderId: MESSAGING_SENDER_ID,
    appId: IS_DEMO_APP ? DEMO_APP_ID : APP_ID,
    measurementId: IS_DEMO_APP ? DEMO_MEASUREMENT_ID : MEASUREMENT_ID
};

export const EXECUTORS_REFERENCE = "people";

export const TOAST_DELAY = 2000;

export const DATA_PLACEHOLDER = "-----";

export const MIN_SCREEN_WIDTH_TO_SHOW_RECORD_PREVIEW = 640;
