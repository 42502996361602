import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import './AppBar.css';
import logoMain from './logo_main.png';
import {Accordion, AccordionContext, Button, Dropdown, SplitButton, useAccordionButton} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import {Languages} from "../../locale/Languages";
import {useTranslation} from "react-i18next";
import {useLocale} from "../../hooks/LocaleHook";
import {useIsFullAppBar} from "../../hooks/ScreenSizeHooks";
import IconMenuSvg from "../Icons/IconMenuSvg";
import {useContext, useEffect} from "react";
import {AWP_LIST_ROUTE, EXECUTORS_ROUTE, HOME_ROUTE, Sections} from "../../routes";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import {buildAwpOptions} from "../../helpers/AwpOptions";
import {useAwpRouteSection} from "../../hooks/RouteHooks";
import {MAIN_APP_URL} from "../../AppSettings";

function AppBarToggleButton(props: { eventKey: string }) {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === props.eventKey;
    const accordionHandler = useAccordionButton(props.eventKey, () => {
    });
    return (
        <Button id="accordion-toggle" variant={isCurrentEventKey ? "primary" : "outline-primary"}
                className="me-4 app-bar-menu-button"
                onClick={accordionHandler}>
            <IconMenuSvg/>
        </Button>
    );
}

export function AppBarDemo() {
    const accordionKey = "0";
    const history = useHistory();
    const isNormalScreen = useIsFullAppBar();
    const executorsListRouteMatch = useRouteMatch(EXECUTORS_ROUTE);
    let section = Sections.ARCHIVE;
    if (executorsListRouteMatch?.isExact) {
        section = Sections.FIND_EXECUTOR;
    }
    const awpRouteSection = useAwpRouteSection();
    if (awpRouteSection !== undefined) {
        section = Sections.AWP;
    }
    const {t, i18n} = useTranslation();
    const locale = useLocale();
    const closeAppBar = () => {
        if (document.querySelector(".accordion-collapse")?.classList?.contains("show")) {
            document.getElementById("accordion-toggle")?.click();
        }
    }
    useEffect(() => {
        document.addEventListener("click", closeAppBar);
        return () => {
            document.removeEventListener("click", closeAppBar);
        }
    }, []);
    const goToExecutorsSearch = () => {
        history.push(EXECUTORS_ROUTE);
    };
    const goToAwpList = () => {
        history.push(AWP_LIST_ROUTE);
    }
    const goToArchive = () => {
        history.push(HOME_ROUTE);
    };
    const goToMainArchive = () => {
        window.open(MAIN_APP_URL, "_blank");
    };
    if (isNormalScreen) {
        return (
            <div className={"app-bar no-select"}>
                <div className={"app-bar-container"}>
                    <div className="d-flex align-items-center">
                        <Link to={"/"}><img src={logoMain} alt="NOVOTEST"/></Link>
                        <span className={`app-bar-link ${section === Sections.FIND_EXECUTOR ? "active" : ""}`}
                              onClick={goToExecutorsSearch}>{t("search_for_executor")}</span>
                        <span className={`app-bar-link accent`} onClick={goToMainArchive}>{t("cloud_archive")}</span>
                        <span className={`app-bar-link ${section === Sections.ARCHIVE ? "active" : ""}`}
                              onClick={goToArchive}>{t("demo_archive")}</span>
                        <Dropdown>
                            <DropdownToggle as="span"
                                            className={`app-bar-link ${section === Sections.AWP ? "active" : ""}`}>
                                <span onClick={(e) => {
                                    e.stopPropagation();
                                    goToAwpList();
                                }}>{t("demo_awp")}</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                {buildAwpOptions(t).map((item, i) => <DropdownItem key={`nav-awp-list-item-${i}`} className={`${(item.deviceType === awpRouteSection) ? "dropdown-item-active" : ""}`}
                                                                                   onClick={() => history.push(item.path)}>{item.title}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="mx-4 d-flex justify-content-end">
                        <SplitButton className="ms-4" id="language-dropdown-basic-button"
                                     title={Languages.find(l => l.code === locale)?.shortName ?? Languages[0].shortName}>
                            {Languages.map(l => <DropdownItem key={l.code}
                                                              onClick={() => i18n.changeLanguage(l.code)}>{l.fullName}</DropdownItem>)}
                        </SplitButton>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Accordion>
                <div className={"app-bar app-bar-small-screen no-select"}>
                    <div className="d-flex flex-row align-items-center">
                        <Link to={"/"} className="mx-2 flex-grow-1 d-flex justify-content-center">
                            <img src={logoMain} alt="NOVOTEST"/>
                        </Link>
                        <AppBarToggleButton eventKey={accordionKey}/>
                    </div>
                    <Accordion.Collapse eventKey={accordionKey}>
                        <div className="d-flex flex-column justify-content-around align-items-end mx-4 mt-1">
                            <HorizontalDivider/>
                            <span
                                className={`app-bar-link app-bar-small-screen align-self-stretch ${section === Sections.FIND_EXECUTOR ? "active" : ""}`}
                                onClick={goToExecutorsSearch}>{t("search_for_executor")}</span>
                            <HorizontalDivider/>
                            <span
                                className={`app-bar-link app-bar-small-screen align-self-stretch`}
                                onClick={goToMainArchive}>{t("cloud_archive")}</span>
                            <HorizontalDivider/>
                            <span
                                className={`app-bar-link app-bar-small-screen align-self-stretch ${section === Sections.ARCHIVE ? "active" : ""}`}
                                onClick={goToArchive}>{t("demo_archive")}</span>
                            <HorizontalDivider className={"mb-2"}/>
                            <Dropdown className={"align-self-stretch"}>
                                <div onClick={e => e.stopPropagation()}>
                                    <DropdownToggle as="span"
                                                    className={`app-bar-link app-bar-small-screen align-self-stretch ${section === Sections.AWP ? "active" : ""}`}>
                                        <span onClick={(e) => {
                                            e.stopPropagation();
                                            goToAwpList();
                                            closeAppBar();
                                        }}>{t("demo_awp")}</span>
                                    </DropdownToggle>
                                </div>
                                <DropdownMenu>
                                    {buildAwpOptions(t).map((item, i) => <DropdownItem
                                        key={`nav-awp-list-small-item-${i}`}
                                        className={`${(item.deviceType === awpRouteSection) ? "dropdown-item-active" : ""}`}
                                        onClick={() => history.push(item.path)}>{item.title}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>
                            <HorizontalDivider/>
                            <div onClick={event => event.stopPropagation()}>
                                <SplitButton className="ms-4 mb-2" id="language-dropdown-basic-button"
                                             title={Languages.find(l => l.code === locale)?.shortName ?? Languages[0].shortName}>
                                    {Languages.map(l => <DropdownItem key={l.code}
                                                                      onClick={() => i18n.changeLanguage(l.code)}>{l.fullName}</DropdownItem>)}
                                </SplitButton>
                            </div>
                        </div>
                    </Accordion.Collapse>
                </div>
            </Accordion>
        );
    }
}