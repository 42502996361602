import {parseFloat, parseFloatArray, parseString, parseUint32, parseUint8} from "../helpers/BinaryParseHelper";
import {LineChartData} from "../components/MeasurementDetails/MeasurementsLineChart";
import {formatAwpTudKrcMaterialName, formatAwpTudKrcScaleName} from "../helpers/AwpTudKrcFormatHelper";
import {TFunction} from "react-i18next";

const SERIES_SIZE = 25362;
const NEW_SERIES_SIZE = 25381;

const TUD2_v_5_0_SERIES_SIZE = 519;

const NEW_STRUCTURE_ADDITIONAL_SIZE = 19;

const MAX_MEASURE = 100;
const SAMPLES = 210;

const MEASURE_STRUCTURE_SIZE = 22041;
const SYS_STRUCTURE_SIZE = 651;

const SERIAL_NUMBER_SIZE = 11;
const NEW_SERIAL_NUMBER_SIZE = 16;

const LIST_BOX_STRUCTURE_SIZE = 40;

const LIST_BOX_DATA_OFFSET = 16;

const SYSTEM_STRUCTURE_OFFSET = MEASURE_STRUCTURE_SIZE;
const SCALE_OFFSET = SYSTEM_STRUCTURE_OFFSET + SYS_STRUCTURE_SIZE + LIST_BOX_DATA_OFFSET;
const MATERIAL_OFFSET = SYSTEM_STRUCTURE_OFFSET + SYS_STRUCTURE_SIZE + 2 * LIST_BOX_STRUCTURE_SIZE + LIST_BOX_DATA_OFFSET;
const PARAMS_STRUCTURE_OFFSET = SYSTEM_STRUCTURE_OFFSET + SYS_STRUCTURE_SIZE + 3 * LIST_BOX_STRUCTURE_SIZE;

export interface AwpTudSeries {
    measurement: TudMeasurement;
    systemSet: TudSys;
    probeParam: TudParam;
    material: number;
    scale: number;
}

export function parseTudSeries(buffer: ArrayBuffer): AwpTudSeries | null {
    if (buffer.byteLength === SERIES_SIZE) {
        return {
            measurement: parseMeasurement(buffer, 0),
            systemSet: parseSys(buffer, SYSTEM_STRUCTURE_OFFSET, SERIAL_NUMBER_SIZE),
            probeParam: parseParams(buffer, PARAMS_STRUCTURE_OFFSET),
            material: parseUint8(buffer, MATERIAL_OFFSET),
            scale: parseUint8(buffer, SCALE_OFFSET)
        }
    } else if (buffer.byteLength === NEW_SERIES_SIZE) {
        return {
            measurement: parseMeasurement(buffer, 0),
            systemSet: parseSys(buffer, SYSTEM_STRUCTURE_OFFSET, NEW_SERIAL_NUMBER_SIZE),
            probeParam: parseParams(buffer, PARAMS_STRUCTURE_OFFSET + NEW_STRUCTURE_ADDITIONAL_SIZE),
            material: parseUint8(buffer, MATERIAL_OFFSET + NEW_STRUCTURE_ADDITIONAL_SIZE),
            scale: parseUint8(buffer, SCALE_OFFSET + NEW_STRUCTURE_ADDITIONAL_SIZE)
        }
    } else if (buffer.byteLength === TUD2_v_5_0_SERIES_SIZE) {
        return {
            measurement: {
                code: [],
                real: parseFloatArray(buffer.slice(77), 0, MAX_MEASURE),
                adc: [],
                n: parseUint32(buffer.slice(485), 0),
                r: parseFloat(buffer.slice(489), 0),
                avg: parseFloat(buffer.slice(493), 0),
                s: parseFloat(buffer.slice(497), 0),
                max: parseFloat(buffer.slice(501), 0),
                min: parseFloat(buffer.slice(505), 0),
                nMax: parseUint32(buffer.slice(509), 0),
                nMin: parseUint32(buffer.slice(513), 0),
                x: 0,
                y: 0,
                validate: [],
                angle: [],
                currentAngle:parseUint8(buffer.slice(517), 0)
            },
            systemSet: {
                serial: parseString(buffer.slice(32), 0, 30)
            },
            probeParam: {
                name: parseString(buffer, 0, 21),
                serial: parseString(buffer.slice(21), 0, 11),
                typeProbe: parseUint8(buffer.slice(518), 0)
            },
            material: parseUint8(buffer.slice(481-19), 0),
            scale: parseUint8(buffer.slice(477-19), 0)
        }
    } else {
        return null;
    }
}

interface TudMeasurement {
    code: Array<number>;
    real: Array<number>;
    adc: Array<number>;
    n: number;
    r: number;
    avg: number;
    s: number;
    max: number;
    min: number;
    nMax: number;
    nMin: number;
    x: number;
    y: number;
    validate: Array<number>;
    angle: Array<number>;
    currentAngle: number;
}

interface TudSys {
    serial: string;
}

interface TudParam {
    name: string;
    serial: string;
    typeProbe: number;
}

function parseMeasurement(buffer: ArrayBuffer, baseOffset: number): TudMeasurement {
    let offset = baseOffset;
    const code = new Uint32Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE * 4;
    const real = new Float32Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE * 4;
    const adc = new Uint8Array(buffer, offset, MAX_MEASURE * SAMPLES);
    offset += (MAX_MEASURE * SAMPLES);
    const n = parseUint32(buffer, offset);
    offset += 4;
    const r = parseFloat(buffer, offset);
    offset += 4;
    const avg = parseFloat(buffer, offset);
    offset += 4;
    const s = parseFloat(buffer, offset);
    offset += 4;
    const max = parseFloat(buffer, offset);
    offset += 4;
    const min = parseFloat(buffer, offset);
    offset += 4;
    const nMax = parseUint32(buffer, offset);
    offset += 4;
    const nMin = parseUint32(buffer, offset);
    offset += 4;
    const x = parseUint32(buffer, offset);
    offset += 4;
    const y = parseUint32(buffer, offset);
    offset += 4;
    const validate = new Uint8Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE;
    const angle = new Uint8Array(buffer, offset, MAX_MEASURE);
    offset += MAX_MEASURE;
    const currentAngle = parseUint8(buffer, offset);
    return {
        code: Array.from(code),
        real: Array.from(real),
        adc: Array.from(adc),
        n: n,
        r: r,
        avg: avg,
        s: s,
        max: max,
        min: min,
        nMax: nMax,
        nMin: nMin,
        validate: Array.from(validate),
        x: x,
        y: y,
        angle: Array.from(angle),
        currentAngle: currentAngle,
    }
}

function parseSys(buffer: ArrayBuffer, baseOffset: number, serialSize: number): TudSys {
    let offset = baseOffset;
    const serial = parseString(buffer, offset, serialSize);
    return {
        serial: serial
    }
}

function parseParams(buffer: ArrayBuffer, baseOffset: number): TudParam {
    let offset = baseOffset;
    const name = parseString(buffer, offset, 11);
    offset += 11;
    const serial = parseString(buffer, offset, 11);
    offset += 11;
    const typeProbe = parseUint8(buffer, offset);
    return {
        name: name,
        serial: serial,
        typeProbe: typeProbe
    };
}

export function prepareAwpTudLineChartData(series: AwpTudSeries) {
    const data = new Array<LineChartData>();
    for (let i = 0; i < series.measurement.n; i++) {
        data.push({
            x: i + 1,
            y: series.measurement.real[i]
        });
    }
    return data;
}

export function prepareAwpTudBarChartData(series: AwpTudSeries) {
    const data = new Array<number>();
    for (let i = 0; i < series.measurement.n; i++) {
        data.push(series.measurement.real[i]);
    }
    return data;
}

export function prepareAwpTudSeriesInfoData(series: AwpTudSeries) {
    const count = series.measurement.n;
    let max = -Number.MAX_VALUE;
    let min = Number.MAX_VALUE;
    let sum = 0;
    for (let i = 0; i < count; i++) {
        const value = series.measurement.real[i];
        max = Math.max(value, max);
        min = Math.min(value, min);
        sum += value;
    }
    const deviation = max - min;
    const avg = sum / count;
    const standardDeviation = series.measurement.s;
    sum = 0;
    for (let i = 0; i < count; i++) {
        const value = series.measurement.real[i];
        sum += Math.pow(value - avg, 2);
    }
    const meanSquareDeviation = Math.pow(sum / count, 0.5);
    const variationCoefficient = meanSquareDeviation / avg * 100;
    return {
        count: count,
        max: max,
        min: min,
        deviation: deviation,
        avg: avg,
        standardDeviation: standardDeviation,
        meanSquareDeviation: meanSquareDeviation,
        variationCoefficient: variationCoefficient
    };
}

export function prepareAwpTudMarkers(series: AwpTudSeries[]) {
    return series.map((s, i) => {
        return {
            index: i,
            x: s.measurement.y,
            y: s.measurement.x
        };
    })
}

export function getAwpTudSeriesTitle(t: TFunction<"translation">, series: AwpTudSeries) {
    return t('scale_material_format', {
        scale: formatAwpTudKrcScaleName(t, series.scale, series.probeParam.typeProbe),
        material: formatAwpTudKrcMaterialName(t, series.material, series.probeParam.typeProbe)
    });
}

