import {useEffect, useLayoutEffect, useState} from "react";
import {IS_LOCAL_APP} from "../AppSettings";

const FULL_APP_BAR_MIN_WIDTH = IS_LOCAL_APP ? 640 : 1340;
const FULL_EXECUTORS_LAYOUT_MIN_WIDTH = 754;

export function useScreenSize() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const callback = () => {
            const w = window.innerWidth;
            const h = window.innerHeight;
            if (width !== w) {
                setWidth(w);
            }
            if (height !== h) {
                setHeight(h);
            }
        }
        window.addEventListener("resize", callback);
        return () => {
            window.removeEventListener("resize", callback);
        };
    });
    return [width, height];
}

export const useIsFullAppBar: () => boolean = () => {
    const [screenSize, setScreenSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize[0] > FULL_APP_BAR_MIN_WIDTH;
}

export const useIsFullExecutorsLayout: () => boolean = () => {
    const [screenSize, setScreenSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return screenSize[0] > FULL_EXECUTORS_LAYOUT_MIN_WIDTH;
}