import {SerialDevice} from "./SerialDevice";
import {delay} from "../helpers/Utils";
import {parseAwpFwHeadGsp} from "../models/AwpFwHeadGsp";
import {AwpDeviceInfo} from "../models/AwpDeviceInfo";
import {FW_KRC, FW_TUD2, FW_UD3701, FW_UNKNOWN} from "../models/AwpFwUpdateDevice";
import {getAwpFwDeviceType} from "../helpers/AwpFwFormatHelper";
import {logError, logger, logInfo} from "../helpers/LogHelper";
import {AwpBootloaderType} from "../models/AwpBootloaderType";
import numeral from "numeral";

const MAX_PACKET_LENGTH = 2400;
const SOH = 0x01;
const STX = 0x02;
const EOT = 0x04;
const ACK = 0x06;
const NAK = 0x15;
const CAN = 0x18;
const CRC = 0x43;

const PACKET_1K_SIZE = 1024;
const PACKET_SIZE = 128;

const PACKET_NVI_SIZE = 1024;
const HEAD_NVI_SIZE = 256;

interface DeviceInfo {
    id?: string;
    deviceTypeId?: number;
    hwVersion?: number;
    swVersion?: number;
    bootloader?: AwpBootloaderType
}

export function testDeviceInfoParsing(): DeviceInfo {
    const ut2a = "INFO\n" +
        "HW Ver.  4.12\n" +
        "\n" +
        "ID Code: 041.412.00018 (0086900754)\n" +
        "\n" +
        "FPGA Ver.  0.00\n" +
        "\n" +
        "SW Ver:3.050\n" +
        "\n" +
        "Total time:004:14:52\n" +
        "\n" +
        "Measurements time:003:10:26\n" +
        "\n";
    const tud3 = "INFO\n" +
        "\n" +
        "\n" +
        "ID Code:09.0108.0302.0423\n" +
        "\n" +
        "SW Ver:35.146\n" +
        "\n" +
        "Enabled  probes: UCI \n" +
        "Serial probe: Gё§©Rч¶xpТџ\n" +
        "\n" +
        "Total measurements: -14115576";
    const ud3701 = "INFO\n" +
        "\n" +
        "\n" +
        "ID Code:24.0005.0273.0323\n" +
        "\n" +
        "HW Ver.  2.73\n" +
        "\n" +
        "20.31\n" +
        "\n" +
        "Total time:004:14:08\n" +
        "\n" +
        "Measurements time:003:16:47";
    const ut1mst = "INFO\n" +
        "ID Code:45.0000.0502.0623\n" +
        "SW Ver:15.171\n" +
        "Work time:022:03:09\n" +
        "Measurements:000400"
    const ut3ema = "HW Ver.  2.03\n" +
        "ID Code: 34.0064.0203.0224\n" +
        "FPGA Ver.  0.00\n" +
        "SW Ver:5.191\n" +
        "Total time:002:02:35\n" +
        "Measurements time:001:14:55"
    const data = ut3ema;
    const result = parseSwDeviceInfo(data);
    console.log(result);
    return result;
}

// Trello fix
// https://trello.com/c/3Cw1HFLJ/813-update-06108-%D1%83%D0%B43701-%D0%BF%D1%80%D0%BE%D1%88-2000-%D0%B0%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BF%D1%80%D0%BE%D1%88%D0%B8%D0%B2%D0%BA%D0%B0-2031-%D0%BF%D0%BE%D1%81%D0%BB%D0%B5-%D0%BF%D0%BE%D0%B8%D1%81%D0%BA%D0%B0-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B9-%D0%BE%D1%82%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B0%D0%B5%D1%82-%D1%83%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%83-%D0%B2%D0%B0%D1%81-%D1%81%D1%82%D0%B0%D1%80%D0%B0%D1%8F-%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%8F-%D0%BF%D1%80%D0%B8%D0%B1%D0%BE%D1%80
function checkUd3701Hw273Sw200(text: string): DeviceInfo | undefined {
    if (text.includes("Serial device: яяяяяяяяяя") && text.includes("HW Ver. 2.XX") && text.includes("20.0")) {
        return {
            id: undefined,
            deviceTypeId: FW_UD3701,
            hwVersion: 2.73,
            swVersion: 20.0
        };
    }
    return undefined;
}

// Trello fix
// https://trello.com/c/CGd00dIy/811-update-06108-%D1%83%D0%B43701-%D0%BF%D1%80%D0%BE%D1%88-2031-%D0%B0%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BF%D1%80%D0%BE%D1%88%D0%B8%D0%B2%D0%BA%D0%B0-2031-%D0%BF%D0%BE%D1%81%D0%BB%D0%B5-%D0%BF%D0%BE%D0%B8%D1%81%D0%BA%D0%B0-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B9-%D0%BD%D0%B5-%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4%D0%B8%D1%82-%D1%83%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%82%D0%BE%D0%BC-%D1%87%D1%82%D0%BE-%D1%83-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0-%D1%83%D0%B6%D0%B5-%D1%83%D1%81
function checkUd3701Hw273Sw2031(text: string, id?: string, deviceTypeId?: number, hwVersion?: number, swVersion?: number): DeviceInfo | undefined {
    if (!swVersion) {
        if (deviceTypeId === FW_UD3701 && hwVersion === 2.73) {
            if (text.includes("20.31")) {
                return {
                    id: id,
                    deviceTypeId: deviceTypeId,
                    hwVersion: hwVersion,
                    swVersion: 20.31
                };
            }
        }
    }
    return undefined;
}

export function parseSwDeviceInfo(text: string): DeviceInfo {
    let id;
    let deviceTypeId = FW_UNKNOWN;
    let hwVersion;
    let swVersion;
    let bootloader : AwpBootloaderType | undefined = undefined;

    const idRegex = /.*ID[A-Za-z ]*:\D*((\d+)\.(\d+)\.\d+)[ \n\r]/gm;
    let idMatch = idRegex.exec(text);
    if (idMatch) {
        bootloader = "1.0"
    } else {
        const idRegexNew = /.*ID[A-Za-z ]*:\D*((\d+)\.\d+\.(\d+)\.\d+).*[ \n\r]/gm;
        idMatch = idRegexNew.exec(text);
        if (idMatch){
            bootloader = "1.1"
        }
    }
    const swRegex = /.*SW\s*Ver:\D*([\d.,]+)\D*[\n\r]/gm;
    const swMatch = swRegex.exec(text);
    const hwRegex = /.*HW\D*([\d.,]*)[\D\n\r]/gm;
    const hwMatch = hwRegex.exec(text);

    if (idMatch) {
        id = idMatch[1].trim();
        deviceTypeId = getAwpFwDeviceType(Number(idMatch[2].trim()));
        hwVersion = Number(idMatch[3].trim()) / 100;
    }
    if (hwMatch) {
        hwVersion = Number(hwMatch[1].trim());
    }
    if (swMatch) {
        swVersion = Number(swMatch[1].trim());
    }

    const ud3701Hw273Sw200 = checkUd3701Hw273Sw200(text);
    if (ud3701Hw273Sw200) {
        return ud3701Hw273Sw200;
    }
    const ud3701Hw273Sw2031 = checkUd3701Hw273Sw2031(text, id, deviceTypeId, hwVersion, swVersion);
    if (ud3701Hw273Sw2031) {
        return ud3701Hw273Sw2031;
    }

    return {
        id: id,
        deviceTypeId: deviceTypeId,
        hwVersion: hwVersion,
        swVersion: swVersion,
        bootloader: bootloader
    };
}

export class AwpFwSerialDevice {

    static readonly speed921600 = 921600;
    static readonly speedOldLoader = 230400;

    private readonly port: SerialDevice;

    private isListening = false;

    private hasDisconnectListener = false;

    private rxBuffer: string = "";

    constructor(port: SerialDevice) {
        this.port = port;
    }

    open(): Promise<boolean> {
        return this.port.open();
    }

    close() {
        try {
            this.port.close();
        } catch (e) {
            logError("Port close failed", e);
        }
    }

    listen() {
        this.rxBuffer = "";
        if (!this.isListening) {
            this.isListening = true;
            const decoder = new TextDecoder("windows-1251");
            this.port.listen(data => {
                const text = decoder.decode(data);
                logInfo(`Data received: [${text}] (${data.length} bytes)`);
                this.rxBuffer += decoder.decode(data);
            });
        }
    }

    requestDeviceData(): [cancelationToken: () => void, promise: Promise<[displayId?: string, deviceTypeId?: number, hw?: number, sw?: number, bootloader?: AwpBootloaderType] | null>] {
        this.listen();
        let interval: NodeJS.Timeout;
        const token = () => {
            if (interval) {
                clearInterval(interval);
            }
        };
        const promise = new Promise<[displayId?: string, deviceTypeId?: number, hw?: number, sw?: number, bootloader?: AwpBootloaderType] | null>(async (resolve) => {
                const encoder = new TextEncoder();
                const checkInterval = 100;
                let slowDevice = false;
                let timeoutAttempt = 0;
                let writeAttempt = 0;
                let elapsedTime = 0;
                let infoRequested = false;
                let getIdRequested = false;
                logInfo("Requesting device info");
                await this.port.setBaudRate(AwpFwSerialDevice.speed921600);
                interval = setInterval(() => {
                    elapsedTime += checkInterval;
                    if (elapsedTime >= 4000) {
                        if (timeoutAttempt < 5) {
                            timeoutAttempt++;
                            elapsedTime = 0;
                            infoRequested = false;
                            getIdRequested = false;
                        } else {
                            if (slowDevice) {
                                logInfo("Timeout");
                                clearInterval(interval);
                                if (this.rxBuffer === "") {
                                    resolve(null);
                                } else {
                                    resolve([undefined, undefined, undefined, undefined, undefined]);
                                }
                            } else {
                                logInfo("Trying with lower baud rate");
                                this.port.setBaudRate(AwpFwSerialDevice.speedOldLoader).then(() => {
                                    slowDevice = true;
                                    elapsedTime = 0;
                                    timeoutAttempt = 0;
                                    infoRequested = false;
                                    getIdRequested = false;
                                });
                            }
                        }
                    }
                    if (!infoRequested) {
                        infoRequested = true;
                        this.rxBuffer = "";
                        this.port.writeTextAsync(encoder, "INFO\r").then();
                        writeAttempt = 0;
                    }
                    if (!getIdRequested && elapsedTime >= 2000) {
                        getIdRequested = true;
                        this.rxBuffer = "";
                        this.port.writeTextAsync(encoder, "GETID\r").then();
                        writeAttempt = 0;
                    }
                    try {
                        const text = this.rxBuffer;
                        if (text.toLowerCase().includes("error command")) {
                            if (elapsedTime < 2000) {
                                if (writeAttempt < 3) {
                                    writeAttempt++;
                                    elapsedTime = 0;
                                    infoRequested = false;
                                } else {
                                    elapsedTime = 2000;
                                }
                            } else if (elapsedTime < 4000) {
                                if (writeAttempt < 3) {
                                    writeAttempt++;
                                    elapsedTime = 2000;
                                    getIdRequested = true;
                                } else {
                                    elapsedTime = 4000;
                                }
                            }
                        }
                        const data = parseSwDeviceInfo(text);
                        if (data.deviceTypeId !== FW_UNKNOWN && data.hwVersion) {
                            clearInterval(interval);
                            resolve([data.id, data.deviceTypeId, data.hwVersion, data.swVersion ?? 0, data.bootloader]);
                        }
                    } catch (e) {
                        this.rxBuffer = "";
                    }
                }, checkInterval);
            }
        );
        return [token, promise];
    }


    exploreDeviceData(deviceId: number, timeoutCallback: () => void): [cancellationToken: () => void, timeoutRestartToken: () => void, promise: Promise<AwpDeviceInfo>] {
        logInfo(`EXPLORING. DeviceID: ${deviceId}`);
        this.listen();
        let bufferCheckInterval: NodeJS.Timeout;
        let timeoutTimer: NodeJS.Timeout | undefined = undefined;
        let hasTimeout = false;
        const cancellationToken = () => {
            if (bufferCheckInterval) {
                clearInterval(bufferCheckInterval);
            }
            if (timeoutTimer) {
                clearTimeout(timeoutTimer);
            }
        };
        const timeoutRestartToken = () => {
            if (timeoutTimer) {
                this.rxBuffer = "";
                clearTimeout(timeoutTimer);
            }
            timeoutTimer = undefined;
            hasTimeout = false;
        };
        const promise = new Promise<AwpDeviceInfo>((resolve) => {
                bufferCheckInterval = setInterval(() => {
                    try {
                        if (!hasTimeout) {
                            let hwVersion;
                            let displayHwVersion;
                            let swVersion;
                            let displaySwVersion;
                            let bootloader;
                            const text = this.rxBuffer;
                            if (text !== "" && !timeoutTimer) {
                                timeoutTimer = setTimeout(() => {
                                    hasTimeout = true;
                                    timeoutCallback();
                                }, 30_000);
                            }
                            const oldBootloaderRegex = /.*Init\s+UART\s+921600.*[\n\r]/gm;
                            const oldBootloaderMatch = oldBootloaderRegex.exec(text);
                            logInfo(`Old BL: ${oldBootloaderMatch}`);
                            const newBootloaderRegex = /.*BOOT\s+Start[\n\r]/gm;
                            const newBootloaderMatch = newBootloaderRegex.exec(text);
                            logInfo(`New BL: ${newBootloaderMatch}`);
                            //TODO bootloader version regex
                            const gspBootloaderRegex = /.*BOOT\s+v\.4\.3[\n\r]/gm;
                            const gspBootloaderMatch = gspBootloaderRegex.exec(text);
                            logInfo(`GSP BL: ${gspBootloaderMatch}`);
                            const hwRegex = /.*HW\s*Ver\.\D*([\d.,]*)\D*[\n\r]/gm;
                            const hwMatch = hwRegex.exec(text);
                            logInfo(`HW: ${hwMatch}`);
                            let swRegex = /.*SW\s*Ver:\D*([\d.,]*)\D*[\n\r]/gm;
                            let swMatch = swRegex.exec(text);
                            logInfo(`SW: ${swMatch}`);
                            if (!swMatch) {
                                swRegex = /.*SW:\s+Ver\.\D*([\d.,]*)\D*[\n\r]/gm;
                                swMatch = swRegex.exec(text);
                                logInfo(`SW alt: ${swMatch}`);
                            }
                            if (hwMatch && swMatch) {
                                hwVersion = Number(hwMatch[1].trim());
                                swVersion = Number(swMatch[1].trim());
                            }
                            if (oldBootloaderMatch) {
                                bootloader = "old";
                            }
                            if (gspBootloaderMatch) {
                                //TODO parse bootloader version
                                bootloader = "1.1";
                            }
                            if (newBootloaderMatch) {
                                bootloader = "new";
                            }
                            logInfo(`BL: ${bootloader}, HW: ${hwVersion}, SW: ${swVersion}`);
                            if (deviceId === FW_TUD2 && !hwVersion && !swVersion && bootloader === "old") {
                                logInfo("TUD2 fallback");
                                hwVersion = 2.0;
                                swVersion = 1.0;
                                displayHwVersion = "2.X";
                                displaySwVersion = "-";
                            }
                            if (deviceId === FW_KRC && !hwVersion && !swVersion) {
                                logInfo("KRC fallback");
                                const gasGaugeRegex = /.*Init\s+GasGauge.*[\n\r]/gm;
                                const gasGaugeMatch = gasGaugeRegex.exec(text);
                                logInfo(`Gas Gauge: ${gasGaugeMatch}`);
                                if (gasGaugeMatch) {
                                    bootloader = "old";
                                    hwVersion = 3.1;
                                    swVersion = 5.0;
                                    displayHwVersion = "3.X";
                                    displaySwVersion = "-";
                                }
                            }
                            if (hwVersion !== undefined && swVersion !== undefined && bootloader) {
                                clearInterval(bufferCheckInterval);
                                resolve({
                                    hwVersion: hwVersion,
                                    displayHwVersion: displayHwVersion,
                                    swVersion: swVersion,
                                    displaySwVersion: displaySwVersion,
                                    bootloader: bootloader,
                                } as AwpDeviceInfo);
                            }
                        }
                    } catch (e) {
                        this.rxBuffer = "";
                    }
                }, 500);
            }
        );
        return [cancellationToken, timeoutRestartToken, promise];
    }

    readData(count: number, timeout: number): Promise<Uint8Array> {
        return new Promise(async (resolve, reject) => {
            let isTimeout = false;
            let elapsedTime = 0;
            const timeoutId = setTimeout(() => isTimeout = true, timeout);
            while (!isTimeout && elapsedTime < timeout) {
                if (this.rxBuffer.length >= count) {
                    clearTimeout(timeoutId);
                    const data = this.rxBuffer.slice(0, count);
                    this.rxBuffer = this.rxBuffer.substring(count);
                    const encoder = new TextEncoder();
                    resolve(encoder.encode(data));
                    return;
                }
                await delay(20);
                elapsedTime += 20;
            }
            reject("Response timeout");
        });
    }

    crc16(buf: Uint8Array, start: number, count: number) {
        let crc = 0;
        let i = 0;
        let n = 0;
        while (count-- > 0) {
            crc ^= (buf[start + n++] << 8) & 0xFFFF;
            for (i = 0; i < 8; i++) {
                if ((crc & 0x8000) === 0x8000) {
                    crc = (((crc << 1) & 0xFFFF) ^ 0x1021) & 0xFFFF;
                } else {
                    crc <<= 1;
                }
            }
        }
        return (crc);
    }

    async sendCRC(crc: number) {
        const bufTxd = new Uint8Array(2);
        bufTxd.set([(crc >> 8) & 0xFF, crc & 0xFF]);
        await this.port.writeBinaryAsync(bufTxd);
    }

    async sendBlockGsp(data: Uint8Array, shift: number, size: number) {
        let end = false;
        let errorCode = 0;
        do {
            logInfo("[Sending GSP block]");
            await this.port.writeBinaryAsync(data.slice(shift, shift + size));
            await this.sendCRC(this.crc16(data, shift, size));
            let response = await this.readData(1, 500);
            const ack = response[0];
            switch (ack) {
                case ACK:
                    end = true;
                    break;
                case CAN:
                    end = true;
                    errorCode = 1;
                    break;
                case EOT:
                    end = true;
                    errorCode = 3;
                    break;
                case NAK:
                    break;
                case -1:
                    end = true;
                    errorCode = -1;
                    break;
            }
        } while (!end);
        return (errorCode);
    }

    async sendDataGsp(data: Uint8Array, offset: number, size: number, progressCallback: (progressUpdate: number) => void) {
        let n = 0;
        let sendSize;
        let errorCode = 0;
        while (size > 0) {
            if (size > PACKET_NVI_SIZE) {
                sendSize = PACKET_NVI_SIZE;
            } else {
                sendSize = size;
            }
            errorCode = await this.sendBlockGsp(data, n + offset, sendSize);
            if (errorCode === 0) {
                size -= sendSize;
                n += sendSize;
                progressCallback(offset + n);
            } else {
                return;
            }
        }
    }

    async sendPacket(data: Uint8Array, blockNumber: number) {
        let packetSize;
        let crc;
        const bufTxd = new Uint8Array(PACKET_1K_SIZE);
        if (blockNumber === 0) {
            packetSize = PACKET_SIZE;
        } else {
            packetSize = PACKET_1K_SIZE;
        }
        crc = this.crc16(data, 0, packetSize);
        if (blockNumber === 0) {
            bufTxd[0] = SOH;
        } else {
            bufTxd[0] = STX;
        }
        bufTxd[1] = (blockNumber & 0xFF);
        bufTxd[2] = ~(blockNumber & 0xFF);
        await this.port.writeBinaryAsync(bufTxd.slice(0, 3));
        await this.port.writeBinaryAsync(data.slice(0, packetSize))
        bufTxd[0] = ((crc >> 8) & 0xFF);
        bufTxd[1] = (crc & 0xFF);
        await this.port.writeBinaryAsync(bufTxd.slice(0, 2));
        await delay(15);
    }


    async sendPacket0(name ?: string, size ?: number) {
        const block = new Uint8Array(PACKET_1K_SIZE);
        block.fill(0);
        if (name && size) {
            const encoder = new TextEncoder();
            const nameData = encoder.encode(name);
            const sizeData = encoder.encode(`${size}`);
            block.set(nameData);
            block.set(sizeData, nameData.length + 1);
        }
        await this.sendPacket(block, 0);
    }


    async sendDataPackets(data: Uint8Array, size: number, progressCallback: (progressUpdate: number) => void) {
        let progress = 0;
        let blockNumber = 1;
        let n = 0;
        let sendSize;
        let response;
        let ch;
        const bufTxd = new Uint8Array(PACKET_1K_SIZE);
        while (size > 0) {
            if (size > PACKET_1K_SIZE) {
                sendSize = PACKET_1K_SIZE;
            } else {
                sendSize = size;
            }
            for (let i = 0; i < sendSize; ++i) {
                bufTxd[i] = data[n + i];
            }
            await this.sendPacket(bufTxd, blockNumber);
            let response = await this.readData(1, 5_000);
            let ch = response[0];
            if (ch === ACK) {
                blockNumber++;
                size -= sendSize;
                n += sendSize;
                progress += sendSize;
                progressCallback(progress);
            } else {
                if ((ch === CAN) || (ch === -1)) {
                    return;
                }
            }
        }
        do {
            bufTxd[0] = EOT;
            await this.port.writeBinaryAsync(bufTxd.slice(0, 1));
            response = await this.readData(1, 5_000);
            ch = response[0];
        } while ((ch !== ACK) && (ch !== -1));

        if (ch === ACK) {
            response = await this.readData(1, 5_000);
            ch = response[0];
            if (ch === CRC) {
                do {
                    await this.sendPacket0();
                    response = await this.readData(1, 5_000);
                    ch = response[0];
                } while ((ch !== ACK) && (ch !== -1));
            }
        }
    }

    sendGsp(data: ArrayBuffer, baudRate: number, progressCallback: (progress: number) => void):
        Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const baudRateChangeResult = await this.port.setBaudRate(baudRate);
            if (baudRateChangeResult === true) {
                this.isListening = false;
            }
            if (baudRateChangeResult === false) {
                reject("Baud rate change failed");
                return;
            }
            this.listen();
            try {
                const encoder = new TextEncoder();
                let startAttempts = 0;
                while (startAttempts < 3) {
                    await this.port.writeTextAsync(encoder, "\r");
                    await delay(200);
                    this.rxBuffer = "";
                    await this.port.writeTextAsync(encoder, "START\r");
                    let response = await this.readData(1, 200);
                    let c = response[0];
                    if (c === ACK || c === 79 || c === 111) {
                        break;
                    }
                    startAttempts++;
                    this.rxBuffer = "";
                    await this.port.writeTextAsync(encoder, "BOOT\r");
                    let elapsedTime = 0;
                    while (elapsedTime < 2000) {
                        await delay(200);
                        elapsedTime += 200;
                        if (this.rxBuffer.match(/.*BOOT v.*[\r\n]+/)) {
                            await delay(5000);
                            break;
                        }
                    }
                }
                if (startAttempts >= 3) {
                    reject("Can't enter to the boot mode");
                    return;
                }
                await delay(200);
                this.rxBuffer = "";
                const errorCode = await this.sendBlockGsp(new Uint8Array(data), 0, HEAD_NVI_SIZE);
                switch (errorCode) {
                    case 1:
                    case -1:
                        reject("Connection error");
                        break;
                    case 3:
                        reject("Incompatible version");
                        break;
                }
                if (errorCode === 0) {
                    const headGsp = parseAwpFwHeadGsp(data);
                    let response = await this.readData(1, 60000);
                    let c = response[0];
                    if (c !== ACK) {
                        reject("Bad response");
                        return;
                    }
                    let allSize = 0;
                    for (let i = 0; i < headGsp.n; ++i) {
                        allSize += headGsp.blockInfo[i].len;
                    }
                    progressCallback(0);

                    for (let i = 0; i < headGsp.n; ++i) {
                        await delay(1000);
                        const size = headGsp.blockInfo[i].len;
                        const offset = headGsp.blockInfo[i].adr + HEAD_NVI_SIZE;
                        await this.sendDataGsp(new Uint8Array(data), offset, size, progressCallback);
                    }
                    resolve();
                }
                reject("Bad response");
            } catch (e) {
                reject(e);
            }
        });
    }

    sendNvt(data: ArrayBuffer, progressCallback: (progress: number) => void): Promise<void> {
        return new Promise(async (resolve, reject) => {
            let baudRateChangeResult = await this.port.setBaudRate(AwpFwSerialDevice.speedOldLoader);
            if (baudRateChangeResult === true) {
                this.isListening = false;
            }
            if (baudRateChangeResult === false) {
                reject("Baud rate change failed");
                return;
            }
            this.listen();
            const dataArray = new Uint8Array(data);
            let count = data.byteLength;
            let shift = 0;
            do {
                const packetLength = Math.min(count, MAX_PACKET_LENGTH);
                const buffer = Uint8Array.of(packetLength & 0xFF, (packetLength >> 8) & 0xFF);
                await this.port.writeBinaryAsync(buffer);
                const slice = dataArray.slice(shift, shift + packetLength);
                await this.port.writeBinaryAsync(slice);
                const size = await this.readData(3, 20_000)
                    .then(response => {
                        if (response[0] !== 0) {
                            throw "Bad response";
                        } else {
                            return response[1] + response[2] * 256;
                        }
                    })
                    .catch(e => {
                        logError("Error writing data", e);
                        return null;
                    });
                if (size) {
                    shift += size;
                    count -= size;
                    progressCallback(shift);
                } else {
                    reject();
                    return;
                }
            } while (count > 0);
            baudRateChangeResult = await this.port.setBaudRate(AwpFwSerialDevice.speed921600)
            if (baudRateChangeResult === true) {
                this.isListening = false;
                this.listen();
            }
            resolve();
        });
    }

    sendPics(data: ArrayBuffer, progressCallback: (progress: number) => void): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const baudRateChangeResult = await this.port.setBaudRate(AwpFwSerialDevice.speed921600)
            if (baudRateChangeResult === true) {
                this.isListening = false;
            }
            if (baudRateChangeResult === false) {
                reject("Baud rate change failed");
                return;
            }
            this.listen();
            const encoder = new TextEncoder();
            await this.port.writeTextAsync(encoder, "\r");
            await delay(200);
            this.rxBuffer = "";
            await this.port.writeTextAsync(encoder, "PICS\r");
            await delay(200);
            this.rxBuffer = "";
            let crcNak = 1;
            let response = await this.readData(1, 50_000);
            let c = response[0];
            if (c >= 0) {
                if (c === CRC) {
                    while (true) {
                        c = 0;
                        await delay(200);
                        await this.sendPacket0("Test.bin", data.byteLength);
                        response = await this.readData(1, 80_000);
                        c = response[0];
                        if (c === ACK) {
                            response = await this.readData(1, 5_000);
                            c = response[0];
                            if (c === CRC) {
                                await delay(200);
                                await this.sendDataPackets(new Uint8Array(data), data.byteLength, progressCallback);
                            }
                        } else {
                            if ((c === CRC) && (crcNak === 1)) {
                                crcNak = 0;
                                break;
                            } else {
                                if ((c !== NAK) || (crcNak === 1)) {
                                    const bufTxd = new Uint8Array(2);
                                    bufTxd.fill(CAN);
                                    await delay(200);
                                    await this.port.writeBinaryAsync(bufTxd);
                                    resolve();
                                    return;
                                }
                            }
                        }
                    }
                }
            }
            reject("Bad response");
        });
    }

    sendNvp(data: ArrayBuffer, progressCallback: (progress: number) => void): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let baudRateChangeResult = await this.port.setBaudRate(AwpFwSerialDevice.speed921600)
            if (baudRateChangeResult === true) {
                this.isListening = false;
            }
            if (baudRateChangeResult === false) {
                reject("Baud rate change failed");
                return;
            }
            this.listen();
            const encoder = new TextEncoder();
            await this.port.writeTextAsync(encoder, "\r");
            await delay(200);
            this.rxBuffer = "";
            await this.port.writeTextAsync(encoder, "FIRMWARE\r");
            await delay(200);
            this.rxBuffer = "";
            let crcNak = 1;
            let response = await this.readData(1, 50_000);
            let c = response[0];
            if (c >= 0) {
                if (c === CRC) {
                    while (true) {
                        c = 0;
                        await this.sendPacket0("ALLSW.nvp", data.byteLength);
                        response = await this.readData(1, 80_000);
                        c = response[0];
                        if (c === ACK) {
                            response = await this.readData(1, 5_000);
                            c = response[0];
                            if (c === CRC) {
                                await this.sendDataPackets(new Uint8Array(data), data.byteLength, progressCallback);
                            }
                        } else {
                            if ((c === CRC) && (crcNak === 1)) {
                                crcNak = 0;
                                break;
                            } else {
                                if ((c !== NAK) || (crcNak === 1)) {
                                    const bufTxd = new Uint8Array(2);
                                    bufTxd.fill(CAN);
                                    await this.port.writeBinaryAsync(bufTxd);
                                    baudRateChangeResult = await this.port.setBaudRate(AwpFwSerialDevice.speedOldLoader)
                                    if (baudRateChangeResult === true) {
                                        this.isListening = false;
                                        this.listen();
                                    }
                                    resolve();
                                    return;
                                }
                            }
                        }
                    }
                }
            }
            reject("Bad response");
        });
    }

    sendConfig(data: ArrayBuffer, progressCallback: (progress: number) => void):
        Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            const baudRateChangeResult = await this.port.setBaudRate(AwpFwSerialDevice.speed921600)
            if (!baudRateChangeResult) {
                reject("Baud rate change failed");
                return;
            }
            this.listen();
            const encoder = new TextEncoder();
            await this.port.writeTextAsync(encoder, "\r");
            await delay(200);
            this.rxBuffer = "";
            await this.port.writeTextAsync(encoder, "CONFIG\r");
            await delay(200);
            this.rxBuffer = "";
            let crcNak = 1;
            let response = await this.readData(1, 50_000);
            let c = response[0];
            if (c >= 0) {
                if (c === CRC) {
                    while (true) {
                        c = 0;
                        await delay(200);
                        await this.sendPacket0("Config.ncf", data.byteLength);
                        response = await this.readData(1, 80_000);
                        c = response[0];
                        if (c === ACK) {
                            response = await this.readData(1, 5_000);
                            c = response[0];
                            if (c === CRC) {
                                await delay(200);
                                await this.sendDataPackets(new Uint8Array(data), data.byteLength, progressCallback);
                            }
                        } else {
                            if ((c === CRC) && (crcNak === 1)) {
                                crcNak = 0;
                                break;
                            } else {
                                if ((c !== NAK) || (crcNak === 1)) {
                                    const bufTxd = new Uint8Array(2);
                                    bufTxd.fill(CAN);
                                    await delay(200);
                                    await this.port.writeBinaryAsync(bufTxd);
                                    resolve();
                                    return;
                                }
                            }
                        }
                    }
                }
            }
            reject("Bad response");
        });
    }

    executeDisconnectCallback(): boolean {
        return this.hasDisconnectListener;
    }

    addDisconnectListener(listener: () => void) {
        this.port.addEventListener('disconnect', listener);
        this.hasDisconnectListener = true;
    }

    removeDisconnectListener(callback: () => void) {
        this.port.removeEventListener("disconnect", callback);
        this.hasDisconnectListener = false;
    }
}