import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {buildAwpOptions} from "../../helpers/AwpOptions";
import './AwpList.css';
import {Col, Row} from "react-bootstrap";
import {IS_DEMO_APP, IS_LOCAL_APP} from "../../AppSettings";
import {Fragment} from "react";
import {AWP_DATA_STREAMING_ROUTE} from "../../routes";
import ut3ema from "../../png/UT-3М-EMA.png"

export function AwpList() {
    const {t} = useTranslation();
    const history = useHistory();
    const navigationHandler: (route: string) => void = (route => {
        history.push(route);
    });
    return (
        <div className="container-grow d-flex flex-column m-4 align-items-center">
            <div className="awp-list-title">{t("records_transfer")}</div>
            <Row sm={2} md={3} lg={4}>
                {buildAwpOptions(t).map((item, i) => <Col key={`awp-list-item-${i}`} className="d-flex flex-grow-1">
                    <div
                        className="d-flex flex-column flex-grow-1 text-center justify-content-between align-items-center awp-item clickable my-2 p-4"
                        onClick={() => navigationHandler(item.path)}>
                        <img height="80px" src={item.image}/>
                        <span className="mt-4 awp-device-name">{item.title}</span>
                    </div>
                </Col>)}
            </Row>
            <div className='flex-grow-1'/>
            {!IS_DEMO_APP &&
                <Fragment>
                    <div className="awp-list-title mt-4">{t("data_streaming")}</div>
                    <Row>
                        <Col key={`awp-list-item-fw`} className="d-flex flex-grow-1">
                            <div
                                className="d-flex flex-column flex-grow-1 text-center justify-content-around align-items-center awp-item clickable mt-4 mb-2 p-4"
                                onClick={() => navigationHandler(AWP_DATA_STREAMING_ROUTE)}>
                                <img height="80px" src={ut3ema}/>
                                <span className="mt-4 awp-device-name">{t('awp_ut3ema')}</span>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            }
            {!IS_LOCAL_APP &&
                <Fragment>
                    <span className='awp-description mt-4'>{t('awp_description_1')} {t('awp_description_2')}</span>
                    <span className='awp-description fw-bold'>{t('awp_description_3')}</span>
                </Fragment>
            }
        </div>
    );
}