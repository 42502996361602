import {Route} from "react-router-dom";
import {
    AWP_DATA_STREAMING_ROUTE,
    AWP_IPSM_BASE_ROUTE, AWP_IPSM_DATA_TRANSFER_ROUTE,
    AWP_IPSM_RECORD_ROUTE,
    AWP_IPSM_ROUTE,
    AWP_KRC_BASE_ROUTE,
    AWP_KRC_RECORD_ROUTE,
    AWP_KRC_ROUTE,
    AWP_LIST_ROUTE, AWP_MF_BASE_ROUTE,
    AWP_MF_RECORD_ROUTE,
    AWP_MF_ROUTE, AWP_TP_BASE_ROUTE,
    AWP_TP_RECORD_ROUTE,
    AWP_TP_ROUTE, AWP_TUD_BASE_ROUTE,
    AWP_TUD_RECORD_ROUTE,
    AWP_TUD_ROUTE, AWP_UD2301_BASE_ROUTE,
    AWP_UD2301_RECORD_ROUTE,
    AWP_UD2301_ROUTE, AWP_UD2303_BASE_ROUTE,
    AWP_UD2303_RECORD_ROUTE,
    AWP_UD2303_ROUTE, AWP_UD3701_BASE_ROUTE,
    AWP_UD3701_RECORD_ROUTE,
    AWP_UD3701_ROUTE, AWP_UT2A_BASE_ROUTE,
    AWP_UT2A_RECORD_ROUTE,
    AWP_UT2A_ROUTE, AWP_UT3EMA_BASE_ROUTE,
    AWP_UT3EMA_RECORD_ROUTE,
    AWP_UT3EMA_ROUTE, AWP_UT_BASE_ROUTE,
    AWP_UT_RECORD_ROUTE,
    AWP_UT_ROUTE
} from "./routes";
import {AwpList} from "./components/AwpList/AwpList";
import {AwpManager} from "./components/AwpManager/AwpManager";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA
} from "./helpers/AwpHelper";
import {AwpRecordView} from "./components/AwpRecordView/AwpRecordView";
import React, {Fragment} from "react";
import {AwpIpsmDataTransfer} from "./components/AwpManager/AwpIpsmDataTransfer";
import {AwpDataStreaming} from "./components/AwpDataStreaming/AwpDataStreaming";
import {AwpFwUpdateRouter} from "./AwpFwUpdateRouter";

export function AwpRouter(){
    return (
        <Fragment>
            <Route exact path={AWP_LIST_ROUTE}>
                <AwpList/>
            </Route>
            <Route exact path={AWP_TUD_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_TUD}/>
            </Route>
            <Route exact path={AWP_TUD_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_TUD}/>
            </Route>
            <Route exact path={AWP_TUD_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_TUD}/>
            </Route>
            <Route exact path={AWP_KRC_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_KRC}/>
            </Route>
            <Route exact path={AWP_KRC_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_KRC}/>
            </Route>
            <Route exact path={AWP_KRC_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_KRC}/>
            </Route>
            <Route exact path={AWP_TP_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_TP}/>
            </Route>
            <Route exact path={AWP_TP_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_TP}/>
            </Route>
            <Route exact path={AWP_TP_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_TP}/>
            </Route>
            <Route exact path={AWP_UT_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UT}/>
            </Route>
            <Route exact path={AWP_UT_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UT}/>
            </Route>
            <Route exact path={AWP_UT_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_UT}/>
            </Route>
            <Route exact path={AWP_MF_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_MF}/>
            </Route>
            <Route exact path={AWP_MF_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_MF}/>
            </Route>
            <Route exact path={AWP_MF_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_MF}/>
            </Route>
            <Route exact path={AWP_UD2301_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UD2301}/>
            </Route>
            <Route exact path={AWP_UD2301_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UD2301}/>
            </Route>
            <Route exact path={AWP_UD2301_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_UD2301}/>
            </Route>
            <Route exact path={AWP_UD2303_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UD2303}/>
            </Route>
            <Route exact path={AWP_UD2303_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UD2303}/>
            </Route>
            <Route exact path={AWP_UD2303_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_UD2303}/>
            </Route>
            <Route exact path={AWP_UD3701_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UD3701}/>
            </Route>
            <Route exact path={AWP_UD3701_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UD3701}/>
            </Route>
            <Route exact path={AWP_UD3701_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_UD3701}/>
            </Route>
            <Route exact path={AWP_UT3EMA_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UT3EMA}/>
            </Route>
            <Route exact path={AWP_UT3EMA_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UT3EMA}/>
            </Route>
            <Route exact path={AWP_UT3EMA_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_UT3EMA}/>
            </Route>
            <Route exact path={AWP_UT2A_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UT2A}/>
            </Route>
            <Route exact path={AWP_UT2A_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_UT2A}/>
            </Route>
            <Route exact path={AWP_UT2A_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_UT2A}/>
            </Route>
            <Route exact path={AWP_IPSM_BASE_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_IPSM}/>
            </Route>
            <Route exact path={AWP_IPSM_ROUTE}>
                <AwpManager deviceType={AWP_DEVICE_IPSM}/>
            </Route>
            <Route exact path={AWP_IPSM_RECORD_ROUTE}>
                <AwpRecordView deviceType={AWP_DEVICE_IPSM}/>
            </Route>
            <Route exact path={AWP_IPSM_DATA_TRANSFER_ROUTE}>
                <AwpIpsmDataTransfer/>
            </Route>
            <AwpFwUpdateRouter/>
            <Route exact path={AWP_DATA_STREAMING_ROUTE}>
                <AwpDataStreaming/>
            </Route>
        </Fragment>
    );
}