import {ToolbarProps} from "react-big-calendar";
// @ts-ignore
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import {useRef} from "react";
import {useTranslation} from "react-i18next";

export interface CalendarToolbarProps extends ToolbarProps {
    min: Date;
    max: Date;
    monthChangeListener: (newDate: number) => void;
}

export function CalendarToolbar(props: CalendarToolbarProps) {
    const ref = useRef<Picker>();
    const {t} = useTranslation();
    const months = [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'), t('oct'), t('nov'), t('dec')];
    const minYear = props.min.getFullYear();
    const minMonth = props.min.getMonth() + 1;
    const maxYear = props.max.getFullYear();
    const maxMonth = props.max.getMonth() + 1;
    const valueYear = props.date.getFullYear();
    const valueMonth = props.date.getMonth() + 1;
    const isPreviousButtonDisabled = valueYear === minYear && valueMonth === minMonth;
    const isNextButtonDisabled = valueYear === maxYear && valueMonth === maxMonth;
    return (
        <div>
            <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                <button type="button" disabled={isPreviousButtonDisabled}
                        onClick={() => props.onNavigate('PREV')}>{props.localizer.messages.previous}</button>
                 <button type="button"
                         onClick={() => props.onNavigate('TODAY')}>{props.localizer.messages.today}</button>
                <button type="button" disabled={isNextButtonDisabled}
                        onClick={() => props.onNavigate('NEXT')}>{props.localizer.messages.next}</button>
            </span>
                <span className="rbc-toolbar-label" onClick={() => {
                    ref.current?.show()
                }}>{props.label}</span>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Picker
                    ref={ref}
                    lang={months}
                    years={{
                        min: {year: minYear, month: minMonth},
                        max: {year: maxYear, month: maxMonth}
                    }}
                    value={{year: valueYear, month: valueMonth}}
                    onChange={(year: any, month: any) => {
                        props.monthChangeListener(Date.UTC(year, month - 1, 1));
                    }}>
                </Picker>
            </div>
        </div>
    );
}