export const FW_UNKNOWN = -1;
export const FW_KRC = 1;
export const FW_MF1M = 2;
export const FW_TUD2 = 3;
export const FW_TUD3 = 4;
export const FW_TP2020 = 5;
export const FW_UD2301 = 6;
export const FW_UD2303 = 7;
export const FW_UD3701 = 8;
export const FW_UT1M = 9;
export const FW_UT1MIP = 10;
export const FW_UT1MST = 11;
export const FW_UT3EMA = 12;
export const FW_UT2A = 13;
export const FW_ARS1 = 14;
export const FW_LEEB = 15;

export const FW_LEEB_PLAIN = 15;

export const FW_DEVICES = [
    FW_KRC,
    FW_MF1M,
    FW_TUD2,
    FW_TUD3,
    FW_TP2020,
    FW_UD2301,
    FW_UD2303,
    // FW_UD3701,       ///Temporarily removed due to different firmware upload method
    FW_UT1M,
    FW_UT1MIP,
    FW_UT1MST,
    FW_UT3EMA,
    FW_UT2A,
    FW_ARS1,
    FW_LEEB,
    FW_LEEB_PLAIN
];
