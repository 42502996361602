import LoaderSvg from "./LoaderSvg";
import './Loader.css';

interface Props{
    message?: string;
    isDanger?: boolean;
    children?: JSX.Element | boolean
}

export function Loader(props: Props) {
    return (
        <div className="container-grow justify-content-center align-items-center">
            <div className="loader-normal">
                <LoaderSvg/>
            </div>
            {props.message &&
                <div className={`mx-4 my-4 loader-message ${(props.isDanger ?? false) ? 'loader-message-danger' : ''}`}>{props.message}</div>
            }
            {props.children}
        </div>
    );
}