import {TFunction} from "react-i18next";
import {
    AWP_IPSM_BASE_ROUTE,
    AWP_KRC_BASE_ROUTE,
    AWP_KRC_ROUTE, AWP_MF_BASE_ROUTE,
    AWP_MF_ROUTE, AWP_TP_BASE_ROUTE,
    AWP_TP_ROUTE, AWP_TUD_BASE_ROUTE,
    AWP_TUD_ROUTE, AWP_UD2301_BASE_ROUTE,
    AWP_UD2301_ROUTE, AWP_UD2303_BASE_ROUTE,
    AWP_UD2303_ROUTE, AWP_UD3701_BASE_ROUTE,
    AWP_UD3701_ROUTE, AWP_UT2A_BASE_ROUTE,
    AWP_UT2A_ROUTE, AWP_UT3EMA_BASE_ROUTE,
    AWP_UT3EMA_ROUTE, AWP_UT_BASE_ROUTE,
    AWP_UT_ROUTE
} from "../routes";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA
} from "./AwpHelper";
import {IS_DEMO_APP} from "../AppSettings";
import ipsm from "../png/IPSM.png"
import krc from "../png/KRC-M2.png"
import mf from "../png/MF-1M.png"
import tud from "../png/T-UD2.png"
import tp from "../png/TP-2020.png"
import ud2301 from "../png/UD2301.png"
import ud2303 from "../png/UD2303.png"
import ud3701 from "../png/UD3701.png"
import ut1m from "../png/UT-1M.png"
import ut2a from "../png/UT-2A.png"
import ut3ema from "../png/UT-3М-EMA.png"


export interface AwpOption {
    title: string;
    image: any;
    path: string;
    deviceType: number;
}

export function formatAwpDeviceName(t: TFunction<"translation">, deviceType: number) {
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return t('awp_tud');
        case AWP_DEVICE_TP:
            return t('awp_tp');
        case AWP_DEVICE_UT:
            return t('awp_ut');
        case AWP_DEVICE_UT2A:
            return t('awp_ut2a');
        case AWP_DEVICE_UT3EMA:
            return t('awp_ut3ema');
        case AWP_DEVICE_UD2301:
            return t('awp_ud2301');
        case AWP_DEVICE_UD2303:
            return t('awp_ud2303');
        case AWP_DEVICE_UD3701:
            return t('awp_ud3701');
        case AWP_DEVICE_MF:
            return t('awp_mf');
        case AWP_DEVICE_KRC:
            return t('awp_krc');
        case AWP_DEVICE_IPSM:
            return t('awp_ipsm');
    }
}

export function buildAwpOptions(t: TFunction<"translation">): Array<AwpOption> {
    return IS_DEMO_APP ? buildDemoAwpOptions(t) : buildMainOptions(t);
}

function buildMainOptions(t: TFunction<"translation">): Array<AwpOption> {
    return [
        {
            title: t('awp_tud'),
            image: tud,
            path: AWP_TUD_BASE_ROUTE,
            deviceType: AWP_DEVICE_TUD
        },
        {
            title: t('awp_tp'),
            image: tp,
            path: AWP_TP_BASE_ROUTE,
            deviceType: AWP_DEVICE_TP
        },
        {
            title: t('awp_ut'),
            image: ut1m,
            path: AWP_UT_BASE_ROUTE,
            deviceType: AWP_DEVICE_UT
        },
        {
            title: t('awp_ut2a'),
            image: ut2a,
            path: AWP_UT2A_BASE_ROUTE,
            deviceType: AWP_DEVICE_UT2A
        },
        {
            title: t('awp_ut3ema'),
            image: ut3ema,
            path: AWP_UT3EMA_BASE_ROUTE,
            deviceType: AWP_DEVICE_UT3EMA
        },
        {
            title: t('awp_ud2301'),
            image: ud2301,
            path: AWP_UD2301_BASE_ROUTE,
            deviceType: AWP_DEVICE_UD2301
        },
        {
            title: t('awp_ud2303'),
            image: ud2303,
            path: AWP_UD2303_BASE_ROUTE,
            deviceType: AWP_DEVICE_UD2303
        },
        {
            title: t('awp_ud3701'),
            image: ud3701,
            path: AWP_UD3701_BASE_ROUTE,
            deviceType: AWP_DEVICE_UD3701
        },
        {
            title: t('awp_mf'),
            image: mf,
            path: AWP_MF_BASE_ROUTE,
            deviceType: AWP_DEVICE_MF
        },
        {
            title: t('awp_krc'),
            image: krc,
            path: AWP_KRC_BASE_ROUTE,
            deviceType: AWP_DEVICE_KRC
        },
        {
            title: t('awp_ipsm'),
            image: ipsm,
            path: AWP_IPSM_BASE_ROUTE,
            deviceType: AWP_DEVICE_IPSM
        }
    ];
}

function buildDemoAwpOptions(t: TFunction<"translation">): Array<AwpOption> {
    return [
        {
            title: t('awp_tud'),
            image: tud,
            path: AWP_TUD_BASE_ROUTE,
            deviceType: AWP_DEVICE_TUD
        },
        {
            title: t('awp_tp'),
            image: tp,
            path: AWP_TP_BASE_ROUTE,
            deviceType: AWP_DEVICE_TP
        },
        {
            title: t('awp_ut'),
            image: ut1m,
            path: AWP_UT_BASE_ROUTE,
            deviceType: AWP_DEVICE_UT
        },
        {
            title: t('awp_ut2a'),
            image: ut2a,
            path: AWP_UT2A_BASE_ROUTE,
            deviceType: AWP_DEVICE_UT2A
        },
        {
            title: t('awp_ut3ema'),
            image: ut3ema,
            path: AWP_UT3EMA_BASE_ROUTE,
            deviceType: AWP_DEVICE_UT3EMA
        },
        {
            title: t('awp_ud2301'),
            image: ud2301,
            path: AWP_UD2301_BASE_ROUTE,
            deviceType: AWP_DEVICE_UD2301
        },
        {
            title: t('awp_ud2303'),
            image: ud2303,
            path: AWP_UD2303_BASE_ROUTE,
            deviceType: AWP_DEVICE_UD2303
        },
        {
            title: t('awp_ud3701'),
            image: ud3701,
            path: AWP_UD3701_BASE_ROUTE,
            deviceType: AWP_DEVICE_UD3701
        },
        {
            title: t('awp_mf'),
            image: mf,
            path: AWP_MF_BASE_ROUTE,
            deviceType: AWP_DEVICE_MF
        },
        {
            title: t('awp_krc'),
            image: krc,
            path: AWP_KRC_BASE_ROUTE,
            deviceType: AWP_DEVICE_KRC
        }
    ];
}