import {TFunction} from "react-i18next";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD, AWP_DEVICE_UD2301, AWP_DEVICE_UD2303, AWP_DEVICE_UD3701,
    AWP_DEVICE_UT, AWP_DEVICE_UT2A, AWP_DEVICE_UT3EMA
} from "./AwpHelper";

export function formatAwpDeviceDisplayName(t: TFunction<"translation">, deviceType: number, serial : string){
    if (deviceType === AWP_DEVICE_TUD) {
        if (serial.startsWith("006") || serial.startsWith("008") || serial.startsWith("06") || serial.startsWith("08")) {
            return t('tud2_display_name');
        }
        if (serial.startsWith("009") || serial.startsWith("09")) {
            return t('tud3_display_name');
        }
    }
    if (deviceType === AWP_DEVICE_KRC){
        return t('krc2_display_name');
    }
    if (deviceType === AWP_DEVICE_IPSM){
        return t('ipsm_display_name');
    }
    if (deviceType === AWP_DEVICE_TP) {
        if (serial.startsWith("001") || serial.startsWith("01")) {
            return t('tp1m_display_name')
        }
        if (serial.startsWith("043") || serial.startsWith("43")) {
            return t('tp2020_display_name')
        }
    }
    if (deviceType === AWP_DEVICE_UT) {
        if (serial.startsWith("027") || serial.startsWith("27")) {
            return t('ut1m_display_name');
        }
        if (serial.startsWith("044") || serial.startsWith("44")) {
            return t('ut1m_ip_display_name');
        }
        if (serial.startsWith("045") || serial.startsWith("45")) {
            return t('ut1m_ct_display_name');
        }
    }
    if (deviceType === AWP_DEVICE_MF){
        return t('mf1m_display_name');
    }
    if (deviceType === AWP_DEVICE_UD2301){
        return t('ud2301_display_name');
    }
    if (deviceType === AWP_DEVICE_UD2303){
        return t('ud2303_display_name');
    }
    if (deviceType === AWP_DEVICE_UD3701){
        return t('ud3701_display_name');
    }
    if (deviceType === AWP_DEVICE_UT3EMA){
        return t('ut3ema_display_name');
    }
    if (deviceType === AWP_DEVICE_UT2A){
        return t('ut2a_display_name');
    }
    return "NOVOTEST";
}