import React, {Suspense} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import './App.css';
import {Viewer} from "./components/Viewer/Viewer";
import {Container} from "react-bootstrap";
import {Footer} from "./components/Footer/Footer";
import {
    buildListPath,
    CALENDAR_ROUTE,
    EXECUTORS_ROUTE,
    HOME_ROUTE,
    LIST_BASE_ROUTE,
    LIST_ROUTE,
    MAP_ROUTE,
    RECORD_ROUTE,
    SHARED_RECORD_ROUTE
} from "./routes";
import {MapExplorer} from "./components/MapExplorer/MapExplorer";
import {CalendarExplorer} from "./components/CalendarExplorer/CalendarExplorer";
import {ListExplorer} from "./components/ListExplorer/ListExplorer";
import {CALENDAR, LIST, MAP} from "./models/ExplorerMode";
import {LoadingScreen} from "./components/LoadingScreen/LoadingScreen";
import {LIST_LOCATION} from "./persistence";
import {getSavedValue} from "./helpers/PersistenceHelper";
import {Executors} from "./components/Executors/Executors";
import {AppBarDemo} from "./components/AppBar/AppBarDemo";
import {AwpRouter} from "./AwpRouter";

function AppDemo() {
    const history = useHistory();
    const modeChangeHandler = (mode: number) => {
        switch (mode) {
            case LIST:
                history.push(buildListPath(getSavedValue(LIST_LOCATION, "")));
                break;
            case MAP:
                history.push(MAP_ROUTE);
                break;
            case CALENDAR:
                history.push(CALENDAR_ROUTE);
                break;
        }
    }
    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Container fluid>
                <AppBarDemo/>
                <div className="container-content">
                    <div className="container-grow">
                        <Switch>
                            <Route exact path={SHARED_RECORD_ROUTE}>
                                <Viewer/>
                            </Route>
                            <Route exact path={RECORD_ROUTE}>
                                <Viewer/>
                            </Route>
                            <Route exact path={MAP_ROUTE}>
                                <MapExplorer modeChangeListener={modeChangeHandler}/>
                            </Route>
                            <Route exact path={CALENDAR_ROUTE}>
                                <CalendarExplorer modeChangeListener={modeChangeHandler}/>
                            </Route>
                            <Route exact path={LIST_BASE_ROUTE}>
                                {<ListExplorer modeChangeListener={modeChangeHandler}/>}
                            </Route>
                            <Route exact path={LIST_ROUTE}>
                                {<ListExplorer modeChangeListener={modeChangeHandler}/>}
                            </Route>
                            <Route exact path={EXECUTORS_ROUTE}>
                                {<Executors />}
                            </Route>
                            <Route exact path={HOME_ROUTE}>
                                <Redirect to={buildListPath()}/>
                            </Route>
                            <AwpRouter/>
                            <Route>
                                <Redirect to={HOME_ROUTE}/>
                            </Route>
                        </Switch>
                    </div>
                    <Footer/>
                </div>
            </Container>
        </Suspense>
    );
}

export default AppDemo;

