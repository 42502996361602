import {ArchiveModeSwitcher} from "../ArchiveModeSwitcher/ArchiveModeSwitcher";
import {CALENDAR} from "../../models/ExplorerMode";
import {Calendar, Event, momentLocalizer} from 'react-big-calendar';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {DeviceFilterSwitcher} from "../DeviceFilterSwitcher/DeviceFilterSwitcher";
import {
    defaultDeviceFilter,
    getDeviceFilterState,
    isValidDeviceType,
    setDeviceFilterState
} from "../../models/DeviceFilter";
import {useHistory} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {RecordWrapper} from "../../models/RecordWrapper";
import {usePersistentState} from "../../hooks/PersistentStateHook";
import {CALENDAR_DATE, EXPLORER_DEVICE_FILTER} from "../../persistence";
import {indexRecords} from "../../helpers/GoogleStorageApiHelper";
import {Loader} from "../Loader/Loader";
import {Error} from "../Error/Error";
import './CalendarExplorer.css';
import {buildRecordPath, CALENDAR_PAGE_NAME} from "../../routes";
import {formatDateTime, formatDeviceName} from "../../helpers/FormatHelper";
import {CalendarToolbar} from "./CalendarToolbar";
import {useAbortStatus} from "../../hooks/AbortHook";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {useTranslation} from "react-i18next";
import 'moment/locale/ru';
import 'moment/locale/uk';
import {useLocale} from "../../hooks/LocaleHook";
import {useAnalytics} from "../../hooks/AnalyticsHook";
import {useGoogleApi} from "../../google_api/GoogleApiProvider";


interface Props {
    modeChangeListener: (mode: number) => void;
}

export function CalendarExplorer(props: Props) {
    useAnalytics(CALENDAR_PAGE_NAME);
    const {t} = useTranslation();
    const locale = useLocale();
    const isAborted = useAbortStatus();
    const [date, setDate] = usePersistentState(CALENDAR_DATE, Date.now());
    const localizer = momentLocalizer(moment);
    const drive = useGoogleApi().drive;
    const history = useHistory();
    const [error, setError] = useState(null as Error | null);
    const [items, setItems] = useState(null as Array<RecordWrapper> | null);
    const [deviceFilter, setDeviceFilter] = usePersistentState(EXPLORER_DEVICE_FILTER, defaultDeviceFilter);
    const [filter, setFilter] = useState(new Map<string, boolean>());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (drive) {
            setItems(null);
            indexRecords(drive).then(items => {
                if (!isAborted) {
                    const filter = new Map<string, boolean>();
                    items.forEach(m => {
                        if (isValidDeviceType(m.record.deviceType) && !filter.has(m.record.deviceType)) {
                            let storedState = getDeviceFilterState(deviceFilter, m.record.deviceType);
                            if (storedState !== undefined) {
                                filter.set(m.record.deviceType, storedState);
                            }
                        }
                    });
                    setFilter(filter);
                    setItems(items);
                    setError(null);
                }
            }).catch((e) => {
                if (!isAborted) {
                    setError(e);
                }
            })
        }
    }, [drive]);
    const displayItems = useMemo(() => {
        return items?.filter(i => filter.get(i.record.deviceType) ?? false).map(i => {
            return {
                start: new Date(i.record.dateTime),
                end: new Date(i.record.dateTime),
                title: i.record.name,
                resource: i
            } as Event;
        });
    }, [items, filter]);
    let min = new Date();
    let max = new Date();
    if (displayItems) {
        let minTime = Date.now();
        let maxTime = Date.now();
        for (const displayItem of displayItems) {
            const dateTime = (displayItem.resource as RecordWrapper).record.dateTime;
            minTime = Math.min(minTime, dateTime);
            maxTime = Math.max(maxTime, dateTime);
        }
        min = new Date(minTime);
        max = new Date(maxTime);
        if (date < minTime) {
            setDate(minTime);
        }
        if (date > maxTime) {
            setDate(maxTime);
        }
    }
    return (
        <div className="container-grow">
            <div className="d-flex flex-row align-items-center justify-content-between">
                {(drive && items && displayItems && !error) &&
                <DeviceFilterSwitcher filters={filter}
                                      filtersChangeListener={newFilters => {
                                          setFilter(newFilters);
                                          newFilters.forEach((v, k) => {
                                              setDeviceFilterState(deviceFilter, k, v);
                                          })
                                          setDeviceFilter(deviceFilter);
                                      }}/>
                }
                <div/>
                <ArchiveModeSwitcher mode={CALENDAR} modeChangeListener={props.modeChangeListener}/>
            </div>
            <HorizontalDivider/>
            {(!error && (!drive || !items || !displayItems)) && <Loader/>}
            {(error) && <Error error={error} retryClickHandler={() => history.replace(history.location)}/>}
            {(drive && items && displayItems && !error) &&
            <div className="container-grow mt-4">
                <Calendar
                    components={{
                        toolbar: p => <CalendarToolbar localizer={p.localizer}
                                                       views={p.views}
                                                       view={p.view}
                                                       onView={p.onView}
                                                       label={p.label}
                                                       date={p.date}
                                                       onNavigate={p.onNavigate}
                                                       children={p.children}
                                                       min={min}
                                                       max={max}
                                                       monthChangeListener={newDate => setDate(newDate)}/>
                    }}
                    localizer={localizer}
                    culture={locale}
                    events={displayItems}
                    views={['month']}
                    toolbar={true}
                    popup={true}
                    date={new Date(date)}
                    onNavigate={newDate => setDate(newDate.getTime())}
                    tooltipAccessor={event => {
                        const item = event.resource as RecordWrapper;
                        return `${item.record.name}\n${formatDeviceName(t, item.record.deviceType)}\n${formatDateTime(locale, new Date(item.record.dateTime))}`;
                    }}
                    selectable={false}
                    className={"calendar"}
                    messages={{
                        next: ">",
                        previous: "<",
                        today: t("today"),
                        showMore: (count) => t("show_more", {count : count}),
                    }}
                    onDoubleClickEvent={event => history.push(buildRecordPath((event.resource as RecordWrapper).id))}
                />
            </div>
            }
        </div>
    );
}