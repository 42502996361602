import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC,
    AWP_DEVICE_MF,
    AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT,
    AWP_DEVICE_UT2A,
    AWP_DEVICE_UT3EMA
} from "./helpers/AwpHelper";

export const HOME_ROUTE = "/";
export const LIST_BASE_ROUTE = "/list/"
export const LIST_ROUTE = LIST_BASE_ROUTE + ":id";
export const MAP_ROUTE = "/map";
export const CALENDAR_ROUTE = "/calendar";
export const RECORD_BASE_ROUTE = "/records/";
export const RECORD_ROUTE = RECORD_BASE_ROUTE + ":id";
export const SHARED_RECORD_BASE_ROUTE = "/share/"
export const SHARED_RECORD_ROUTE = SHARED_RECORD_BASE_ROUTE + ":id";
export const EXECUTORS_ROUTE = "/executors";

export const AWP_LIST_ROUTE = "/usb";
export const AWP_TUD_BASE_ROUTE = AWP_LIST_ROUTE + "/tud";
export const AWP_TUD_ROUTE = AWP_TUD_BASE_ROUTE + "/:path";
export const AWP_TUD_RECORD_ROUTE = AWP_TUD_ROUTE + "/:id";
export const AWP_KRC_BASE_ROUTE = AWP_LIST_ROUTE + "/krc";
export const AWP_KRC_ROUTE = AWP_KRC_BASE_ROUTE + "/:path";
export const AWP_KRC_RECORD_ROUTE = AWP_KRC_ROUTE + "/:id";
export const AWP_TP_BASE_ROUTE = AWP_LIST_ROUTE + "/tp";
export const AWP_TP_ROUTE = AWP_TP_BASE_ROUTE + "/:path";
export const AWP_TP_RECORD_ROUTE = AWP_TP_ROUTE + "/:id";
export const AWP_UT_BASE_ROUTE = AWP_LIST_ROUTE + "/ut";
export const AWP_UT_ROUTE = AWP_UT_BASE_ROUTE + "/:path";
export const AWP_UT_RECORD_ROUTE = AWP_UT_ROUTE + "/:id";
export const AWP_MF_BASE_ROUTE = AWP_LIST_ROUTE + "/mf";
export const AWP_MF_ROUTE = AWP_MF_BASE_ROUTE + "/:path";
export const AWP_MF_RECORD_ROUTE = AWP_MF_ROUTE + "/:id";

export const AWP_UD2301_BASE_ROUTE = AWP_LIST_ROUTE + "/ud2301";
export const AWP_UD2301_ROUTE = AWP_UD2301_BASE_ROUTE + "/:path";
export const AWP_UD2301_RECORD_ROUTE = AWP_UD2301_ROUTE + "/:id";
export const AWP_UD2303_BASE_ROUTE = AWP_LIST_ROUTE + "/ud2303";
export const AWP_UD2303_ROUTE = AWP_UD2303_BASE_ROUTE + "/:path";
export const AWP_UD2303_RECORD_ROUTE = AWP_UD2303_ROUTE + "/:id";
export const AWP_UD3701_BASE_ROUTE = AWP_LIST_ROUTE + "/ud3701";
export const AWP_UD3701_ROUTE = AWP_UD3701_BASE_ROUTE + "/:path";
export const AWP_UD3701_RECORD_ROUTE = AWP_UD3701_ROUTE + "/:id";
export const AWP_UT3EMA_BASE_ROUTE = AWP_LIST_ROUTE + "/ut3ema";
export const AWP_UT3EMA_ROUTE = AWP_UT3EMA_BASE_ROUTE + "/:path";
export const AWP_UT3EMA_RECORD_ROUTE = AWP_UT3EMA_ROUTE + "/:id";
export const AWP_UT2A_BASE_ROUTE = AWP_LIST_ROUTE + "/ut2a";
export const AWP_UT2A_ROUTE = AWP_UT2A_BASE_ROUTE + "/:path";
export const AWP_UT2A_RECORD_ROUTE = AWP_UT2A_ROUTE + "/:id";
export const AWP_IPSM_BASE_ROUTE = AWP_LIST_ROUTE + "/ipsm";
export const AWP_IPSM_ROUTE = AWP_IPSM_BASE_ROUTE + "/:path";
export const AWP_IPSM_RECORD_ROUTE = AWP_IPSM_ROUTE + "/:id";
export const AWP_IPSM_DATA_TRANSFER_BASE_ROUTE = "/read-ipsm"
export const AWP_IPSM_DATA_TRANSFER_ROUTE = AWP_IPSM_DATA_TRANSFER_BASE_ROUTE + "/:path";

export const AWP_FW_GSP_UPDATE_ROUTE = "/update";

export const AWP_FW_EXPERT_UPDATE_ROUTE = "update-expert";

export const AWP_DATA_STREAMING_ROUTE = AWP_LIST_ROUTE + "/data-streaming";

export const WELCOME_PAGE_NAME = "Welcome";
export const LIST_PAGE_NAME = "Archive-list";
export const MAP_PAGE_NAME = "Archive-map";
export const CALENDAR_PAGE_NAME = "Archive-calendar";
export const RECORD_PAGE_NAME = "Record";
export const SHARED_RECORD_PAGE_NAME = "Shared Record";
export const EXECUTORS_PAGE_NAME = "Executors";

export enum Sections{
    WELCOME,
    FIND_EXECUTOR,
    ARCHIVE,
    AWP
}

export function escapePath(path: string){
    return path.replaceAll("/", "@");
}

export function unescapePath(path : string){
    return path.replaceAll("@", "/");
}

export function buildRecordPath(recordId: string): string {
    return RECORD_BASE_ROUTE + escapePath(recordId);
}

export function buildSharedRecordPath(recordId: string): string {
    return SHARED_RECORD_BASE_ROUTE + escapePath(recordId);
}

export function buildListPath(locationId?: string): string {
    return LIST_BASE_ROUTE + (locationId ? escapePath(locationId) : "");
}

export function buildAwpListPath(deviceType: number, folders: string[]){
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return buildAwpTudListPath(folders);
        case AWP_DEVICE_KRC:
            return buildAwpKrcListPath(folders);
        case AWP_DEVICE_TP:
            return buildAwpTpListPath(folders);
        case AWP_DEVICE_UT:
            return buildAwpUtListPath(folders);
        case AWP_DEVICE_MF:
            return buildAwpMfListPath(folders);
        case AWP_DEVICE_UD2301:
            return buildAwpUd2301ListPath(folders);
        case AWP_DEVICE_UD2303:
            return buildAwpUd2303ListPath(folders);
        case AWP_DEVICE_UD3701:
            return buildAwpUd3701ListPath(folders);
        case AWP_DEVICE_UT3EMA:
            return buildAwpUt3EmaListPath(folders);
        case AWP_DEVICE_UT2A:
            return buildAwpUt2aListPath(folders);
        case AWP_DEVICE_IPSM:
            return buildAwpIpsmListPath(folders);
    }
    return "";
}

export function buildAwpRecordPath(deviceType: number, folders: string[], id: string){
    switch (deviceType) {
        case AWP_DEVICE_TUD:
            return buildAwpTudRecordPath(folders, id);
        case AWP_DEVICE_KRC:
            return buildAwpKrcRecordPath(folders, id);
        case AWP_DEVICE_TP:
            return buildAwpTpRecordPath(folders, id);
        case AWP_DEVICE_UT:
            return buildAwpUtRecordPath(folders, id);
        case AWP_DEVICE_MF:
            return buildAwpMfRecordPath(folders, id);
        case AWP_DEVICE_UD2301:
            return buildAwpUd2301RecordPath(folders, id);
        case AWP_DEVICE_UD2303:
            return buildAwpUd2303RecordPath(folders, id);
        case AWP_DEVICE_UD3701:
            return buildAwpUd3701RecordPath(folders, id);
        case AWP_DEVICE_UT3EMA:
            return buildAwpUt3EmaRecordPath(folders, id);
        case AWP_DEVICE_UT2A:
            return buildAwpUt2aRecordPath(folders, id);
        case AWP_DEVICE_IPSM:
            return buildAwpIpsmRecordPath(folders, id);
    }
    return "";
}

export function buildAwpIpsmDataTransferPath(folders : string[]){
    return `${AWP_IPSM_DATA_TRANSFER_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

function buildFoldersPath(folders : string[]){
    return (folders && folders.length > 0) ? folders.join("@") : "@";
}

export function buildAwpTudListPath(folders: string[]) {
    return `${AWP_TUD_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpTudRecordPath(folders: string[], id : string) {
    return `${AWP_TUD_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpKrcListPath(folders: string[]) {
    return `${AWP_KRC_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpKrcRecordPath(folders: string[], id : string) {
    return `${AWP_KRC_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpTpListPath(folders: string[]) {
    return `${AWP_TP_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpTpRecordPath(folders: string[], id : string) {
    return `${AWP_TP_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpUtListPath(folders: string[]) {
    return `${AWP_UT_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpUtRecordPath(folders: string[], id : string) {
    return `${AWP_UT_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpMfListPath(folders: string[]) {
    return `${AWP_MF_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpMfRecordPath(folders: string[], id : string) {
    return `${AWP_MF_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpUd2301ListPath(folders: string[]) {
    return `${AWP_UD2301_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpUd2301RecordPath(folders: string[], id : string) {
    return `${AWP_UD2301_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpUd2303ListPath(folders: string[]) {
    return `${AWP_UD2303_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpUd2303RecordPath(folders: string[], id : string) {
    return `${AWP_UD2303_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpUd3701ListPath(folders: string[]) {
    return `${AWP_UD3701_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpUd3701RecordPath(folders: string[], id : string) {
    return `${AWP_UD3701_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpUt3EmaListPath(folders: string[]) {
    return `${AWP_UT3EMA_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpUt3EmaRecordPath(folders: string[], id : string) {
    return `${AWP_UT3EMA_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpUt2aListPath(folders: string[]) {
    return `${AWP_UT2A_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpUt2aRecordPath(folders: string[], id : string) {
    return `${AWP_UT2A_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}

export function buildAwpIpsmListPath(folders: string[]) {
    return `${AWP_IPSM_BASE_ROUTE}/${buildFoldersPath(folders)}`;
}

export function buildAwpIpsmRecordPath(folders: string[], id : string) {
    return `${AWP_IPSM_BASE_ROUTE}/${buildFoldersPath(folders)}/${id}`;
}