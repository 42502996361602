import {ArchiveFileSystemHandle, createDemoArchiveFileSystemHandle} from "./FileSystemHelpers";
import {useEffect, useState} from "react";
import {
    AWP_DEVICE_IPSM,
    AWP_DEVICE_KRC, AWP_DEVICE_MF, AWP_DEVICE_TP,
    AWP_DEVICE_TUD,
    AWP_DEVICE_UD2301,
    AWP_DEVICE_UD2303,
    AWP_DEVICE_UD3701,
    AWP_DEVICE_UT, AWP_DEVICE_UT2A, AWP_DEVICE_UT3EMA
} from "../helpers/AwpHelper";
import {firebaseStorage} from "../index";
import {ref} from "firebase/storage";

const ROOT_FOLDER_NAME = "DEMO AWP";

export const useFileSystem: (deviceType: number, folders: string[]) => ArchiveFileSystemHandle | undefined = ((deviceType, folders) => {
    const [handle, setHandle] = useState(undefined as ArchiveFileSystemHandle | undefined);
    const subFolders = folders.length > 0 ? `/${folders.join("/")}` : "";
    useEffect(() => {
        const folderName = getDeviceFolder(deviceType);
        const rootRef = ref(firebaseStorage, `${ROOT_FOLDER_NAME}/${folderName}${subFolders}`);
        const rootHandle = createDemoArchiveFileSystemHandle(rootRef);
        setHandle(rootHandle);
    }, [deviceType, subFolders]);
    return handle;
})

function getDeviceFolder(deviceType: number) : string {
    switch (deviceType){
        case AWP_DEVICE_TUD:
            return "TUD";
        case AWP_DEVICE_KRC:
            return "KRC";
        case AWP_DEVICE_TP:
            return "TP";
        case AWP_DEVICE_UT:
            return "UT-1M";
        case AWP_DEVICE_MF:
            return "MF";
        case AWP_DEVICE_UD2301:
            return "2301";
        case AWP_DEVICE_UD2303:
            return "2303";
        case AWP_DEVICE_UD3701:
            return "3701";
        case AWP_DEVICE_UT3EMA:
            return "UT-3EMA";
        case AWP_DEVICE_UT2A:
            return "UT-2A";
        case AWP_DEVICE_IPSM:
            return "IPSM";
    }
    throw new Error("Wrong device");
}