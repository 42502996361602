import {getImageDataFromBase64, getImageDataFromUrl, ImageData} from "./ImageData";
import QRCode from "qrcode";
import {GeoLocation} from "../models/GeoLocation";
import {buildMapsUrl} from "../helpers/GoogleMapsHelper";
import {getMediaFile} from "../helpers/GoogleStorageApiHelper";
import {MediaRecord} from "../models/MediaRecord";
import jsPDF from "jspdf";
import {LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UT1M, UT1M_CT, UT1M_IP} from "../models/DeviceType";
import {
    COATING_THICKNESS_MEASUREMENT_TYPE,
    DEW_POINT_MEASUREMENT_TYPE,
    SURFACE_TEMPERATURE_MEASUREMENT_TYPE,
    TpRecord
} from "../models/TpRecord";
import {
    createLeebRecordPdf,
    createMfRecordPdf, createTpDewPointRecordPdf,
    createTpRecordPdf, createTpSurfaceTemperatureRecordPdf,
    createTudRecordPdf,
    createUciRecordPdf,
    createUtRecordPdf
} from "./PdfDrawer";
import {TudRecord} from "../models/TudRecord";
import {UtRecord} from "../models/UtRecord";
import {BaseRecord} from "../models/BaseRecord";
import {TFunction} from "react-i18next";
import {PdfCreationError} from "../errors/PdfErrors";
import {UciRecord} from "../models/UciRecord";
import * as DeviceType from "../models/DeviceType";
import {MfRecord} from "../models/MfRecord";
import {LeebRecord} from "../models/LeebRecord";

function getPhotoRecord(record: BaseRecord) : MediaRecord | undefined {
    switch (record.deviceType){
        case TP1M:
            return (record as TpRecord).photo;
        case TUD2:
        case TUD3:
            return (record as TudRecord).photo;
        case LEEB:
            return (record as LeebRecord).photo;
        case UCI:
            return (record as UciRecord).photo;
        case UT1M:
        case UT1M_IP:
        case UT1M_CT:
            return (record as UtRecord).photo;
        case MF1M:
            return (record as MfRecord).photo;
    }
    return undefined;
}

export function getQrCodeBitmap(link: string) : Promise<ImageData | undefined> {
    return QRCode.toDataURL(link).then(code => getImageDataFromBase64(code));
}

export function getSignatureBitmap(signature : string | undefined) : Promise<ImageData | undefined> {
    if (!signature){
        return Promise.resolve(undefined);
    } else {
        return getImageDataFromBase64("data:image/png;base64," + signature).catch(() => undefined);
    }

}

export function getMapBitmap(location: GeoLocation | undefined, locale: string): Promise<ImageData | undefined> {
    return (location ? getImageDataFromUrl(buildMapsUrl(location, locale)) : Promise.resolve(undefined));
}

export function getMeasurementPhoto(drive: any, recordId: string, mediaRecord: MediaRecord | undefined) : Promise<ImageData | undefined> {
    return mediaRecord ? getMediaFile(drive, recordId, mediaRecord.fileName).then(url => getImageDataFromUrl(url)) : Promise.resolve(undefined);
}

export function createPdf(drive: any, t: TFunction<"translation">, locale: string, logo: string, recordId : string, record: BaseRecord, link : string) : Promise<jsPDF>{
    return Promise.all([getImageDataFromUrl(logo), getQrCodeBitmap(link), getMeasurementPhoto(drive, recordId, getPhotoRecord(record)), getMapBitmap(record.geoLocation, locale), getSignatureBitmap(record.userSignature)]).then(bitmaps => {
        const logoBitmap = bitmaps[0];
        const qrCode = bitmaps[1];
        const measurementPhoto = bitmaps[2];
        const locationBitmap = bitmaps[3];
        const signatureBitmap = bitmaps[4];
        let promise: Promise<jsPDF> | undefined = undefined;
        if (logoBitmap && qrCode) {
            switch (record.deviceType) {
                case TP1M:
                    const tpRecord = record as TpRecord;
                    const measurementType = tpRecord.measurementType ?? COATING_THICKNESS_MEASUREMENT_TYPE;
                    if (measurementType === COATING_THICKNESS_MEASUREMENT_TYPE) {
                        promise = createTpRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, tpRecord.probe, tpRecord.probeId, record.dateTime, measurementPhoto, tpRecord.measurements, tpRecord.notes, tpRecord.audio, tpRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    }
                    if (measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE){
                        promise = createTpSurfaceTemperatureRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, tpRecord.probe, tpRecord.probeId, record.dateTime, measurementPhoto, tpRecord.surfaceTemperatureMeasurements, tpRecord.notes, tpRecord.audio, tpRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    }
                    if (measurementType === DEW_POINT_MEASUREMENT_TYPE){
                        promise = createTpDewPointRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, tpRecord.probe, tpRecord.probeId, record.dateTime, measurementPhoto, tpRecord.dewPointMeasurements, tpRecord.notes, tpRecord.audio, tpRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    }
                    break;
                case TUD2:
                case TUD3:
                    const tudRecord = record as TudRecord;
                    promise = createTudRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, tudRecord.probe, tudRecord.probeId, record.dateTime, measurementPhoto, tudRecord.measurements, tudRecord.notes, tudRecord.audio, tudRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    break;
                case LEEB:
                    const leebRecord = record as LeebRecord;
                    promise = createLeebRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, record.dateTime, measurementPhoto, leebRecord.measurements, leebRecord.notes, leebRecord.audio, leebRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    break;
                case UCI:
                    const uciRecord = record as UciRecord;
                    promise = createUciRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, record.dateTime, measurementPhoto, uciRecord.measurements, uciRecord.notes, uciRecord.audio, uciRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    break;
                case UT1M:
                case UT1M_IP:
                case UT1M_CT:
                    const utRecord = record as UtRecord;
                    promise = createUtRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, utRecord.probeName, record.dateTime, measurementPhoto, utRecord.measurements, utRecord.showAverage ?? false, utRecord.notes, utRecord.audio, utRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    break;
                case MF1M:
                    const mfRecord = record as MfRecord;
                    promise = createMfRecordPdf(t, locale, logoBitmap, record.name, record.userName, record.userOrganization, signatureBitmap, record.deviceType, record.deviceId, record.dateTime, measurementPhoto, mfRecord.measurements, mfRecord.notes, mfRecord.audio, mfRecord.video, record.geoLocation, locationBitmap, link, qrCode);
                    break;
            }
        }
        if (promise) {
            return promise;
        }
        throw new PdfCreationError();
    });
}