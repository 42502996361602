import connectionWindowEn from './Assets/connection_window_en.png';
import connectionWindowRu from './Assets/connection_window_ru.png';
import connectionWindowUk from './Assets/connection_window_uk.png';
import {TFunction} from "react-i18next";


export function getConnectionWindowImage(t: TFunction<"translation">){
    switch (t("language")){
        case "ru":
            return connectionWindowRu;
        case "uk":
            return connectionWindowUk;
        case "en":
        default:
            return connectionWindowEn;
    }
}