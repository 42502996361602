import React, { Fragment } from "react";
import {Route} from "react-router-dom";
import {AWP_FW_EXPERT_UPDATE_ROUTE, AWP_FW_GSP_UPDATE_ROUTE} from "./routes";
import {AwpFwExpertUpdate} from "./components/AwpFwExpertUpdate/AwpFwExpertUpdate";
import {AwpFwGspUpdate} from "./components/AwpFwGspUpdate/AwpFwGspUpdate";

export function AwpFwUpdateRouter(){
    return (
        <Fragment>
            <Route exact path={AWP_FW_GSP_UPDATE_ROUTE}>
                <AwpFwGspUpdate/>
            </Route>
            <Route exact path={AWP_FW_EXPERT_UPDATE_ROUTE}>
                <AwpFwExpertUpdate/>
            </Route>
        </Fragment>
    );
}