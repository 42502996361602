import {AwpDirectoryHandle} from "./AwpDirectoryHandle";
import {AwpFileHandle} from "./AwpFileHandle";
import {listAll, ref, StorageReference} from "firebase/storage";
import {firebaseStorage} from "../index";
import {AwpGoogleCloudFileHandle} from "./AwpGoogleCloudFileHandle";

export class AwpGoogleCloudDirectoryHandle implements AwpDirectoryHandle {

    private readonly ref: StorageReference;

    constructor(ref: StorageReference) {
        this.ref = ref;
    }

    directories(): Promise<Array<AwpDirectoryHandle>> {
        return new Promise<Array<AwpDirectoryHandle>>(resolve => {
            const directories = new Array<AwpDirectoryHandle>();
            listAll(this.ref).then(refs => {
                for (const ref of refs.prefixes) {
                    directories.push(new AwpGoogleCloudDirectoryHandle(ref));
                }
                resolve(directories);
            }).catch(() => {
                resolve(directories);
            });
        });
    }

    files(): Promise<Array<AwpFileHandle>> {
        return Promise.resolve(new Array<AwpFileHandle>());
    }

    getDirectoryHandle(recordName: string): Promise<AwpDirectoryHandle | null> {
        const r = ref(firebaseStorage, `${this.ref.fullPath}/${recordName}`);
        return Promise.resolve(new AwpGoogleCloudDirectoryHandle(r));
    }

    getFileHandle(name: string): Promise<AwpFileHandle | null> {
        const r = ref(firebaseStorage, `${this.ref.fullPath}/${name}`);
        return Promise.resolve(new AwpGoogleCloudFileHandle(r));
    }

    getFileSystemHandle(): FileSystemDirectoryHandle | undefined {
        return undefined;
    }

    name(): string {
        return this.ref.name;
    }

}